/**
 * @require ./properties/button-row-property.js
 * @require ./properties/checkbox-property.js
 * @require ./properties/label.js
 * @require ./properties/number-property.js
 * @require ./properties/property-group.js
 * @require ./properties/resettable-button-property.js
 * @require ./properties/rich-text-property.js
 * @require ./properties/text-line-property.js
 * @require ./properties/text-memo-property.js
 * @require ./properties/time-property.js
 */
(function (global) {
    var PageBase = function (tabs, options) {
        if (this.constructor === PageBase) {
            throw new Error('This class cannot be instanciated.')
        }

        if (!(tabs || []).length) {
            return;
        }

        this.Tabs = tabs;
        this.TabMap = {};
        this.Options = options || {
            TreeTextProperty: 'Title'
        };

        var me = this;

        this.Tabs.forEach(function (tab) {
            me.TabMap[tab.ID] = tab;
        });
    };

    PageBase.prototype.RenderTab = function (tabId) {
        if (!this.TabMap.hasOwnProperty(tabId)) {
            return;
        }

        var tab = this.TabMap[tabId];

        tab.RenderProperties();

        this.CurrentTab = tab;
    };

    PageBase.prototype.ApplyValues = function () {
        var me = this;

        if (!me.CurrentTab) {
            return $.Deferred().resolve().promise();
        }

        if (!me.CurrentTab.ValidateProperties()) {
            return $.Deferred().resolve().promise();
        }

        me.CurrentTab.ApplyValues();

        return me.SaveEntity()
            .then(function () {
                onAfterEntitySaved.call(me);
            }, function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Write, xhr);
            });
    };

    PageBase.prototype.SaveEntity = function () {
        throw new Error('Function must be defined in the concrete implementation of this class.');
    };

    PageBase.prototype.OnBeforeDestroy = function () { };

    PageBase.prototype.Destroy = function () {
        this.OnBeforeDestroy();
        this.Tabs.forEach(function (tab) {
            if (tab.Destroy instanceof Function) {
                tab.Destroy();
            }
        });
    };

    function onAfterEntitySaved() {
        content.properties.setChanged(false);
        Commit.setChanged(true);
        Center.ResetSearch();

        $('#center-tree')
            .tree(
                'set-node-text',
                CurrentEntity,
                Tools.escapeHtml(CurrentEntity[this.Options.TreeTextProperty])
            );
    }

    if (!global.Base) {
        global.Base = {};
    }

    global.Base.Page = PageBase;
})(Modifications.Pages || (Modifications.Pages = {}));