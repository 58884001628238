/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Tools) {
        global.Tools = {};
    }

    /**
     * Passt die Höhe / Breite von Komponenten an Kopf-/Fußzeilen an
     * @param {Marginal} marginal
     * @param {BaseComponent} component
     * @param {number} maxHeight
     * @param {boolean} isHeader
     * @param {boolean} isFooter
     * @return {BaseComponent|null}
     */
    global.Tools.AdjustMarginalComponentDimensions = function (marginal, component, maxHeight, isHeader, isFooter) {
        if (!component) {
            return null;
        }

        if (isHeader) {
            if (component.Height + component.Position.Coordinates.Y > marginal.Height) {
                component.Height = marginal.Height - component.Position.Coordinates.Y;
            }
        } else if (isFooter) {
            if (component.Height + component.Position.Coordinates.Y > 100) {
                component.Height = 100 - component.Position.Coordinates.Y;
            }
        }

        if (component.Width + component.Position.Coordinates.X > 100) {
            component.Width = 100 - component.Position.Coordinates.X;
        }

        return component;
    };

    /**
     * Passt die Höhe / Breite von Komponenten an den Hauptbereich der Seite an
     * @param {BaseComponent} component
     * @param {number} footerHeight
     * @return {BaseComponent|null}
     */
    global.Tools.AdjustPageComponentDimensions = function (component, footerHeight) {
        if (!component) {
            return null;
        }

        if (component.Height + component.Position.Coordinates.Y > 100 - footerHeight) {
            component.Height = 100 - footerHeight - component.Position.Coordinates.Y;
        }

        if (component.Width + component.Position.Coordinates.X > 100) {
            component.Width = 100 - component.Position.Coordinates.X;
        }

        return component;
    };

    /**
     * Erstellt ein Zentimetermaß
     * @param {number} lengthCm
     * @param {('horizontal'|'vertical')} orientation
     */
    global.Tools.CreateRulerMarkup = function (lengthCm, orientation = 'horizontal') {
        if (typeof lengthCm !== 'number' || isNaN(lengthCm) || lengthCm < 1) {
            return;
        }

        const isHorizontal = orientation === 'horizontal';
        const pxPerCm = global.Tools.CalculatePixelsPerMillimeter();
        const markup = [];

        for (let cm = 0; cm < lengthCm; cm++) {
            markup.push(`<div class="cm" style="${isHorizontal ? 'width' : 'height'}:${pxPerCm}px;"><span class="label">${cm + 1}</span>`);

            for (let mm = 1; mm < 10; mm++) {
                if (mm % 2 !== 0) {
                    continue;
                }

                markup.push(`<div class="mm" style="${isHorizontal ? 'left' : 'top'}:${(mm / 10) * 100}%;"></div>`);
            }

            markup.push('</div>');
        }

        markup.push('<div class="cm"></div>');
        markup.push('<div class="current-position hide"></div>');

        return markup.join('');
    };

    /**
     * Gibt die Anzahl Pixel zurück, die nötig sind, um einen Millimeter darzustellen. Hierbei wird fix mit 96 DPI gerechnet.
     * @return {number}
     */
    global.Tools.CalculatePixelsPerMillimeter = function () {
        return 1 / 2.54 * 96;
    };
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));