/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @typedef ComponentGroup
     */
    global.Model.ComponentGroup = (function () {
        /**
         * @param {string} identifier
         * @param {string} title
         * @param {BaseComponent[]} components
         * @constructor
         */
        function ComponentGroup(identifier, title, components) {
            /**
             * @type {string}
             */
            this.Identifier = identifier;

            /**
             * @type {string}
             */
            this.Title = title;

            /**
             * @type {BaseComponent[]}
             */
            this.Components = components;
        }

        ComponentGroup.prototype.RenderInSelection = function () {
            return [
                `<section data-identifier="${this.Identifier}">`,
                    `<h4 class="section-header">${this.Title}</h4>`,
                    '<ul class="component-list">',
                        this.Components
                            .map(component => component.CreateSelectionMarkup())
                            .join(''),
                    '</ul>',
                '</section>'
            ].join('');
        };

        ComponentGroup.prototype.RenderOnPage = function () {};

        return ComponentGroup;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));