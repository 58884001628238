/**
 * @require ./_base.js
 */
(function (global) {
    var MailTemplatePreview = function (options) {
        if (!options.ID) {
            options.ID = 'mail-template-preview';
        }

        if (!options.Subject && !options.Body) {
            throw new Error('No texts defined!');
        }

        options.Title = options.Title || i18next.t('changeMode.mailTemplates.previewWindow.title');
        options.ResizeToFitToContent = true;
        options.ShowOverlay = true;

        options.Styles = {
            '_': {
                'z-index': 14001,
                width: 768
            },
            '.content': {
                overflow: 'auto',
                padding: '10px'
            },
            '.content > p:first-child': {
                margin: '0 0 1rem 0'
            },
            '.content .form-info': {
                'margin-bottom': '1rem'
            },
            '.content .row': {
                border: '1px solid #eee',
                padding: 5,
                'margin-top': 5
            },
            '.content .property': {
                'margin-top': 0,
                'font-weight': 'bold'
            },
            '.content .text': {
                'margin-bottom': 0,
                padding: 16
            }
        };

        global.PopupBase.call(this, options);
    };

    MailTemplatePreview.prototype = Object.create(global.PopupBase.prototype, {
        constructor: MailTemplatePreview
    });

    MailTemplatePreview.prototype.CreateContentMarkup = function () {
        var html = [];

        if (this.options.IntroductionText) {
            html.push(
                '<p>',
                    Tools.escapeHtml(this.options.IntroductionText),
                '</p>',
            );
        }

        if (this.options.CurrentForm) {
            html.push(
                '<div class="form-info">',
                    '<div class="grid-row">',
                        '<div class="grid-col-5-24">',
                            i18next.t('changeMode.mailTemplates.previewWindow.currentForm'),
                        ':</div>',
                        '<div class="grid-col-19-24">',
                            Tools.escapeHtml(this.options.CurrentForm.Title),
                        '</div>',
                    '</div>',
                    '<div class="grid-row">',
                        '<div class="grid-col-5-24">',
                            i18next.t('changeMode.mailTemplates.previewWindow.newForm'),
                        ':</div>',
                        '<div class="grid-col-19-24">',
                            this.options.NewForm ?
                                Tools.escapeHtml(this.options.NewForm.Title) :
                                '-/-',
                        '</div>',
                    '</div>',
                '</div>'
            );
        }

        if (!!this.options.Subject) {
            html.push(
                '<div class="row">',
                    '<p class="property">',
                        i18next.t('changeMode.mailTemplates.previewWindow.subject'),
                    '</p>',

                    '<p class="text">',
                        Tools.escapeHtml(this.options.Subject, ['script']),
                    '</p>',
                '</div>'
            );
        }

        if (!!this.options.Body) {
            html.push(
                '<div class="row">',
                    '<p class="property">',
                        i18next.t('changeMode.mailTemplates.previewWindow.body'),
                    '</p>',

                    '<div class="text sun-editor-editable">',
                        this.options.Body,
                    '</div>',
                '</div>'
            );
        }

        return html.join('');
    };

    global.MailTemplatePreview = MailTemplatePreview;
})(Modifications.Popups || (Modifications.Popups = {}));