/**
 * @require ./_base.js
 */

(function (global) {
    /**
     * @param options
     * @augments {PopupBase}
     * @constructor
     */
    const AutomaticStatusChangeWindow = function (options) {
        if (!options.ID) {
            options.ID = 'automatic-status-change';
        }

        options.ResizeToFitToContent = true;
        options.ShowOverlay = true;

        if (!options.Buttons) {
            options.Buttons = {
                Close: {
                    Caption: i18next.t('misc.close')
                }
            };
        }

        options.Styles = {
            '_': {
                'z-index': 14001,
                width: 650
            },
            '.content': {
                overflow: 'auto',
                padding: '10px'
            },
            '.content > p:first-child': {
                margin: '0 0 1rem 0'
            },
            '.status-wrapper': {
                display: 'flex',
                gap: '1rem',
                'align-items': 'center'
            },
            '.status-wrapper > label': {
                width: '7rem',
                'text-overflow': 'ellipsis',
                overflow: 'hidden'
            },
            '.selection-wrapper + .selection-wrapper': {
                'margin-top': '.5rem'
            }
        };

        this.CurrentStatusOID = options.CurrentStatusOID;
        this.PrevAutomaticStatusChange =  options.AutomaticStatusChange || null;
        this.AutomaticStatusChange =  Tools.clone(options.AutomaticStatusChange || null);
        this.PrevAutomaticStatusChangeByKeyword = options.AutomaticStatusChangeByKeyword || {};
        this.AutomaticStatusChangeByKeyword = Tools.clone(options.AutomaticStatusChangeByKeyword || {});

        global.PopupBase.call(this, options);
    };

    AutomaticStatusChangeWindow.prototype = Object.create(global.PopupBase.prototype, {
        constructor: AutomaticStatusChangeWindow
    });

    AutomaticStatusChangeWindow.prototype.CreateContentMarkup = function () {
        const html = [
            '<p class="message-text">',
                i18next.t('changeMode.panels.nextStatus.automaticStatusChange.description'),
            '</p>'
        ];

        if (this.AutomaticStatusChange) {
            html.push(getRowMarkup.call(this, this.AutomaticStatusChange));
        } else {
            html.push('<div class="row"><div class="btn add-configuration" data-without-keyword="true">+ {0}</div></div>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.activate')));
        }

        for (const key in this.AutomaticStatusChangeByKeyword) {
            const automaticStatusChange = this.AutomaticStatusChangeByKeyword[key];

            html.push(getRowMarkup.call(this, automaticStatusChange, key));
        }

        html.push('<div class="row"><div class="btn add-configuration">+ {0}</div></div>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.addWithKeyword')));

        return html.join('');
    };

    AutomaticStatusChangeWindow.prototype.BindAdditionalEvents = function () {
        bindConfigurationEvents.call(this, this.$content);
    };

    AutomaticStatusChangeWindow.prototype.OnAfterRendered = function () {
        if (!this.SelectedOpenStatusOID || !this.SelectedClosedStatusOID) {
            this.$bottom.find('.btn-yes').attr('disabled', 'disabled');
        }
    };

    function unbindConfigurationEvents($container) {
        $container.find('.add-configuration').off('click');
        $container.find('.status-change-column .btn-keyword').off('click');
        $container.find('.status-change-column .btn-status').off('click');
        $container.find('.status-change-column .timing-type').off('change');
        $container.find('.status-change-column .offset-value').off('change');
        $container.find('.status-change-column .offset-type').off('change');
        $container.find('.status-change-column .time-hour').off('change');
        $container.find('.status-change-column .time-minute').off('change');
        $container.find('.status-change-column .day-of-week-option').off('change');
        $container.find('.delete-configuration').off('click');
    }

    function bindConfigurationEvents($container) {
        $container.find('.add-configuration').on('click', $.proxy(onAddConfiguration, this));
        $container.find('.status-change-column .btn-keyword').on('click', $.proxy(onChangeKeyword, this));
        $container.find('.status-change-column .btn-status').on('click', $.proxy(onChangeStatus, this));
        $container.find('.status-change-column .timing-type').on('change', $.proxy(onTimingTypeChange, this));
        $container.find('.status-change-column .offset-value').on('change', $.proxy(onOffsetValueChange, this));
        $container.find('.status-change-column .offset-type').on('change', $.proxy(onOffsetTypeChange, this));
        $container.find('.status-change-column .time-hour').on('change', $.proxy(onTimeChange, this));
        $container.find('.status-change-column .time-minute').on('change', $.proxy(onTimeChange, this));
        $container.find('.status-change-column .day-of-week-option').on('change', $.proxy(onDayOfWeekStateChange, this));
        $container.find('.delete-configuration').on('click', $.proxy(onDeleteConfiguration, this));
    }

    function setButtonsState() {
        const enableYesButton = hasModifications.call(this) && !this.$content.find('.invalid').length;
        const $btnYes = this.$bottom.find('.btn-yes');

        if (enableYesButton) {
            $btnYes.removeAttr('disabled');
        } else {
            $btnYes.attr('disabled', 'disabled');
        }
    }


    function onAddConfiguration(evt) {
        const $btn = $(evt.currentTarget);
        const me = this;

        const automaticStatusChange = {
            Timing: Enums.StatusChangeTiming.Offset,
            Offset: 1,
            Type: Enums.AutomaticStatusChangeOffsetType.Days
        };

        if ($btn.data('without-keyword') === true) {
            me.AutomaticStatusChange = automaticStatusChange;

            const $newContent = $(getRowMarkup.call(me, automaticStatusChange));
            $btn.parent().replaceWith($newContent);
            bindConfigurationEvents.call(me, $newContent);

            setButtonsState.call(me);
            resize.call(me);

            return;
        }

        showKeywordSelection.call(me, (function (keywordOID) {
            if (!keywordOID) {
                return;
            }

            me.AutomaticStatusChangeByKeyword[keywordOID] = automaticStatusChange;

            const $newContent = $(getRowMarkup.call(me, automaticStatusChange, keywordOID));
            $btn.parent().before($newContent);
            bindConfigurationEvents.call(me, $newContent);

            setButtonsState.call(me);
            resize.call(me);
        }));
    }

    function onChangeKeyword(evt) {
        const $btn = $(evt.currentTarget);
        const me = this;

        const $configurationContainer = getConfigurationContainer($btn)

        if (!$configurationContainer) {
            return;
        }

        const currentKeywordIdentifier = $configurationContainer.data('id');

        console.log(currentKeywordIdentifier);

        showKeywordSelection.call(me, (function (keywordOID) {
            if (!keywordOID) {
                return;
            }

            const automaticStatusChange = me.AutomaticStatusChangeByKeyword[currentKeywordIdentifier];
            delete me.AutomaticStatusChangeByKeyword[currentKeywordIdentifier];
            me.AutomaticStatusChangeByKeyword[keywordOID] = automaticStatusChange;

            const keyword = changemode.Properties[keywordOID];
            $btn.removeClass('invalid');
            $btn.find('.color').css('background-color', keyword.Color);
            $btn.find('.text').text(keyword.Title);
            $configurationContainer.attr('data-id', keyword.OID);
            $configurationContainer.data('id', keyword.OID);

            setButtonsState.call(me);
            resize.call(me);
        }));
    }

    function onChangeStatus(evt) {
        const $btn = $(evt.currentTarget);
        const me = this;

        const $configurationContainer = getConfigurationContainer($btn);

        if (!$configurationContainer) {
            return null;
        }

        const id = $configurationContainer.data('id');
        const automaticStatusChange = getAutomaticStatusChange.call(me, id);

        const options = {
            title: i18next.t('changeMode.panels.nextStatus.automaticStatusChange.selectStatus'),
            selectMultiple: false,
            onSelect: function (statusOID) {
                if (!statusOID) {
                    return;
                }

                if (automaticStatusChange.StatusOID === statusOID) {
                    setButtonsState.call(me);
                    return
                }

                automaticStatusChange.StatusOID = statusOID;

                const status = changemode.Properties[statusOID];

                $btn.removeClass('invalid');
                $btn.find('.color').css('background-color', status.Color);
                $btn.find('.text').text(status.Title);

                setButtonsState.call(me);
                resize.call(me);
            },
            treeOptions: {
                opened: true,
                schema: function (status) {
                    const isSelectable = status.OID !== me.CurrentStatusOID;
                    const classes = isSelectable ? [] : ['jquery-tree-node-disabled'];

                    return {
                        id: status.OID,
                        text: status.Title,
                        color: status.Color,
                        children: status.Children,
                        selectable: isSelectable,
                        classes: classes.join(' ')
                    };
                },
                filter: function (entity) {
                    return entity.ModificationType !== Enums.ModificationType.Deleted;
                }
            }
        };

        TreePicker.Show(changemode.Status, options);
    }

    function onTimingTypeChange(evt) {
        const $selection = $(evt.currentTarget);
        const me = this;
        const $configurationContainer = getConfigurationContainer($selection);

        if (!$configurationContainer) {
            return;
        }

        const id = $configurationContainer.data('id');
        const automaticStatusChange = getAutomaticStatusChange.call(me, id);

        const newVal = parseInt($selection.val(), 10);

        if (isNaN(newVal)) {
            $selection.addClass('invalid');
            setButtonsState.call(me);
            return;
        }

        $selection.removeClass('invalid');

        if (newVal === automaticStatusChange.Timing) {
            setButtonsState.call(me);
            return;
        }

        automaticStatusChange.Timing = newVal;


        switch (automaticStatusChange.Timing) {
            case Enums.StatusChangeTiming.Offset:
                automaticStatusChange.Offset = 1;
                automaticStatusChange.Type = Enums.AutomaticStatusChangeOffsetType.Days;
                delete automaticStatusChange.Time;
                delete automaticStatusChange.Weekdays;
                break;
            case Enums.StatusChangeTiming.Time:
                automaticStatusChange.Time = "00:00";
                automaticStatusChange.Weekdays = 127;
                delete automaticStatusChange.Offset;
                delete automaticStatusChange.Type;
        }

        $configurationContainer.find('.additional-options').replaceWith($(getAdditionalOptionsMarkup(automaticStatusChange)));

        if (automaticStatusChange.Timing === Enums.StatusChangeTiming.Time) {
            $configurationContainer.find('.status-change-column').append(getWeekdaysPickerMarkup(0));
        } else {
            $configurationContainer.find('.weekday-picker').remove();
        }

        unbindConfigurationEvents.call(me, $configurationContainer);
        bindConfigurationEvents.call(me, $configurationContainer);

        setButtonsState.call(me);
        resize.call(me);
    }

    function onOffsetValueChange(evt) {
        const $input = $(evt.currentTarget);
        const me = this;

        const $configurationContainer = getConfigurationContainer($input);

        if (!$configurationContainer) {
            return;
        }

        let newOffset = parseFloat($input.val());

        if (isNaN(newOffset) || newOffset < 0) {
            $input.addClass('invalid');
            setButtonsState.call(me);
            return;
        }

        $input.removeClass('invalid');

        const id = $configurationContainer.data('id');
        const automaticStatusChange = getAutomaticStatusChange.call(me, id);

        newOffset = Math.round(newOffset);

        if (automaticStatusChange.Offset === newOffset) {
            setButtonsState.call(me);
            return;
        }

        automaticStatusChange.Offset = newOffset;
        $input.val(newOffset); // falls gerundet wurde

        setButtonsState.call(me);
    }

    function onOffsetTypeChange(evt) {
        const $selection = $(evt.currentTarget);
        const me = this;

        const $configurationContainer = getConfigurationContainer($selection);

        if (!$configurationContainer) {
            return;
        }

        const id = $configurationContainer.data('id');
        const automaticStatusChange = getAutomaticStatusChange.call(me, id);

        const newOffsetType = parseInt($selection.val(), 10);

        if (isNaN(newOffsetType) || newOffsetType < 0) {
            $selection.addClass('invalid');
            setButtonsState.call(me);
            return;
        }

        $selection.removeClass('invalid');

        if (automaticStatusChange.Type === newOffsetType) {
            setButtonsState.call(me);
            return;
        }

        automaticStatusChange.Type = newOffsetType;
        setButtonsState.call(me);
    }

    function onTimeChange(evt) {
        const $selection = $(evt.currentTarget);
        const me = this;

        const $configurationContainer = getConfigurationContainer($selection);

        if (!$configurationContainer) {
            return;
        }

        const id = $configurationContainer.data('id');
        const automaticStatusChange = getAutomaticStatusChange.call(me, id);
        const $hourSelection = $configurationContainer.find('.time-hour');
        const $minuteSelection = $configurationContainer.find('.time-minute');

        const hourValue = parseInt($hourSelection.val(), 10);
        const minuteValue = parseInt($minuteSelection.val(), 10);
        let anyInvalid = false;

        if (isNaN(hourValue) || hourValue < 0 || hourValue > 23) {
            $hourSelection.addClass('invalid');
            anyInvalid = true;
        } else {
            $hourSelection.removeClass('invalid');
        }

        if (isNaN(minuteValue) || minuteValue < 0 || minuteValue > 59) {
            $minuteSelection.addClass('invalid');
            anyInvalid = true;
        } else {
            $minuteSelection.removeClass('invalid');
        }

        if (anyInvalid) {
            setButtonsState.call(me);
            return;
        }


        const newTime = $hourSelection.val().toString().padStart(2, '0') + ':' + $minuteSelection.val().toString().padStart(2, '0');

        if (automaticStatusChange.Time === newTime) {
            setButtonsState.call(me);
            return;
        }

        automaticStatusChange.Time = newTime;
        setButtonsState.call(me);
    }

    function onDayOfWeekStateChange(evt) {
        const $checkbox = $(evt.currentTarget);
        const me = this;

        const $configurationContainer = getConfigurationContainer($checkbox);

        if (!$configurationContainer) {
            return;
        }

        const id = $configurationContainer.data('id');
        const automaticStatusChange = getAutomaticStatusChange.call(me, id);
        const $weekdayPicker = $configurationContainer.find('.weekday-picker');

        let weekdays = 0;
        $weekdayPicker.find('.day-of-week-option:checked').each(function(index, item) {
            weekdays += parseInt(item.value);
        });

        if (weekdays === 0) {
            $weekdayPicker.addClass('invalid');
            $weekdayPicker.append('<div class="invalid-weekday-notice">{0}'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.invalidWeekdayNotice')));
            setButtonsState.call(me);
            return;
        }

        $weekdayPicker.removeClass('invalid');
        $weekdayPicker.find('.invalid-weekday-notice').remove();

        if (automaticStatusChange.Weekdays === weekdays) {
            setButtonsState.call(me);
            return;
        }

        automaticStatusChange.Weekdays = weekdays;
        setButtonsState.call(me);
    }

    function onDeleteConfiguration(evt) {
        const $btn = $(evt.currentTarget);
        const me = this;

        const $configurationContainer = getConfigurationContainer($btn);

        if (!$configurationContainer) {
            return;
        }

        const identifier = $configurationContainer.data('id');

        if (identifier) {
            delete me.AutomaticStatusChangeByKeyword[identifier];
            $configurationContainer.remove();
        } else {
            delete me.AutomaticStatusChange;

            const $addConfigurationButton = $('<div class="row"><div class="btn add-configuration" data-without-keyword="true">+ {0}</div></div>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.activate')));
            $addConfigurationButton.find('.add-configuration').on('click', $.proxy(onAddConfiguration, me));
            $configurationContainer.replaceWith($addConfigurationButton);
        }

        setButtonsState.call(me);
        resize.call(me);
    }

    function showKeywordSelection(callback) {
        const me = this;
        const options = {
            title: i18next.t('changeMode.panels.nextStatus.automaticStatusChange.selectKeyword'),
            selectMultiple: false,
            onSelect: callback,
            treeOptions: {
                opened: true,
                schema: function (keyword) {
                    const isSelectable = !me.AutomaticStatusChangeByKeyword.hasOwnProperty(keyword.OID);
                    const classes = isSelectable ? [] : ['jquery-tree-node-disabled'];

                    return {
                        id: keyword.OID,
                        text: keyword.Title,
                        color: keyword.Color,
                        children: keyword.Children,
                        selectable: isSelectable,
                        classes: classes.join(' ')
                    };
                },
                filter: function (entity) {
                    return entity.ModificationType !== Enums.ModificationType.Deleted;
                }
            }
        };

        TreePicker.Show(changemode.Keywords, options);
    }

    function getRowMarkup(automaticStatusChange, keywordOID) {
        return [
            '<div class="row configuration" data-id="{0}">'.format(keywordOID || ''),
                '<div class="column status-change-column">',
                    getAutomaticStatusChangeMarkup.call(this, automaticStatusChange, keywordOID),
                '</div>',
                '<div class="column delete-column">',
                    '<div class="delete-configuration" title="{0}">'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.delete')),
                        '<img class="delete" src="./img/bin.svg" />',
                    '</div>',
                '</div>',
            '</div>'
        ].join('');
    }

    function getAutomaticStatusChangeMarkup(automaticStatusChange, keywordOID) {
        automaticStatusChange = automaticStatusChange || {};

        const status = changemode.Properties[automaticStatusChange.StatusOID] || {Color: "#FFF", Title: i18next.t('changeMode.panels.nextStatus.automaticStatusChange.selectStatus')};
        const keyword = keywordOID ? changemode.Properties[keywordOID] || {Color: '#FFF', Title: i18next.t('changeMode.panels.nextStatus.automaticStatusChange.selectKeyword')} : null;
        const timingType = automaticStatusChange.Timing || Enums.StatusChangeTiming.Offset;

        const html = [
            '<div class="row">',
                '<div class="btn-status button-steal{0}" >'.format(status.OID ? '' : ' invalid'),
                    '<span class="color" style="background-color: {0}"></span>'.format(status.Color),
                    '<span class="text">{0}</span>'.format(status.Title),
                '</div>',
                keyword ? '<div class="keyword-wrapper"><span>{0}</span> <div class="btn-keyword button-steal{1}"><span class="color" style="background-color: {2}"></span><span class="text">{3}</span></div></div>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.byKeyword'), keyword.OID ? '' : ' invalid', keyword.Color, keyword.Title) : '',
            '</div>',
            '<div class="row">',
                '<select class="timing-type button-steal" name="timing-type">',
                    '<option value="0" {1}>{0}</option>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.timing.offset'), (timingType === Enums.StatusChangeTiming.Offset ? 'selected' : '')),
                    '<option value="1" {1}>{0}</option>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.timing.time'), (timingType === Enums.StatusChangeTiming.Time ? 'selected' : '')),
                '</select>',
                getAdditionalOptionsMarkup(automaticStatusChange),
            '</div>'
        ];

        if (timingType === Enums.StatusChangeTiming.Time) {
            html.push(getWeekdaysPickerMarkup(automaticStatusChange.Weekdays));
        }

        return html.join('');
    }

    function getAdditionalOptionsMarkup(automaticStatusChange) {
        switch (automaticStatusChange.Timing || Enums.StatusChangeTiming.Offset) {
            case Enums.StatusChangeTiming.Offset:
                return getOffsetAdditionalOptionsMarkup(automaticStatusChange.Offset, automaticStatusChange.Type);
            case Enums.StatusChangeTiming.Time:
                return getTimeAdditionalOptionsMarkup((automaticStatusChange.Time));
            default:
                return '';
        }
    }

    function getOffsetAdditionalOptionsMarkup(offset, offsetType) {
        offsetType = offsetType || Enums.AutomaticStatusChangeOffsetType.Minutes;

        return [
            '<div class="additional-options">',
                '<span>{0}</span>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.offsetBy')),
                '<input class="offset-value" type="number" name="offset-value" value="{0}" min="0" />'.format(offset || 0),
                '<select class="offset-type" name="offset-type">',
                    '<option value="0" {1}>{0}</option>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.minutes'), (offsetType === Enums.AutomaticStatusChangeOffsetType.Minutes ? 'selected' : '')),
                    '<option value="1" {1}>{0}</option>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.hours'), (offsetType === Enums.AutomaticStatusChangeOffsetType.Hours ? 'selected' : '')),
                    '<option value="2" {1}>{0}</option>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.days'), (offsetType === Enums.AutomaticStatusChangeOffsetType.Days ? 'selected' : '')),
                    '<option value="3" {1}>{0}</option>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.months'), (offsetType === Enums.AutomaticStatusChangeOffsetType.Months ? 'selected' : '')),
                    '<option value="4" {1}>{0}</option>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.years'), (offsetType === Enums.AutomaticStatusChangeOffsetType.Years ? 'selected' : '')),
                '</select>',
            '</div>',
        ].join('');
    }

    function getTimeAdditionalOptionsMarkup(time) {
        const timeParts = (time || '00:00').split(':');
        const selectedHour = parseInt(timeParts[0] || 0, 10);
        const selectedMinute = parseInt(timeParts[1] || 0, 10);
        const hourOptions = [];
        const minuteOptions = [];

        for (let hour = 0; hour <= 23; hour++) {
            hourOptions.push('<option value="{0}" {2}>{1}</option>'.format(hour, ('' + hour).padleft('0', 2), hour === selectedHour ? 'selected' : ''))
        }

        for (let minute = 0; minute <= 59; minute++) {
            minuteOptions.push('<option value="{0}" {2}>{1}</option>'.format(minute, ('' + minute).padleft('0', 2), minute === selectedMinute ? 'selected' : ''))
        }

        return [
            '<div class="additional-options">',
                '<span class="label time-label">{0}</span>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.time')),
                '<select class="time-hour" name="time-hour">',
                    hourOptions.join(''),
                '</select>',
                '<select class="time-minute" name="time-minute">',
                    minuteOptions.join(''),
                '</select>',
            '</div>',
        ].join('');
    }

    function getWeekdaysPickerMarkup(selectedWeekdays) {
        selectedWeekdays = selectedWeekdays || 127;

        return [
            '<div class="row weekday-picker">',
                '<div class="weekday-picker-heading">{0}</div>'.format(i18next.t('changeMode.panels.nextStatus.automaticStatusChange.onWeekdays')),
                '<label>',
                    '<input class="day-of-week-option" type="checkbox" name="weekdays" value="2" {0}>'.format((selectedWeekdays & 2) !== 0 ? 'checked' : ''),
                    '<span>{0}</span>'.format(i18next.t('weekdays.monday_abbreviation')),
                '</label>',
                '<label>',
                    '<input class="day-of-week-option" type="checkbox" name="weekdays" value="4" {0}>'.format((selectedWeekdays & 4) !== 0 ? 'checked' : ''),
                    '<span>{0}</span>'.format(i18next.t('weekdays.tuesday_abbreviation')),
                '</label>',
                '<label>',
                    '<input class="day-of-week-option" type="checkbox" name="weekdays" value="8" {0}>'.format((selectedWeekdays & 8) !== 0 ? 'checked' : ''),
                    '<span>{0}</span>'.format(i18next.t('weekdays.wednesday_abbreviation')),
                '</label>',
                '<label>',
                    '<input class="day-of-week-option" type="checkbox" name="weekdays" value="16" {0}>'.format((selectedWeekdays & 16) !== 0 ? 'checked' : ''),
                    '<span>{0}</span>'.format(i18next.t('weekdays.thursday_abbreviation')),
                '</label>',
                '<label>',
                    '<input class="day-of-week-option" type="checkbox" name="weekdays" value="32" {0}>'.format((selectedWeekdays & 32) !== 0 ? 'checked' : ''),
                    '<span>{0}</span>'.format(i18next.t('weekdays.friday_abbreviation')),
                '</label>',
                '<label>',
                    '<input class="day-of-week-option" type="checkbox" name="weekdays" value="64" {0}>'.format((selectedWeekdays & 64) !== 0 ? 'checked' : ''),
                    '<span>{0}</span>'.format(i18next.t('weekdays.saturday_abbreviation')),
                '</label>',
                '<label>',
                    '<input class="day-of-week-option" type="checkbox" name="weekdays" value="1" {0}>'.format((selectedWeekdays & 1) !== 0 ? 'checked' : ''),
                    '<span>{0}</span>'.format(i18next.t('weekdays.sunday_abbreviation')),
                '</label>',
            '</div>'
        ].join('');
    }

    function getConfigurationContainer($subNode) {
        const $configurationContainer = $subNode.parents('.configuration');

        if (!$configurationContainer.length) {
            return null;
        }

        return $configurationContainer;
    }

    function hasModifications() {
        return !compare(this.PrevAutomaticStatusChange, this.AutomaticStatusChange, 'object') ||
                !compare(this.PrevAutomaticStatusChangeByKeyword, this.AutomaticStatusChangeByKeyword, 'object');
    }

    function getAutomaticStatusChange(keywordId) {
        let automaticStatusChange;


        if (keywordId) {
            automaticStatusChange = this.AutomaticStatusChangeByKeyword[keywordId];

            if (!automaticStatusChange) {
                automaticStatusChange = this.AutomaticStatusChangeByKeyword[keywordId] = {};
            }

            return automaticStatusChange;
        }

        automaticStatusChange = this.AutomaticStatusChange;

        if (!automaticStatusChange) {
            automaticStatusChange = this.AutomaticStatusChange = {};
        }

        return automaticStatusChange;
    }

    function resize() {
        $(window).trigger('resize.' + this.options.ID);
    }

    global.AutomaticStatusChangeWindow = AutomaticStatusChangeWindow;
})(Modifications.Popups || (Modifications.Popups = {}));