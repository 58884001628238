/**
 * @require ../_base/page.js
 * @require ../_base/page-tab.js
 */
(function (global) {
    var cpRegex = /{{CP_([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})}}/ig;
    var issueRegex = /{{([A-Z]\w+)}}/ig;

    var MailTemplatesView = function () {
        var availableTabs = getAvailableTabs.call(this);

        global.Base.Page.call(this, availableTabs);
    };

    MailTemplatesView.prototype = Object.create(global.Base.Page.prototype, {
        constructor: MailTemplatesView
    });

    MailTemplatesView.prototype.SaveEntity = function () {
        return MailTemplates.Save(CurrentEntity);
    };

    MailTemplatesView.prototype.OnBeforeDestroy = function () {
        if (this.PlaceholdersWindow) {
            this.PlaceholdersWindow.Close();
        }
    };

    function getAvailableTabs() {
        return [
            new global.Base.PageTab(
                'properties', getAvailableProperties.call(this)
            )
        ];
    }

    function getAvailableProperties() {
        return [
            new global.Base.Properties.PropertyGroup({
                ID: 'mail-templates-group-1',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.TextLineProperty({
                        Caption: i18next.t('changeMode.panels.properties.title'),
                        ID: 'mail-templates-template-title',
                        MaxLength: 100,
                        MappedProperty: 'Title',
                        IsRequired: true
                    }),
                    new global.Base.Properties.TextMemoProperty({
                        Caption: i18next.t('changeMode.panels.properties.description'),
                        ID: 'mail-templates-template-description',
                        Rows: 3,
                        MaxLength: 200,
                        MappedProperty: 'Description'
                    })
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'mail-templates-group-2',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.ResettableButtonProperty({
                        Caption: i18next.t('changeMode.panels.properties.mailTemplates.form.propertyCaption'),
                        ID: 'mail-templates-select-form',
                        Source: changemode.Forms,
                        MappedProperty: 'FormOID',
                        OnSelectClick: $.proxy(onBtnSelectFormClick, this),
                        OnResetClick: $.proxy(onBtnResetFormClick, this),
                        ValueFormatter: formatFormValue
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        ID: 'mail-templates-toolbar',
                        Buttons: [{
                            ID: 'show-placeholders',
                            Caption: i18next.t('changeMode.panels.properties.mailTemplates.openPlaceholdersWindow'),
                            Icon: './img/brackets.svg',
                            OnClick: $.proxy(onBtnOpenPlaceholdersWindowsClick, this)
                        }, {
                            ID: 'show-preview',
                            Caption: i18next.t('changeMode.panels.properties.mailTemplates.showPreview'),
                            Icon: './img/eye.svg',
                            OnClick: $.proxy(onBtnShowMailTemplatePreview, this)
                        }]
                    }),
                    new global.Base.Properties.TextLineProperty({
                        Caption: i18next.t('changeMode.panels.properties.mailTemplates.subject.propertyCaption'),
                        ID: 'mail-templates-mail-subject',
                        MappedProperty: 'Subject'
                    }),
                    new global.Base.Properties.RichTextProperty({
                        Caption: i18next.t('changeMode.panels.properties.mailTemplates.body'),
                        ID: 'mail-templates-mail-body',
                        Rows: 10,
                        MappedProperty: 'Body'
                    }),
                    new global.Base.Properties.TableProperty({
                        Caption: i18next.t('changeMode.panels.properties.mailTemplates.attachmentFiles'),
                        ID: 'mail-templates-select-attachment-files',
                        MappedProperty: 'AttachmentFiles',
                        OnSelectClick: $.proxy(onBtnSelectAttachmentFilesClick, this),
                        OnRemoveClick: $.proxy(onBtnRemoveFileClick, this),
                        GetActionDropdownItems: $.proxy(getAttachmentFilesActionDropdownItems, this)
                    }),
                ]
            })
        ];
    }

    function onBtnSelectFormClick(evt) {
        var $button = $(evt.currentTarget);
        var me = this;

        var formProperty = this.CurrentTab.PropertyMap['mail-templates-select-form'];
        var options = {
            title: i18next.t('elementTypes.form_plural'),
            selectMultiple: false,
            onSelect: function (formIdentifier) { onSelectForm.call(me, $button, formIdentifier); },
            treeOptions: {
                schema: function (form) {
                    return {
                        id: form.OID,
                        text: form.Title,
                        color: form.Color,
                        children: form.Children
                    };
                },
                opened: true,
                selectable: true,
                selected: formProperty.GetValue(),
                filter: function (form) {
                    return form.ModificationType !== Enums.ModificationType.Deleted;
                }
            }
        };

        if (this.PlaceholdersWindow) {
            this.PlaceholdersWindow.Close();
        }

        TreePicker.Show(changemode.Forms, options);
    }

    function onSelectForm($button, formIdentifier) {
        var form = changemode.Elements[formIdentifier];
        var buttonText = i18next.t('misc.noSelection');

        var formProperty = this.CurrentTab.PropertyMap['mail-templates-select-form'];
        var subjectProperty = this.CurrentTab.PropertyMap['mail-templates-mail-subject'];
        var bodyProperty = this.CurrentTab.PropertyMap['mail-templates-mail-body'];

        var previousFormIdentifier = formProperty.GetValue();
        var subject = subjectProperty.GetValue();
        var body = bodyProperty.GetValue();

        function applyChanges() {
            var $selection = $button.parents('.clearable-button-selection');

            $selection
                .attr('data-value', formIdentifier)
                .data('value', formIdentifier);

            $button.html(buttonText);

            subject = removeConflictedPlaceholdersFromText(subject, formIdentifier);
            body = removeConflictedPlaceholdersFromText(body, formIdentifier);

            subjectProperty.SetValue(subject);
            bodyProperty.SetValue(body);

            changemode.CurrentPage.CurrentTab.MarkAsModified();
        }

        if (form) {
            if (!!form.Color) {
                buttonText = '<span class="color" style="background-color: {0};"></span> '
                    .format(form.Color);
            }

            if (!!form.Title) {
                buttonText += ' ' + form.Title;
            }
        }

        if (previousFormIdentifier === formIdentifier) {
            return;
        }

        var isConflicted = getHasCheckpointPlaceholderConflicts(subject, formIdentifier) ||
            getHasCheckpointPlaceholderConflicts(body, formIdentifier);

        if (isConflicted) {
            showCheckpointPlaceholderConflicts(
                subject,
                body,
                previousFormIdentifier,
                formIdentifier,
                applyChanges
            );
            return;
        }

        applyChanges();
    }

    function showCheckpointPlaceholderConflicts(subject, body, currentFormIdentifier, newFormIdentifier, applyChanges) {
        if (!subject && !body) {
            return;
        }

        var subjectWithConflicts = getTextWithHighlightedConflictedPlaceholders(subject, newFormIdentifier, true);
        var bodyWithConflicts = getTextWithHighlightedConflictedPlaceholders(body, newFormIdentifier, true);

        var currentForm = changemode.Elements[currentFormIdentifier];
        var newForm = changemode.Elements[newFormIdentifier];

        new Modifications.Popups.MailTemplatePreview({
            Title: i18next.t('changeMode.mailTemplates.conflictsWindow.title'),
            IntroductionText: i18next.t('changeMode.mailTemplates.conflictsWindow.introduction'),
            Subject: subjectWithConflicts,
            Body: bodyWithConflicts,
            CurrentForm: currentForm,
            NewForm: newForm,
            Buttons: {
                No: {
                    Caption: i18next.t('misc.abort')
                },
                Yes: {
                    Caption: i18next.t('misc.apply'),
                    OnClick: applyChanges
                }
            }
        }).Show();
    }

    function formatFormValue(formIdentifier) {
        if (!formIdentifier) {
            return null;
        }

        var form = changemode.Elements[formIdentifier];

        if (!form || form.ModificationType === Enums.ModificationType.Deleted) {
            return null;
        }

        return [
            '<span class="color" style="background-color:', form.Color, '"></span>',
            form.Title
        ].join('');
    }

    function onBtnResetFormClick() {
        if (this.PlaceholdersWindow) {
            this.PlaceholdersWindow.Close();
        }

        var $btn = $('#mail-templates-select-form .select');

        onSelectForm.call(this, $btn, null);
    }

    function onBtnSelectAttachmentFilesClick() {
        if (this.PlaceholdersWindow) {
            this.PlaceholdersWindow.Close();
        }

        var me = this;

        ChangeMode.FilePicker.Show({
            onPick: function (file) {
                onSelectAttachmentFiles.call(me, file.OID);
            },
            filterTypes: [Enums.FileType.File]
        });
    }

    function onBtnRemoveFileClick() {
        changemode.CurrentPage.CurrentTab.MarkAsModified();
    }

    function onSelectAttachmentFiles(fileIdentifier) {
        if (!fileIdentifier) {
            return;
        }

        var property = this.CurrentTab.PropertyMap['mail-templates-select-attachment-files'];
        var currentlySelectedFiles = property.GetValue();

        currentlySelectedFiles = currentlySelectedFiles || [];
        currentlySelectedFiles = Tools.addUnique(currentlySelectedFiles, fileIdentifier)
            .filter(function (identifier) {
                var file = changemode.Files[identifier];

                return file && file.ModificationType !== Enums.ModificationType.Deleted;
            });

        property.SetValue(currentlySelectedFiles);
        property.AddFileToTable(fileIdentifier);

        changemode.CurrentPage.CurrentTab.MarkAsModified();
    }

    function getAttachmentFilesActionDropdownItems() {
        return [{
            Caption: i18next.t('misc.fileTable.actionDropdown.addFileFromCatalog'),
            ID: 'add-file-from-catalog',
            OnClick: $.proxy(onBtnSelectAttachmentFilesClick, this),
            Icon: './img/datei-aus-katalog.svg'
        }, {
            Caption: i18next.t('misc.fileTable.actionDropdown.uploadFile'),
            ID: 'upload-new-file',
            Icon: './img/datei.svg',
            FileInput: {
                InitUpload: initAttachmentFileUpload,
                Accept: '*/*',
                Multiple: true
            }
        }];
    }

    function initAttachmentFileUpload($container) {
        var options = {
            url: Config.BaseUri + 'files',
            params: function (fileData) {
                var filename = fileData.name.split('\\').pop();

                return {
                    FolderOID: changemode.RootFolder.Children[0].OID,
                    Title: filename,
                    IsAvailableOffline: true
                };
            }
        };

        var $fileUpload = $container.find('.file-upload');
        var uploadedFiles = [];
        var prop = this;

        $fileUpload.upload(options);
        $fileUpload
            .on('start.upload', Tools.Spinner.show)
            .on('uploaded.upload', function (_event, data) {
                var file = JSON.parse(data.response);

                if (Tools.Files.isImage(file.MimeType)) {
                    file.Metrics = {
                        s: { width: 0, height: 0 },
                        m: { width: 0, height: 0 },
                        l: { width: 0, height: 0 },
                        o: { width: 0, height: 0 }
                    };

                    var tmp;

                    if ((tmp = (file.SizeS || '').split('x')).length === 2) {
                        file.Metrics.s.width = parseInt(tmp[0], 10) || 0;
                        file.Metrics.s.height = parseInt(tmp[1], 10) || 0;
                    }

                    if ((tmp = (file.SizeM || '').split('x')).length === 2) {
                        file.Metrics.m.width = parseInt(tmp[0], 10) || 0;
                        file.Metrics.m.height = parseInt(tmp[1], 10) || 0;
                    }

                    if ((tmp = (file.SizeL || '').split('x')).length === 2) {
                        file.Metrics.l.width = parseInt(tmp[0], 10) || 0;
                        file.Metrics.l.height = parseInt(tmp[1], 10) || 0;
                    }

                    if ((tmp = (file.SizeOriginal || '').split('x')).length === 2) {
                        file.Metrics.o.width = parseInt(tmp[0], 10) || 0;
                        file.Metrics.o.height = parseInt(tmp[1], 10) || 0;
                    }

                    changemode.Files[file.OID] = file;
                }

                changemode.Files = changemode.Files || {};
                changemode.Files[file.OID] = file;

                var folder = changemode.Folders[file.FolderOID];
                folder.Files = folder.Files || [];
                folder.Files.push(file);

                uploadedFiles.push(file.OID);

                if (data.numberOfFilesHandled !== data.numberOfFiles) {
                    return;
                }

                var currentlySelectedFiles = prop.GetValue() || [];
                currentlySelectedFiles = currentlySelectedFiles.concat(uploadedFiles);

                prop.SetValue(currentlySelectedFiles);
                uploadedFiles.forEach(function (fileIdentifier) {
                    prop.AddFileToTable(fileIdentifier);
                });

                uploadedFiles = [];

                changemode.CurrentPage.CurrentTab.MarkAsModified();
                Tools.Spinner.hide();
            })
            .on('error.upload', function (event, context) {
                if (context.data.numberOfFilesHandled === context.data.numberOfFiles) {
                    Tools.Spinner.hide();
                }

                Tools.OnUploadError(event, context);
            });
    }

    function onBtnOpenPlaceholdersWindowsClick() {
        var selectedFormOID = $('#mail-templates-select-form-value').data('value');
        var selectedForm = !!selectedFormOID ? changemode.Elements[selectedFormOID] : null;

        this.PlaceholdersWindow = new Modifications.Popups.System.MailTemplatePlaceholdersWindow(
            'mail-template-placeholders',
            '#mail-templates-mail-subject-value, #mail-templates-mail-body',
            selectedForm && selectedForm.ModificationType !== Enums.ModificationType.Deleted ? selectedForm : null
        ).Show();
    }

    function onBtnShowMailTemplatePreview() {
        var subjectProperty = this.CurrentTab.PropertyMap['mail-templates-mail-subject'];
        var bodyProperty = this.CurrentTab.PropertyMap['mail-templates-mail-body'];

        var subject = parseTextWithPlaceholders(subjectProperty.GetValue());
        var body = parseTextWithPlaceholders(bodyProperty.GetValue());

        new Modifications.Popups.MailTemplatePreview({
            Subject: subject,
            Body: body
        }).Show();
    }

    function getHasCheckpointPlaceholderConflicts(text, newFormIdentifier) {
        if (!text) {
            return false;
        }

        cpRegex.lastIndex = -1;

        var match;

        if (cpRegex.test(text)) {
            cpRegex.lastIndex = -1;

            while (match = cpRegex.exec(text)) {
                var cpIdentifier = match[1];
                var checkpoint = changemode.Elements[cpIdentifier];

                if (checkpoint &&
                    checkpoint.Parent &&
                    checkpoint.Parent.ParentOID !== newFormIdentifier) {
                    return true;
                }
            }
        }

        return false;
    }

    function getTextWithHighlightedConflictedPlaceholders(text, newFormIdentifier, returnOnlyIfMatchesFound) {
        if (!text) {
            return null;
        }

        cpRegex.lastIndex = -1;

        text = replaceCheckpointPlaceholders(text, function (text, match) {
            var cpIdentifier = match[1];
            var checkpoint = changemode.Elements[cpIdentifier];
            var group = checkpoint.Parent;

            if (checkpoint &&
                group &&
                group.ParentOID !== newFormIdentifier) {
                var checkpointText = '<span style="color:#7e1210;">(' + (group ? group.Title : '') + ' > ' + (checkpoint ? checkpoint.Title : '') + ')</span>';

                return text.replace(match[0], checkpointText);
            }

            return text;
        }, returnOnlyIfMatchesFound);

        text = replaceIssuePlaceholders(text, returnOnlyIfMatchesFound);

        return text;
    }

    function removeConflictedPlaceholdersFromText(text, newFormIdentifier) {
        if (!text) {
            return null;
        }

        text = replaceCheckpointPlaceholders(text, function (text, match) {
            var cpIdentifier = match[1];
            var checkpoint = changemode.Elements[cpIdentifier];
            var group = checkpoint.Parent;

            if (checkpoint &&
                group &&
                group.ParentOID !== newFormIdentifier) {
                return text.replace(match[0], '');
            }

            return text;
        });

        return text;
    }

    function parseTextWithPlaceholders(text) {
        if (!text) {
            return null;
        }

        text = replaceCheckpointPlaceholders(text);
        text = replaceIssuePlaceholders(text);

        return text;
    }

    function replaceCheckpointPlaceholders(text, replaceFunction, returnOnlyIfMatchesFound) {
        cpRegex.lastIndex = -1;

        if (!(replaceFunction instanceof Function)) {
            replaceFunction = function (text, match) {
                var cpIdentifier = match[1];
                var checkpoint = changemode.Elements[cpIdentifier];

                if (checkpoint) {
                    var group = checkpoint.Parent;
                    var checkpointText = '(' + (group ? group.Title : '') + ' > ' + (checkpoint ? checkpoint.Title : '') + ')';

                    return text.replace(match[0], checkpointText);
                }

                return text.replace(match[0], '');
            };
        }

        returnOnlyIfMatchesFound = returnOnlyIfMatchesFound || false;

        var match;
        var matchesFound = false;

        if (cpRegex.test(text)) {
            cpRegex.lastIndex = -1;

            while (match = cpRegex.exec(text)) {
                matchesFound = true;
                text = replaceFunction(text, match);

                cpRegex.lastIndex = -1;
            }
        }

        return returnOnlyIfMatchesFound && !matchesFound ? null : text;
    }

    function replaceIssuePlaceholders(text, replaceFunction, returnOnlyIfMatchesFound) {
        issueRegex.lastIndex = -1;

        if (!(replaceFunction instanceof Function)) {
            replaceFunction = function (text, match) {
                var placeholder = match[1];
                var placeholderText = '(' + getIssuePlaceholderText(placeholder) + ')';

                return text.replace(match[0], placeholderText);
            };
        }

        returnOnlyIfMatchesFound = returnOnlyIfMatchesFound || false;

        var match;
        var matchesFound = false;

        if (issueRegex.test(text)) {
            issueRegex.lastIndex = -1;

            while (match = issueRegex.exec(text)) {
                matchesFound = true;
                text = replaceFunction(text, match);

                issueRegex.lastIndex = -1;
            }
        }

        return returnOnlyIfMatchesFound && !matchesFound ? null : text;
    }

    function getIssuePlaceholderText(placeholder) {
        if (!placeholder) {
            return null;
        }

        placeholder = placeholder[0].toLowerCase() + placeholder.substr(1);

        return i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.' + placeholder + '.title');
    }

    if (!global.System) {
        global.System = {};
    }

    global.System.MailTemplates = MailTemplatesView;
})(Modifications.Pages || (Modifications.Pages = {}));