/**
 * @require ./property.js
 */
(function (global) {
    var TimeProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.TextLine;

        if (!(definition.OnChange instanceof Function)) {
            definition.OnChange = function () {
                changemode.CurrentPage.CurrentTab.MarkAsModified();
            };
        }

        global.Base.Properties.Base.call(this, definition);
    };

    TimeProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: TimeProperty
    });

    TimeProperty.prototype.RenderInput = function () {
        var html = [
            '<div>',
                '<select id="', this.ID, '-hour" class="tiny hour-property">'
        ];

        for (var hr = 0, maxHr = 23; hr <= maxHr; hr++) {
            html.push('<option value="', hr, '">', ('' + hr).padleft('0', 2), '</option>');
        }

        html.push(
                '</select>',
                '<select id="', this.ID, '-minute" class="tiny minute-property">'
        );

        for (var min = 0, maxMin = 59; min <= maxMin; min++) {
            html.push('<option value="', min, '">', ('' + min).padleft('0', 2), '</option>');
        }

        html.push(
                '</select>',
            '</div>'
        );

        return html.join('');
    };

    TimeProperty.prototype.OnAfterRendered = function () {
        var value = CurrentEntity[this.MappedProperty];

        if (!(value instanceof Date)) {
            return null;
        }

        var $hrProp = $('#' + this.ID + '-hour');
        var $minProp = $('#' + this.ID + '-minute');

        if (!$hrProp.length || !$minProp.length) {
            return null;
        }

        $hrProp.val(value.getHours());
        $minProp.val(value.getMinutes());

        this.BindEvents.call(this);
    };

    TimeProperty.prototype.BindEvents = function () {
        var $hrProp = $('#' + this.ID + '-hour');
        var $minProp = $('#' + this.ID + '-minute');

        if (!$hrProp.length || !$minProp.length) {
            return null;
        }

        $hrProp.on('input', this.OnChange);
        $minProp.on('input', this.OnChange);
    };

    TimeProperty.prototype.SetValue = function (value) {
        if (!(value instanceof Date)) {
            return null;
        }

        var $hrProp = $('#' + this.ID + '-hour');
        var $minProp = $('#' + this.ID + '-minute');

        if (!$hrProp.length || !$minProp.length) {
            return null;
        }

        $hrProp.val(value.getHours());
        $minProp.val(value.getMinutes());
    };

    TimeProperty.prototype.GetValue = function () {
        var $hrProp = $('#' + this.ID + '-hour');
        var $minProp = $('#' + this.ID + '-minute');

        if (!$hrProp.length || !$minProp.length) {
            return null;
        }

        var hour = parseInt($hrProp.val(), 10) || 0;
        var minute = parseInt($minProp.val(), 10) || 0;

        var date = new Date();

        date = new Date(date.setHours(hour, minute, 0, 0));

        return date;
    };

    TimeProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.TimeProperty = TimeProperty;
})(Modifications.Pages || (Modifications.Pages = {}));