/**
 * @require ./property.js
 */
(function (global) {
    var ButtonRowProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.Buttons;

        global.Base.Properties.Base.call(this, definition);
    };

    ButtonRowProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: ButtonRowProperty
    });

    ButtonRowProperty.prototype.RenderInput = function () {
        var me = this;

        return (this.Buttons || [])
            .map(function (buttonConfig) {
                return renderButton(buttonConfig, me);
            })
            .join('');
    };

    function renderButton(buttonConfig, property) {
        var html = ['<div class="button-steal ', buttonConfig.ID];

        if (buttonConfig.Icon) {
            html.push(' with-icon');
        }

        html.push('">');

        if (buttonConfig.Icon) {
            html.push(
                '<div class="icon">',
                    '<img src="', buttonConfig.Icon, '" />',
                '</div>',
                '<div class="caption">',
                    buttonConfig.Caption,
                '</div>'
            );
        } else if (buttonConfig.CaptionFormatter instanceof Function) {
            var unformattedValue = CurrentEntity[property.MappedProperty];
            var formattedCaption = buttonConfig.CaptionFormatter(unformattedValue);

            if (!formattedCaption) {
                formattedCaption = buttonConfig.Caption;
            }

            html.push(formattedCaption);
        } else {
            html.push(buttonConfig.Caption);
        }

        html.push('</div>');

        return html.join('');
    }

    ButtonRowProperty.prototype.OnAfterRendered = function () {
        var $prop = $('#' + this.ID);

        if (!$prop.length) {
            return;
        }

        var value = CurrentEntity[this.MappedProperty];

        $prop.data('value', value);

        this.BindEvents.call(this);
    };

    ButtonRowProperty.prototype.BindEvents = function () {
        var $prop = $('#' + this.ID);

        if (!$prop.length) {
            return;
        }

        var me = this;

        this.Buttons.forEach(function (buttonConfig) {
            var $button = $prop.find('.' + buttonConfig.ID);

            if (!$button.length || !(buttonConfig.OnClick instanceof Function)) {
                return;
            }

            $button.on('click', $.proxy(buttonConfig.OnClick, me));
        });
    };

    ButtonRowProperty.prototype.SetValue = function (value) {
        var $prop = $('#' + this.ID);

        if (!$prop.length) {
            return;
        }

        if (value instanceof Array) {
            value = value.join('|');
        }

        $prop
            .data('value', value)
            .attr('data-value', value);
    };

    ButtonRowProperty.prototype.GetValue = function () {
        var $prop = $('#' + this.ID);

        if (!$prop.length) {
            return;
        }

        var value = $prop.data('value');

        if (this.AllowMultiSelection && typeof value === 'string') {
            return value.split('|');
        }

        return value;
    };

    ButtonRowProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.ButtonRowProperty = ButtonRowProperty;
})(Modifications.Pages || (Modifications.Pages = {}));