/**
 * @require ./_base.js
 */
(function (global) {
    /**
     * @param options
     * @augments {PopupBase}
     * @constructor
     */
    var CheckpointFilterWindow = function (options) {
        if (!options.ID) {
            options.ID = 'checkpoint-filter-window';
        }

        if (!options.Title) {
            options.Title = i18next.t('changeMode.panels.checkpoints.filterWindow.windowTitle');
        }

        options.ShowOverlay = true;
        options.MaxHeight = 300;
        options.MaxWidth = 410;
        options.ResizeToFitToContent = true;
        options.Styles = {
            '_': {
                'z-index': 13001
            },
            '.content': {
                padding: '1rem'
            },
            '.gfx-table': {
                padding: 0,
                'margin-bottom': '1rem'
            },
            '.content > label': {
                display: 'block',
            },
            '.content > label:not(:first-child)': {
                'margin-top': '1rem'
            }
        };

        this.Filters = options.Filters || {};

        global.PopupBase.call(this, options);
    };

    CheckpointFilterWindow.prototype = Object.create(global.PopupBase.prototype, {
        constructor: CheckpointFilterWindow
    });

    CheckpointFilterWindow.prototype.CreateContentMarkup = function () {
        var html = [
            '<label for="' + this.options.ID + '-' + 'text-filter' + '">',
                i18next.t('changeMode.panels.checkpoints.filterWindow.textSearch'),
            '</label>',
            '<input id="' + this.options.ID + '-' + 'text-filter' + '" class="xlarge" type="text">',

            '<label>',
                i18next.t('changeMode.panels.checkpoints.filterWindow.keywords'),
            '</label>',
            '<div class="clearable-button-selection">',
                '<div class="btn button-steal btn-keywords">',
                    getSelectedKeywordsText.call(this),
                '</div>',
                '<div class="button-steal btn-reset clear">×</div>',
            '</div>',

            '<label>',
                i18next.t('changeMode.panels.checkpoints.filterWindow.categories'),
            '</label>',
            '<div class="clearable-button-selection">',
                '<div class="btn button-steal btn-categories">',
                    getSelectedCategoriesText.call(this),
                '</div>',
                '<div class="button-steal btn-reset clear">×</div>',
            '</div>'
        ];

        return html.join('');
    };

    CheckpointFilterWindow.prototype.BindAdditionalEvents = function () {
        this.$content.find('#' + this.options.id + '-' + 'text-filter').on('input', $.proxy(onTextFilterInput, this));
        this.$content.find('.btn-keywords').on('click', $.proxy(onShowKeywordPicker, this));
        this.$content.find('.btn-keywords + .btn-reset').on('click', $.proxy(onClearKeywords, this));
        this.$content.find('.btn-categories').on('click', $.proxy(onShowCategoriesPicker, this));
        this.$content.find('.btn-categories + .btn-reset').on('click', $.proxy(onClearCategories, this));
    };

    CheckpointFilterWindow.prototype.OnAfterRendered = function () {
        this.$content.find('#' + this.options.id + '-' + 'text-filter')
            .val(this.Filters.Text)
            .focus();
    };

    function onTextFilterInput(evt) {
        this.Filters.Text = $.trim($(evt.currentTarget).val());
    }

    function getSelectedKeywordsText() {
        var selectedKeywords = (this.Filters.Keywords || []).filter(function (oid) {
            var keyword = changemode.Properties[oid];

            return keyword && keyword.ModificationType !== Enums.ModificationType.Deleted;
        });

        if (selectedKeywords.length) {
            return i18next.t('changeMode.panels.checkpoints.filterWindow.selectedKeywords', {
                count: selectedKeywords.length
            });
        }

        return i18next.t('changeMode.panels.checkpoints.filterWindow.selectKeywords');
    }

    function getSelectedCategoriesText() {
        var selectedCategories = (this.Filters.Categories || []).filter(function (oid) {
            var category = changemode.Properties[oid];

            return category && category.ModificationType !== Enums.ModificationType.Deleted;
        });

        if (selectedCategories.length) {
            return i18next.t('changeMode.panels.checkpoints.filterWindow.selectedCategories', {
                count: selectedCategories.length
            });
        }

        return i18next.t('changeMode.panels.checkpoints.filterWindow.selectCategories');
    }

    function onShowKeywordPicker(evt) {
        var me = this;
        var $btn = $(evt.currentTarget);
        var options = {
            title: i18next.t('changeMode.panels.checkpoints.filterWindow.selectKeywords'),
            checkedEntities: this.Filters.Keywords,
            onApply: function (keywords) {
                me.Filters.Keywords = keywords;
                $btn.text(getSelectedKeywordsText.call(me));
            },
            treeOptions: {
                schema: {id: 'OID', text: 'Title', children: 'Children'},
                opened: true,
                checkbox: {fullrow: true},
                filter: function (entity) {
                    return entity.ModificationType !== Enums.ModificationType.Deleted;
                }
            }
        };

        TreePicker.Show(changemode.Keywords, options);
    }

    function onShowCategoriesPicker(evt) {
        var me = this;
        var $btn = $(evt.currentTarget);
        var options = {
            title: i18next.t('changeMode.panels.checkpoints.filterWindow.selectCategories'),
            checkedEntities: this.Filters.Categories,
            onApply: function (categories) {
                me.Filters.Categories = categories;
                $btn.text(getSelectedCategoriesText.call(me));
            },
            treeOptions: {
                schema: {id: 'OID', text: 'Title', children: 'Children'},
                opened: true,
                checkbox: {fullrow: true},
                filter: function (entity) {
                    return entity.ModificationType !== Enums.ModificationType.Deleted;
                }
            }
        };

        TreePicker.Show(changemode.Categories, options);
    }

    function onClearKeywords(evt) {
        this.Filters.Keywords = null;

        var $btnSelectKeywords = $(evt.currentTarget).siblings('.btn-keywords');
        $btnSelectKeywords.text(getSelectedKeywordsText.call(this));
    }

    function onClearCategories(evt) {
        this.Filters.Categories = null;

        var $btnSelectKeywords = $(evt.currentTarget).siblings('.btn-categories');
        $btnSelectKeywords.text(getSelectedCategoriesText.call(this));
    }

    global.CheckpointFilterWindow = CheckpointFilterWindow;
})(Modifications.Popups || (Modifications.Popups = {}));