/**
 * @require ./property.js
 */
(function (global) {
    var NumberProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.Number;

        if (!(definition.OnChange instanceof Function)) {
            definition.OnChange = function () {
                changemode.CurrentPage.CurrentTab.MarkAsModified();
            };
        }

        global.Base.Properties.Base.call(this, definition);
    };

    NumberProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: NumberProperty
    });

    NumberProperty.prototype.RenderInput = function () {
        var html = [
            '<input type="number" id="', this.ID + '-value"',
            ' class="xlarge" placeholder="', i18next.t('misc.enterNumber'), '">'
        ];

        return html.join('');
    };

    NumberProperty.prototype.OnAfterRendered = function () {
        var value = parseInt(CurrentEntity[this.MappedProperty], 10);
        var $prop = $('#' + this.ID + '-value');

        if (isNaN(value) || !$prop.length) {
            return;
        }

        if (!isNaN(this.ConversionFactor)) {
            value = parseInt(value / this.ConversionFactor, 10);
        }

        $prop.val(value || '');

        this.BindEvents.call(this);
    };

    NumberProperty.prototype.Validate = function () {
        var value = this.GetValue();

        if (typeof this.Minimum === 'number' &&
            value < this.Minimum) {
            return false;
        }

        if (typeof this.Maximum === 'number' &&
            value > this.Maximum) {
            return false;
        }

        return !this.IsRequired || value !== '' && value != null;
    };

    NumberProperty.prototype.BindEvents = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        $prop.on('input', this.OnChange);
    };

    NumberProperty.prototype.SetValue = function (value) {
        var $prop = $('#' + this.ID + '-value');

        if (isNaN(value) || !$prop.length) {
            return null;
        }

        if (!isNaN(this.ConversionFactor)) {
            value = parseInt(value * this.ConversionFactor, 10);
        }

        $prop.val(value);
    };

    NumberProperty.prototype.GetValue = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        return $prop.val();
    };

    NumberProperty.prototype.ApplyValue = function () {
        var value = this.GetValue();

        if (!isNaN(this.ConversionFactor)) {
            value = parseInt(value * this.ConversionFactor, 10);
        }

        CurrentEntity[this.MappedProperty] = value;
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.NumberProperty = NumberProperty;
})(Modifications.Pages || (Modifications.Pages = {}));