/**
 * @require ./_base.js
 */
(function (global) {
    const OUActionTypes = {
        Move: 0,
        Delete: 1
    }

    /**
     * @param options
     * @augments {PopupBase}
     * @constructor
     */
    const FixIssuesForCommit = function (options) {
        if (!options.ID) {
            options.ID = 'fix-issues-for-commit';
        }

        options.ResizeToFitToContent = true;
        options.ShowOverlay = true;

        if (!options.Buttons) {
            options.Buttons = {
                Close: {
                    Caption: i18next.t('misc.close')
                }
            };
        }

        options.Styles = {
            '_': {
                'z-index': 14001,
                width: 650
            },
            '.content': {
                overflow: 'auto',
                padding: '10px'
            },
            '.content > p:first-child': {
                margin: '0 0 1rem 0'
            },
            '.row-wrapper': {
                display: 'flex',
                gap: '1rem',
                'align-items': 'center'
            },
            '.row-wrapper > label': {
                width: '7rem',
                'text-overflow': 'ellipsis',
                overflow: 'hidden'
            },
            '.row-wrapper + .row-wrapper': {
                'margin-top': '.5rem'
            },
            '.fix-type-wrapper + .fix-type-wrapper': {
                'margin-top': '1rem'
            },
            '.fix-type-wrapper .info': {
                'margin-bottom': '.5rem'
            },
            '.select-ou-action-type': {
                'width': 'fit-content',
                'margin-top': '0',
                'height': '30px'
            }
        };

        this.HasStatusConflict = options.HasStatusConflict;
        this.HasOrganizationUnitConflict = options.HasOrganizationUnitConflict;

        if (this.HasStatusConflict) {
            const defaultModifiedOpenStatus = changemode.Properties[Client.Settings.TicketOpened];
            const defaultModifiedClosedStatus = changemode.Properties[Client.Settings.TicketCompleted];

            if (defaultModifiedOpenStatus.ModificationType === Enums.ModificationType.NotModified ||
                defaultModifiedOpenStatus.ModificationType === Enums.ModificationType.Edited) {
                this.SelectedOpenStatusOID = defaultModifiedOpenStatus.OID;
            }

            if (defaultModifiedClosedStatus.ModificationType === Enums.ModificationType.NotModified ||
                defaultModifiedClosedStatus.ModificationType === Enums.ModificationType.Edited) {
                this.SelectedClosedStatusOID = defaultModifiedClosedStatus.OID;
            }
        }

        if (this.HasOrganizationUnitConflict) {
            this.SelectedOrganizationUnitActionType = OUActionTypes.Move;
            this.SelectedOrganizationUnitOID = null;
        }

        global.PopupBase.call(this, options);
    };

    FixIssuesForCommit.prototype = Object.create(global.PopupBase.prototype, {
        constructor: FixIssuesForCommit
    });

    FixIssuesForCommit.prototype.CreateContentMarkup = function () {
        return [
            '<p class="message-text">',
                i18next.t('changeMode.messages.commitModificationsFixIssues.text'),
            '</p>',
            getOrganizationUnitFixMarkup.call(this),
            getStatusFixMarkup.call(this)
        ].join('');
    };

    FixIssuesForCommit.prototype.BindAdditionalEvents = function () {
        this.$content.find('.btn-select-open-status').on('click', $.proxy(onSelectOpenStatus, this));
        this.$content.find('.btn-select-closed-status').on('click', $.proxy(onSelectClosedStatus, this));
        this.$content.find('.btn-select-organization-unit').on('click', $.proxy(onShowOrganizationUnitSelection, this));
        this.$content.find('.select-ou-action-type').on('change', $.proxy(onChangeOuActionType, this));
    };

    FixIssuesForCommit.prototype.OnAfterRendered = function () {
        updateYesButtonState.call(this);
    };

    function getStatusFixMarkup() {
        if (!this.HasStatusConflict) {
            return;
        }

        return [
            '<div class="fix-type-wrapper">',
                '<div class="info">', i18next.t('changeMode.messages.commitModificationsFixIssues.status.deletedStatus'), '</div>',
                '<div class="row-wrapper">',
                    '<label>', i18next.t('changeMode.messages.commitModificationsFixIssues.status.openStatus'), ':</label>',
                    '<span class="btn-steal btn-select-open-status">', createButtonCaption(Properties[this.SelectedOpenStatusOID]), '</span>',
                '</div>',
                '<div class="row-wrapper">',
                    '<label>', i18next.t('changeMode.messages.commitModificationsFixIssues.status.closedStatus'), ':</label>',
                    '<span class="btn-steal btn-select-closed-status">', createButtonCaption(Properties[this.SelectedClosedStatusOID]), '</span>',
                '</div>',
            '</div>'
        ].join('');
    }

    function getOrganizationUnitFixMarkup() {
        if (!this.HasOrganizationUnitConflict) {
            return;
        }

        return [
            '<div class="fix-type-wrapper">',
                '<div class="info">', i18next.t('changeMode.messages.commitModificationsFixIssues.organizationUnit.deletedOrganizationUnit'), '</div>',
                '<div class="row-wrapper">',
                    '<select class="select-ou-action-type">',
                        '<option value="0">', i18next.t('changeMode.messages.commitModificationsFixIssues.organizationUnit.move'), '</option>',
                        '<option value="1">', i18next.t('changeMode.messages.commitModificationsFixIssues.organizationUnit.delete'), '</option>',
                    '</select>',
                    '<span class="btn-steal btn-select-organization-unit">', createButtonCaption(this.SelectedOrganizationUnitOID), '</span>',
                '</div>',
            '</div>'
        ].join('');
    }

    function createButtonCaption(titledAndColored) {
        if (!titledAndColored) {
            return i18next.t('changeMode.messages.commitModificationsFixIssues.pleaseSelect');
        }

        return [
            '<span class="color" style="background-color:', titledAndColored.Color, '";"></span>',
            Tools.escapeHtml(titledAndColored.Title)
        ].join('');
    }

    function onSelectOpenStatus(evt) {
        const $btn = $(evt.currentTarget);
        const me = this;

        showStatusSelection(me.SelectedOpenStatusOID, false, (status) => {
            me.SelectedOpenStatusOID = status.OID;
            $btn.html(createButtonCaption(status));

            updateYesButtonState.call(me);
        });
    }

    function onSelectClosedStatus(evt) {
        const $btn = $(evt.currentTarget);
        const me = this;

        showStatusSelection(me.SelectedClosedStatusOID, true, (status) => {
            me.SelectedClosedStatusOID = status.OID;
            $btn.html(createButtonCaption(status));

            updateYesButtonState.call(me);
        });
    }

    function showStatusSelection(selectedStatusOID, showClosedStatus, callback) {
        Tools.WindowWrappers.ShowStatusSelection({
            selectedStatusses: [selectedStatusOID],
            selectMultiple: false,
            windowCaption: i18next.t('issueViewer.panels.settings.selectStatus'),
            showCheckbox: false,
            onApplySelection: (statusOID) => callback(Properties[statusOID]),
            statusState: showClosedStatus ? Enums.StatusState.Closed : Enums.StatusState.Open,
            source: Properties,
            sourceTree: IssueStates,
            additionalFilter: (status) => {
                if (status.IsDeletedState) {
                    return false;
                }

                const modifiedStatus = changemode.Properties[status.OID];

                if (!modifiedStatus) {
                    return false;
                }

                return modifiedStatus.ModificationType === Enums.ModificationType.NotModified ||
                    modifiedStatus.ModificationType === Enums.ModificationType.Edited;
            }
        });
    }

    function onChangeOuActionType(evt) {
        const $select = $(evt.currentTarget);
        const me = this;

        const value = parseInt($select.val(), 10);

        if (isNaN(value)) {
            me.SelectedOrganizationUnitActionType = null;
            me.SelectedOrganizationUnitOID = null;
            return;
        }

        me.SelectedOrganizationUnitActionType = value;

        const $btnSelectOU = me.$content.find('.btn-select-organization-unit');

        if (value === OUActionTypes.Move) {
            $btnSelectOU.removeClass('hidden');
        } else if (value === OUActionTypes.Delete) {
            $btnSelectOU.addClass('hidden');
            me.SelectedOrganizationUnitOID = null;
        }

        updateYesButtonState.call(me);
    }

    function onShowOrganizationUnitSelection(evt) {
        const $btn = $(evt.currentTarget);
        const me = this;

        const options = {
            title: i18next.t('elementTypes.room'),
            selectMultiple: false,
            onSelect: (oid) => {
                const ou = DataManager.OrganizationUnitLoader.Data.DataMap[oid];
                $btn.html(createButtonCaption(ou));
                me.SelectedOrganizationUnitOID = oid;

                updateYesButtonState.call(me);
            },
            treeOptions: {
                schema: (oe) => {
                    return {
                        id: oe.OID,
                        text: oe.Title,
                        color: oe.Color,
                        children: oe.Children,
                        selectable: true,
                        classes: ''
                    };
                },
                selected: me.SelectedOrganizationUnitOID,
                opened: true,
                selectable: true,
                filter: (oe) => {
                    const changemodeElement = changemode.Elements[oe.OID];
                    return changemodeElement && changemodeElement.ModificationType !== Enums.ModificationType.Deleted;
                }
            }
        };

        TreePicker.Show(DataManager.OrganizationUnitLoader.Data.Root, options);
    }

    function updateYesButtonState() {
        const $btnYes = this.$bottom.find('.btn-yes');

        if (isValidConfiguration.call(this))
        {
            $btnYes.removeAttr('disabled');
        } else {
            $btnYes.attr('disabled', 'disabled');
        }
    }

    function isValidConfiguration() {
        if (this.HasOrganizationUnitConflict) {
            if (this.SelectedOrganizationUnitActionType == null) {
                return false;
            }

            if (this.SelectedOrganizationUnitActionType === OUActionTypes.Move && !this.SelectedOrganizationUnitOID) {
                return false;
            }
        }

        if (this.HasStatusConflict) {
            if (!this.SelectedOpenStatusOID) {
                return false;
            }

            if (!this.SelectedClosedStatusOID) {
                return false;
            }
        }

        return true;
    }

    global.FixIssuesForCommit = FixIssuesForCommit;
})(Modifications.Popups || (Modifications.Popups = {}));