/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @augments {BaseComponent}
     * @typedef GenericInformationComponent
     */
    global.Model.GenericInformationComponent = (function () {
        /**
         * @param {string|null} title
         * @param {string|null} description
         * @param {ComponentSettings?} settings
         * @param {string} icon
         * @constructor
         */
        function GenericInformationComponent(title, description, settings, icon) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.textLabel.label'),
                            Key: 'Textflow.Text',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiLineInput,
                            SubType: 'text'
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.font.label'),
                            Key: 'Textflow.Font',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Dropdown,
                            Options: [
                                {
                                    Value: 'Verdana',
                                    Label: 'Verdana'
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.label'),
                            Key: 'Textflow',
                            AllowMultiSelection: true,
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.bold'),
                                    Icon: './img/bold-solid.svg',
                                    Key: 'Bold'
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.italic'),
                                    Icon: './img/italic-solid.svg',
                                    Key: 'Italic'
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.underline'),
                                    Icon: './img/underline-solid.svg',
                                    Key: 'Underline'
                                }
                            ]
                        }
                    ]
                },
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.label'),
                            Key: 'Layout.HorizontalAlignment',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.left'),
                                    Icon: './img/align-left-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Left
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.center'),
                                    Icon: './img/align-center-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.right'),
                                    Icon: './img/align-right-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Right
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.justify'),
                                    Icon: './img/align-justify-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Justify
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.label'),
                            Key: 'Layout.VerticalAlignment',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.top'),
                                    Icon: './img/vertical-align-top.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Top
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.center'),
                                    Icon: './img/vertical-align-center.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.bottom'),
                                    Icon: './img/vertical-align-bottom.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Bottom
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.rotation.label'),
                            Key: 'Layout.Rotation',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: -360,
                            Max: 360,
                            Default: 0
                        },
                    ]
                }
            ];

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Text,
                title,
                description,
                icon,
                settings
            );
        }

        GenericInformationComponent.prototype = Object.create(global.Model.BaseComponent.prototype);

        GenericInformationComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();
            const additionalSettings = {
                Textflow: {
                    Text: this.Placeholder
                },
                Layout: {
                    HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                    VerticalAlignment: global.Model.Enums.VerticalAlignment.Top
                }
            };

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY,
                additionalSettings
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        /**
         * @return {string}
         */
        GenericInformationComponent.prototype.CreateComponentContent = function () {
            const markup = ['<span'];
            const styles = [];

            if (!!this.Textflow && !!this.Textflow.Color) {
                styles.push(`color="${this.Textflow.Color}"`);
            }

            if (typeof this.Layout.Rotation === 'number' && !isNaN(this.Layout.Rotation)) {
                styles.push(`transform:rotate(${this.Layout.Rotation}deg)`);
            }

            if (styles.length) {
                markup.push(` style="${styles.join(';')}"`);
            }

            markup.push(`>${DOMPurify.sanitize(this.Textflow.Text).replace(/\r?\n|\r/g, '<br />')}</span>`);

            return markup.join('');
        };

        GenericInformationComponent.prototype.constructor = GenericInformationComponent;

        return GenericInformationComponent;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));