/**
 * @require ./property.js
 */
(function (global) {
    var Label = function (definition) {
        if (!definition) {
            throw new Error('Label definition not given');
        }

        if (!definition.Caption) {
            throw new Error('No caption given');
        }

        for (var attr in definition) {
            if (!definition.hasOwnProperty(attr)) {
                continue;
            }

            this[attr] = definition[attr];
        }
    };

    Label.prototype.Render = function () {
        var html = [
            '<div class="label">',
            '<b class="caption">', this.Caption, '</b>',
        ];

        if (!!this.Description) {
            html.push('<p class="description">', this.Description, '</p>');
        }

        html.push('</div>');

        return html.join('');
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.Label = Label;
})(Modifications.Pages || (Modifications.Pages = {}));