/**
 * @require ./property.js
 */
(function (global) {
    var TextMemoProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.TextMemo;

        if (!(definition.OnChange instanceof Function)) {
            definition.OnChange = function () {
                changemode.CurrentPage.CurrentTab.MarkAsModified();
            };
        }

        global.Base.Properties.Base.call(this, definition);
    };

    TextMemoProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: TextMemoProperty
    });

    TextMemoProperty.prototype.RenderInput = function () {
        var html = [
            '<textarea id="', this.ID + '-value"',
            ' class="xlarge" placeholder="', i18next.t('misc.enterText'), '"'
        ];

        if (typeof this.Rows === 'number' && this.Rows > 0) {
            html.push(' rows="', this.Rows, '"');
        }

        if (typeof this.MaxLength === 'number' && this.MaxLength > 0) {
            html.push(' maxlength="', this.MaxLength, '"');
        }

        html.push(
            '>',
            '</textarea>'
        );

        return html.join('');
    };

    TextMemoProperty.prototype.OnAfterRendered = function () {
        var value = CurrentEntity[this.MappedProperty];
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        $prop.val(Tools.unescapeHtml(value) || '');

        this.BindEvents.call(this);
    };

    TextMemoProperty.prototype.BindEvents = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        $prop.on('input', this.OnChange);
    };

    TextMemoProperty.prototype.SetValue = function (value) {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        $prop.val(Tools.unescapeHtml(value));
    };

    TextMemoProperty.prototype.GetValue = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        return Tools.escapeHtml($prop.val());
    };

    TextMemoProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.TextMemoProperty = TextMemoProperty;
})(Modifications.Pages || (Modifications.Pages = {}));