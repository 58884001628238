(function (global) {
    var PageTab = function (id, propertyGroups) {
        if (!(propertyGroups || []).length) {
            return;
        }

        this.ID = id;
        this.PropertyGroups = propertyGroups;
        this.PropertyMap = {};

        var me = this;

        propertyGroups.forEach(function (group) {
            (group.Properties || []).forEach(function (prop) {
                me.PropertyMap[prop.ID] = prop;
            });
        });
    };

    PageTab.prototype.RenderProperties = function () {
        if (!(this.PropertyGroups || []).length) {
            throw new Error('No properties have been defined.');
        }

        content.properties.hideAllProperties();

        var $container = $('#properties');
        var renderedProperties = this.PropertyGroups.map(renderPropertyGroup).join('');
        var html = '<div class="dynamic-properties">' + renderedProperties + '</div>';

        $container
            .find('.dynamic-properties')
            .replaceWith(html);

        this.PropertyGroups.forEach(function (group) {
            (group.Properties || []).forEach(function (prop) {
                if (prop.OnAfterRendered instanceof Function) {
                    prop.OnAfterRendered();
                }
            });
        });
    };

    PageTab.prototype.ValidateProperties = function () {
        if (!(this.PropertyGroups || []).length) {
            throw new Error('No properties have been defined.');
        }

        var settingsAreValid = true;

        this.PropertyGroups.forEach(function (group) {
            (group.Properties || []).forEach(function (prop) {
                if (!(prop.Validate instanceof Function)) {
                    return;
                }

                var $prop = $('#' + prop.ID);

                if (prop.Validate()) {
                    $prop.removeClass('validation-error');
                    return;
                }

                $prop.addClass('validation-error');

                settingsAreValid = false;
            });
        });

        return settingsAreValid;
    };

    PageTab.prototype.ApplyValues = function () {
        if (!(this.PropertyGroups || []).length) {
            throw new Error('No properties have been defined.');
        }

        this.PropertyGroups.forEach(function (group) {
            (group.Properties || []).forEach(function (prop) {
                if (!(prop.ApplyValue instanceof Function)) {
                    return;
                }

                prop.ApplyValue();

                if (CurrentEntity[prop.MappedProperty] === '' ||
                    CurrentEntity[prop.MappedProperty] == null) {
                    delete CurrentEntity[prop.MappedProperty];
                }
            });
        });
    };

    PageTab.prototype.Destroy = function () {
        this.PropertyGroups.forEach(function (group) {
            $('#' + group.ID).remove();
        });
    }

    PageTab.prototype.MarkAsModified = function () {
        content.properties.setChanged(true);
    };

    function renderPropertyGroup(group) {
        if (!group || !group.Type === global.PropertyTypes.Group) {
            return null;
        }

        return group.Render();
    }

    if (!global.Base) {
        global.Base = {};
    }

    global.Base.PageTab = PageTab;
})(Modifications.Pages || (Modifications.Pages = {}));