/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @augments {BaseComponent}
     * @typedef UnknownComponent
     */
    global.Model.UnknownComponent = (function () {
        /**
         * @param {string|null} title
         * @param {string|null} description
         * @param {ComponentSettings} settings
         * @constructor
         */
        function UnknownComponent(title, description, settings) {
            global.Model.BaseComponent.call(this, 'unknown', title, description, null, settings);
        }

        UnknownComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        UnknownComponent.prototype.constructor = UnknownComponent;

        UnknownComponent.prototype.CreateComponentContent = function () {
            return 'unknown component';
        };

        return UnknownComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef TextComponent
     */
    global.Model.TextComponent = (function () {
        /**
         * @param {string|null} title
         * @param {string|null} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function TextComponent(title, description, settings) {
            const defaultFont = settings.Fonts.indexOf('Helvetica') >= 0 ? 'Helvetica' : 'Arial';

            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.textLabel.label'),
                            Key: 'Textflow.Text',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiLineInput,
                            SubType: 'text'
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.font.label'),
                            Key: 'Textflow.Font',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Dropdown,
                            Options: settings.Fonts.map(font => { return { Label: font, Value: font }; }),
                            Default: defaultFont
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.color.label'),
                            Key: 'Textflow.Color',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Color,
                            Default: '#000'
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.size.label'),
                            Key: 'Textflow.FontSize',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: 0,
                            Max: 100
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.label'),
                            Key: 'Textflow',
                            AllowMultiSelection: true,
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.bold'),
                                    Icon: './img/bold-solid.svg',
                                    Key: 'Bold'
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.italic'),
                                    Icon: './img/italic-solid.svg',
                                    Key: 'Italic'
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.underline'),
                                    Icon: './img/underline-solid.svg',
                                    Key: 'Underline'
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.dynamicFontSize.label'),
                            Key: 'Textflow.IsFontsizeDynamic',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Boolean
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.maxCharactersCount.label'),
                            Key: 'Textflow.MaxCharacters',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: 0
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.maxLines.label'),
                            Key: 'Textflow.MaxLines',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: 0
                        }
                    ]
                },
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.label'),
                            Key: 'Layout.HorizontalAlignment',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.left'),
                                    Icon: './img/align-left-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Left
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.center'),
                                    Icon: './img/align-center-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.right'),
                                    Icon: './img/align-right-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Right
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.justify'),
                                    Icon: './img/align-justify-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Justify
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.label'),
                            Key: 'Layout.VerticalAlignment',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.top'),
                                    Icon: './img/vertical-align-top.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Top
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.center'),
                                    Icon: './img/vertical-align-center.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.bottom'),
                                    Icon: './img/vertical-align-bottom.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Bottom
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.rotation.label'),
                            Key: 'Layout.Rotation',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: -360,
                            Max: 360,
                            Default: 0
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.bottomBorder.label'),
                            Key: 'Underline',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Boolean
                        }
                    ]
                }
            ];

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Text,
                title,
                description,
                'font-solid.svg',
                settings
            );
        }

        TextComponent.prototype = Object.create(global.Model.BaseComponent.prototype);

        TextComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();
            const additionalSettings = {
                Textflow: {
                    Text: this.Title,
                    FontSize: .95
                },
                Layout: {
                    HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                    VerticalAlignment: global.Model.Enums.VerticalAlignment.Top
                }
            };

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY,
                additionalSettings
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        /**
         * @return {string}
         */
        TextComponent.prototype.CreateComponentContent = function () {
            const markup = ['<span'];
            const styles = [];

            if (!!this.Textflow && !!this.Textflow.Color) {
                styles.push(`color:"${this.Textflow.Color}"`);
            }

            if (typeof this.Layout.Rotation === 'number' && !isNaN(this.Layout.Rotation)) {
                styles.push(`transform:rotate(${this.Layout.Rotation}deg)`);
            }

            if (styles.length) {
                markup.push(` style="${styles.join(';')}"`);
            }

            markup.push(`>${DOMPurify.sanitize(this.Textflow.Text).replace(/\r?\n|\r/g, '<br />')}</span>`);

            return markup.join('');
        };

        TextComponent.prototype.constructor = TextComponent;

        return TextComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef CataloguePictureComponent
     */
    global.Model.CataloguePictureComponent = (function () {
        /**
         * @param {string|null} title
         * @param {string|null} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function CataloguePictureComponent(title, description, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.files.image'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.files.fileSelection.label'),
                            Key: 'FileOID',
                            Type: global.Model.Enums.ComponentSettingRepresentation.FileSelection,
                            SubType: 'image'
                        }
                    ]
                },
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.label'),
                            Key: 'Layout.HorizontalAlignment',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.left'),
                                    Icon: './img/align-left-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Left
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.center'),
                                    Icon: './img/align-center-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.right'),
                                    Icon: './img/align-right-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Right
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.label'),
                            Key: 'Layout.VerticalAlignment',
                            Type: global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.top'),
                                    Icon: './img/vertical-align-top.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Top
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.center'),
                                    Icon: './img/vertical-align-center.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.bottom'),
                                    Icon: './img/vertical-align-bottom.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Bottom
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.rotation.label'),
                            Key: 'Layout.Rotation',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: -360,
                            Max: 360,
                            Default: 0
                        }
                    ]
                }
            ];

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Image,
                title,
                description,
                'image-regular.svg',
                settings
            );

            this.DefaultDimensions = {
                Width: 30,
                Height: 22
            };
        }

        CataloguePictureComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        CataloguePictureComponent.prototype.constructor = CataloguePictureComponent;

        CataloguePictureComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();
            const additionalSettings = {
                Layout: {
                    HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Center,
                    VerticalAlignment: global.Model.Enums.VerticalAlignment.Center
                }
            };

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY,
                additionalSettings
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        CataloguePictureComponent.prototype.CreateComponentContent = function () {
            const file = !!this.FileOID ? changemode.Files[this.FileOID] : null;
            const filePath = file ? `${Config.BaseUri}images/s/${file.Filename}` : './img/image-regular.svg';

            const styles = [];

            if (typeof this.Layout.Rotation === 'number' && !isNaN(this.Layout.Rotation)) {
                styles.push(`transform:rotate(${this.Layout.Rotation}deg)`);
            }

            return `<img src="${filePath}" style="max-height:100%;max-width:100%;${styles.length ? styles.join(';') : ''}" />`;
        };

        return CataloguePictureComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef TableComponent
     */
    global.Model.TableComponent = (function () {
        /**
         * @typedef TableElementBorder
         * @property {string} Color Rahmenfarbe (Hex)
         * @property {number} Width Rahmenbreite
         * @property {bool} Dashed true: zeichnet den Rahmen als gestrichelte Linie, ansonsten durchgehend
         */

        /**
         * @typedef TableCell
         * @property {number} Rowspan
         * @property {number} Row
         * @property {number} Column
         * @property {number} ColumnSpan
         * @property {number?} Width
         * @property {number?} Height
         * @property {string?} BackgroundColor
         * @property {BaseComponent[]} Content
         * @property {boolean} IsTemplate True, wenn es sich um eine Teilprobe handelt
         * @function CreateCellMarkup Gibt das HTML für eine Zelle zurück
         */

        /**
         * @typedef TableCellProvider
         * @property {number} Type Absolut oder Teilprobe
         * @property {TableCell[][]?} Cells Muss gefüllt werden, wenn statische Daten hinterlegt werden sollen
         */


        /**
         * @typedef AbsoluteCellProvider
         * @property {number} Type Absolut
         * @property {TableCell[][]} Cells Muss gefüllt werden, wenn statische Daten hinterlegt werden sollen
         */

        /**
         * @typedef SubsampleParameterCellProvider
         * @property {number} Type Teilprobe
         * @property {TableCell[][]?} Cells Muss gefüllt werden, wenn statische Daten hinterlegt werden sollen
         * @property {string?} ParameterGroupOID Wird benötigt, wenn es sich um eine Teilprobengruppe handelt
         * @property {TableCell[]} TemplateCells Wird benötigt, wenn es sich um eine Teilprobengruppe handelt
         */


        /**
         * @param {string} title
         * @param {string} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function TableComponent(title, description, settings) {
            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Table,
                title,
                description,
                'table-solid.svg',
                settings
            );

            this.DefaultDimensions = {
                Width: 40,
                Height: 7.5
            };

            this.Layout.HorizontalAlignment = global.Model.Enums.HorizontalAlignment.Center;
            this.Layout.VerticalAlignment = global.Model.Enums.HorizontalAlignment.Top;

            /**
             * @type {Object.<number, TableElementBorder>}
             */
            this.RowBorders = settings.RowBorders || {};

            /**
             * @type {Object.<number, TableElementBorder>}
             */
            this.ColumnBorders = settings.ColumnBorders || {};

            /**
             * @type {AbsoluteCellProvider}
             */
            this.HeaderCellProvider = settings.HeaderCellProvider || {
                Type: global.Model.Enums.TableCellProviderType.Absolute,
                Cells: []
            };

            /**
             * @type {TableCellProvider | AbsoluteCellProvider | SubsampleParameterCellProvider}
             */
            this.CellProvider = settings.CellProvider || {
                Type: global.Model.Enums.TableCellProviderType.Absolute,
                Cells: []
            };

            /**
             * @type {Object.<string, BaseComponent>}
             */
            this.TableComponentsMap = {};
            /**
             * @type {Object.<string, TableCell>}
             */
            this.TableCellsMap = {};
            this.ColumnSizes = [];

            this.ZIndex = 2;
        }

        TableComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        TableComponent.prototype.constructor = TableComponent;

        TableComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY
            ).then((component) => {
                component.IsSubsampleTable = !!this.IsSubsampleTable;
                $deferred.resolve(component);
            });

            return $deferred.promise();
        };

        TableComponent.prototype.SetNewSubsampleTableContent = function(groupOID, subsampleCheckpointComponent) {
            this.IsSubsampleTable = true;
            this.GroupOID = groupOID;
            this.CellProvider.Type = global.Model.Enums.TableCellProviderType.Subsample;
            this.CellProvider.ParameterGroupOID = groupOID;
            delete this.CellProvider.Cells;
            this.CellProvider.TemplateCells = [];

            this.CellProvider.TemplateCells.push(
                this.CreateTableCell({
                    Row: 0,
                    Column: 0,
                    IsTemplate: true,
                    Content: [subsampleCheckpointComponent]
                })
            );

            for (let i = 0; i < this.HeaderCellProvider.Cells.length; i++) {
                const row = this.HeaderCellProvider.Cells[i];

                // Bei 1 anfangen, da die neue Komponente schon drin ist
                for (let j = 1; j < row.length; j++) {
                    this.CellProvider.TemplateCells.push(
                        this.CreateTableCell({
                            Row: 0,
                            Column: j,
                            IsTemplate: true,
                            Content: []
                        }));
                }
            }
        };

        TableComponent.prototype.CreateComponentContent = function () {
            const markup = [
                '<table>',
                '<thead>',
            ];

            if (this.HeaderCellProvider.Cells.length === 0) {
                // leere 1x1 Tabelle mit Textzeilen erstellen
                const headerCell = this.CreateTableCell({Row: 0, Column: 0, IsTemplate: !!this.IsSubsampleTable, IsHeader: true});

                this.HeaderCellProvider.Cells.push(
                    [headerCell]
                );
            }

            for (let i = 0; i < this.HeaderCellProvider.Cells.length; i++) {
                const row = this.HeaderCellProvider.Cells[i];

                markup.push('<tr>');
                for (let j = 0; j < row.length; j++) {
                    const cell = row[j];
                    const tableCell = this.CreateTableCell({
                        Row: i,
                        Rowspan: cell.Rowspan,
                        Column: j,
                        ColumnSpan: cell.ColumnSpan,
                        IsTemplate: this.IsSubsampleTable || cell.IsTemplate,
                        IsHeader: true,
                        BackgroundColor: cell.BackgroundColor,
                        Width: cell.Width,
                        Height: cell.Height,
                        Content: cell.Content
                    });

                    markup.push(tableCell.CreateCellMarkup(this.Page));

                    this.SetTableCell(tableCell, true);
                }

                markup.push('</tr>');
            }

            markup.push('</thead>');
            markup.push('<tbody>');

            if (this.CellProvider.Type === global.Model.Enums.TableCellProviderType.Subsample) {
                markup.push('<tr>');

                for (let i = 0; i < this.CellProvider.TemplateCells.length; i++) {
                    const cell = this.CellProvider.TemplateCells[i];
                    const tableCell = this.CreateTableCell({
                        Row: 0,
                        Column: i,
                        ColumnSpan: cell.ColumnSpan,
                        IsTemplate: true,
                        IsHeader: false,
                        BackgroundColor: cell.BackgroundColor,
                        Width: cell.Width,
                        Height: cell.Height,
                        Content: cell.Content
                    });

                    markup.push(tableCell.CreateCellMarkup(this.Page));

                    this.SetTableCell(tableCell, false);
                }

                markup.push('</tr>');
            } else {
                if (this.CellProvider.Cells.length === 0) {
                    const contentCell = this.CreateTableCell({Row: 0, Column: 0, IsTemplate: false, IsHeader: false});

                    this.CellProvider.Cells.push(
                        [contentCell]
                    );
                }

                for (let i = 0; i < this.CellProvider.Cells.length; i++) {
                    const row = this.CellProvider.Cells[i];

                    markup.push('<tr>');

                    for (let j = 0; j < row.length; j++) {
                        const cell = row[j];
                        const tableCell = this.CreateTableCell({
                            Row: i,
                            Rowspan: cell.Rowspan,
                            Column: j,
                            ColumnSpan: cell.ColumnSpan,
                            IsTemplate: cell.IsTemplate,
                            IsHeader: false,
                            BackgroundColor: cell.BackgroundColor,
                            Width: cell.Width,
                            Height: cell.Height,
                            Content: cell.Content
                        });

                        markup.push(tableCell.CreateCellMarkup(this.Page));

                        this.SetTableCell(tableCell, false);
                    }

                    markup.push('</tr>');
                }
            }

            markup.push(
                    '</tbody>',
                '</table>'
            );

            return markup.join('');
        };

        /**
         * @returns {TableCell}
         * @constructor
         */
        TableComponent.prototype.CreateTableCell = function (cellSettings) {
            const cell = new this.TableCell({
                Row: cellSettings.Row,
                Rowspan: cellSettings.Rowspan,
                Column: cellSettings.Column,
                ColumnSpan: cellSettings.ColumnSpan,
                IsTemplate: cellSettings.IsTemplate,
                IsHeader: cellSettings.IsHeader,
                BackgroundColor: cellSettings.BackgroundColor,
                Width: cellSettings.Width,
                Height: cellSettings.Height,
                Content: cellSettings.Content
            });

            if (cell.IsTemplate) {
                this.IsSubsampleTable = true;
            }

            if ((cellSettings.Content || []).length === 0) {
                const textComponent = new global.Model.TextComponent('Platzhalter', null, { Fonts: [], OID: uuid() });

                textComponent.Textflow = { Text: 'Platzhalter', FontSize: 0.95 };
                textComponent.Layout = {
                    HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                    VerticalAlignment: global.Model.Enums.VerticalAlignment.Center,
                    Rotation: 0,
                    Fitmethod: global.Model.Enums.FitMethod.Auto
                };
                textComponent.Position = { Type: global.Model.Enums.PositionType.Relative, Coordinates: { X: 0, Y: 0 } };
                textComponent.SetPage(this.Page);
                textComponent.RenderArea = global.Model.Enums.SectionModificationArea.Page;

                const relativeCoordinates = {
                    left: 0,
                    top: 0
                };

                const relativeDimensions = {
                    height: 2.5,
                    width: 4
                };

                textComponent
                    .SetCoordinates(relativeCoordinates.left, relativeCoordinates.top)
                    .SetDimensions(relativeDimensions.height, relativeDimensions.width);

                textComponent.CellOID = cell.OID;
                textComponent.IsTableCell = true;
                cell.Content = [textComponent];
                cell.Width = textComponent.Width;
                cell.Height = textComponent.Height;

                this.TableComponentsMap[textComponent.OID] = textComponent;
            } else {
                cell.Content = cellSettings.Content || [];

                for (let i = 0; i < cell.Content.length; i++) {
                    const component = cell.Content[i];

                    component.Position = { Type: global.Model.Enums.PositionType.Relative, Coordinates: { X: 0, Y: 0 } };
                    component.SetPage(this.Page);
                    component.RenderArea = global.Model.Enums.SectionModificationArea.Page;
                    component.CellOID = cell.OID;
                    component.IsTableCell = true;

                    const relativeDimensions = {
                        height: cell.Height || component.Height || 0,
                        width: cell.Width || component.Width || 0
                    };

                    component
                        .SetDimensions(relativeDimensions.height, relativeDimensions.width);

                    this.TableComponentsMap[component.OID] = component;
                }

                cell.Height = cell.Content.reduce((acc, value) => acc + value.Height, 0);
            }

            return cell;
        };

        /**
         * @param {TableCell} tableCell
         * @param {boolean} isHeader
         */
        TableComponent.prototype.SetTableCell = function(tableCell, isHeader) {
            const row = tableCell.Row;
            const column = tableCell.Column;

            this.TableCellsMap[tableCell.OID] = tableCell;

            if (isHeader) {
                this.HeaderCellProvider.Cells[row || 0][column] = tableCell;

                if (tableCell.IsTemplate) {
                    for (let j = 0; j < (this.CellProvider.TemplateCells || []).length; j++) {
                        const cell = this.CellProvider.TemplateCells[column];

                        cell.Width = tableCell.Width;

                        if ((cell.Content || []).length === 0) {
                            continue;
                        }

                        for (const content of cell.Content) {
                            content.Width = cell.Width;
                        }
                    }

                    return;
                }

                for (let j = 0; j < this.CellProvider.Cells.length; j++) {
                    const cell = this.CellProvider.Cells[j][column];

                    cell.Width = tableCell.Width;

                    if ((cell.Content || []).length === 0) {
                        continue;
                    }

                    for (const content of cell.Content) {
                        content.Width = cell.Width;
                    }
                }

                return;
            }

            if (tableCell.IsTemplate) {
                this.CellProvider.TemplateCells[column] = tableCell;
            } else {
                this.CellProvider.Cells[row][column] = tableCell;
            }
        };

        /**
         * @param {TableCell} tableCell
         * @param {boolean} isHeader
         */
        TableComponent.prototype.GetTableCell = function(tableCell, isHeader) {
            const row = tableCell.Row;
            const column = tableCell.Column;

            if (isHeader) {
                return this.HeaderCellProvider.Cells[row || 0][column];
            }

            return tableCell.IsTemplate ? this.CellProvider.TemplateCells[column] : this.CellProvider.Cells[row][column];
        };

        TableComponent.prototype.RenderOnPage = function () {
            this.TableComponentsMap = {};
            this.TableCellsMap = {};
            this.ColumnSizes = [];

            global.Model.BaseComponent.prototype.RenderOnPage.call(this, this.Page, global.Model.Enums.SectionModificationArea.Page);

            if (!!this.GroupOID) {
                this.$node.attr('data-group-oid', this.GroupOID);
            }

            const $table = this.$node.find('table');
            const $tableHeaders = $table.find('th');
            const tableMargin = parseInt($table.css('margin-top'), 10);
            let colResizerLeftPosition = 0;

            $tableHeaders.each((index, element) => {
                if (index === $tableHeaders.length - 1) {
                    return;
                }

                const $el = $(element);
                $el.append(`<div class="col-resizer" data-column="${index}"></div>`);
                colResizerLeftPosition += $el.outerWidth(true);

                const $colResizer = $el.find('.col-resizer');
                $colResizer.css({
                    top: tableMargin,
                    left: colResizerLeftPosition,
                    height: this.$node.find('table').height()
                });
            });

            // Events für die Komponenten binden
            var components = Object.values(this.TableComponentsMap);
            const $page = $(this.Page.PageSelector);

            for (let i = 0; i < components.length; i++) {
                const component = components[i];
                const $node = $page.find(`.table-cell-component[data-identifier="${component.OID}"]`);

                component.SetNode($node);

                $node.off('click.selectNode');
                $node.on('click.selectNode', $.proxy(component.OnComponentClick, component));
            }

            for (const cell of this.HeaderCellProvider.Cells[0]) {
                this.ColumnSizes.push(cell.Width || 0);
            }
        };

        TableComponent.prototype.GetSelectedComponent = function(identifier) {
            return this.TableComponentsMap[identifier];
        }

        TableComponent.prototype.RemoveHelperInformation = function () {
            delete this.TableComponentsMap;
            delete this.TableCellsMap;
            delete this.ColumnSizes;

            global.Model.BaseComponent.prototype.RemoveHelperInformation.call(this);

            for (let i = 0; i < this.HeaderCellProvider.Cells.length; i++) {
                let row = this.HeaderCellProvider.Cells[i];

                for (let j = 0; j < row.length; j++) {
                    let cell = row[j];

                    for (let k = 0; k < cell.Content.length; k++) {
                        global.Model.BaseComponent.prototype.RemoveHelperInformation.call(cell.Content[k]);
                    }
                }
            }

            for (let i = 0; i < (this.CellProvider.Cells || []).length; i++) {
                let row = this.CellProvider.Cells[i];

                for (let j = 0; j < row.length; j++) {
                    let cell = row[j];

                    for (let k = 0; k < cell.Content.length; k++) {
                        global.Model.BaseComponent.prototype.RemoveHelperInformation.call(cell.Content[k]);
                    }
                }
            }

            for (let i = 0; i < (this.CellProvider.TemplateCells || []).length; i++) {
                delete this.CellProvider.TemplateCells[i].Row;

                for (let k = 0; k < this.CellProvider.TemplateCells[i].Content.length; k++) {
                    global.Model.BaseComponent.prototype.RemoveHelperInformation.call(this.CellProvider.TemplateCells[i].Content[k]);
                }
            }
        };

        TableComponent.prototype.OnAfterTableResized = function (page) {
            this.ColumnSizes = [];
            const components = Object.values(this.TableComponentsMap);

            for (var i = 0; i < components.length; i++) {
                const component = components[i];
                const $node = component.$node;

                if ($node && $node.length > 0) {
                    const $cell = $node.parent();

                    const cellWidth = $cell.width();
                    const cellHeight = $cell.height();

                    $node.width(cellWidth);

                    let cell = this.TableCellsMap[$cell.data('cell')];
                    cell = this.GetTableCell(cell, cell.IsHeader);

                    const dimensions = {
                        height: (cellHeight / page.Height) * 100,
                        width: (cellWidth / page.Width) * 100
                    };

                    if (cell) {
                        cell.Width = dimensions.width;
                        cell.Height = dimensions.height;
                    }

                    component.SetDimensions(dimensions.height, dimensions.width);
                    this.SetTableCell(cell, cell.IsHeader);
                }
            }

            for (const cell of this.HeaderCellProvider.Cells[0]) {
                this.ColumnSizes.push(cell.Width || 0);
            }

            const $table = this.$node.find('table');
            const $tableHeaders = $table.find('th');
            const tableMargin = parseInt($table.css('margin-top'), 10);
            let colResizerLeftPosition = 0;

            $tableHeaders.each((index, element) => {
                if (index === $tableHeaders.length - 1) {
                    return;
                }

                const $el = $(element);
                colResizerLeftPosition += $el.outerWidth(true);

                const $colResizer = $el.find('.col-resizer');
                $colResizer.css({
                    top: tableMargin,
                    left: colResizerLeftPosition,
                    height: this.$node.find('table').height()
                });
            });
        };

        /**
         * @returns {TableCell}
         * @class
         */
        TableComponent.prototype.TableCell = (function() {
            /**
             * @constructor
             */
            function TableCell(tableCellSettings) {
                this.RowSpan = tableCellSettings.RowSpan || 1;

                this.Column = tableCellSettings.Column;
                this.ColumnSpan = tableCellSettings.ColumnSpan || 1;

                this.Width = tableCellSettings.Width || 2;
                this.Height = tableCellSettings.Height || 2;

                this.BackgroundColor = tableCellSettings.BackgroundColor || tableCellSettings.IsHeader ? '#ccc' : undefined;

                this.Content = tableCellSettings.Content || [];

                this.IsTemplate = !!tableCellSettings.IsTemplate;

                if (!this.IsTemplate) {
                    this.Row = tableCellSettings.Row;
                }

                this.IsHeader = tableCellSettings.IsHeader;
                this.OID = uuid();
            }

            TableCell.prototype.constructor = TableCell;

            /** @function */
            TableCell.prototype.CreateCellMarkup = function(page) {
                const markup = [];

                const dimensions = {
                    height: (this.Height / 100) * page.Height,
                    width: (this.Width / 100) * page.Width
                };

                markup.push(this.IsHeader ? '<th' : '<td');
                markup.push(this.IsHeader ? ` style="width: ${dimensions.width}px"` : '');
                markup.push(` data-cell="${this.OID}"`);
                markup.push(` data-column="${this.Column || 0}"`);
                markup.push(` data-row="${this.Row || 0}"`);
                markup.push(`>`);

                for (let j = 0; j < this.Content.length; j++) {
                    const content = this.Content[j];
                    markup.push(
                        content.RenderOnPage.call(content, content.Page, content.RenderArea, {
                            Width: this.Width || content.Width,
                            Height: this.Height || content.Height
                        })
                    );
                }

                markup.push(this.IsHeader ? '</th>' : '</td>');

                return markup.join('');
            }

            return TableCell;
        })();

        return TableComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef RectangleComponent
     */
    global.Model.RectangleComponent = (function () {
        /**
         * @param {string|null} title
         * @param {string|null} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function RectangleComponent(title, description, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.borderColor.label'),
                            Key: 'StrokeColor',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Color,
                            Default: '#000'
                        },
                    ]
                }
            ];

            this.ZIndex = 2;

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Geometry,
                title,
                description,
                'rectangle-empty.svg',
                settings
            );

            /**
             * @type {Point[]}
             */
            this.Points = [];

            this.Layout = {
                HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                VerticalAlignment: global.Model.Enums.VerticalAlignment.Top,
                Rotation: 0,
                Fitmethod: global.Model.Enums.FitMethod.Auto
            };

            /**
             * @type {string}
             */
            this.StrokeColor = '#000000';

            this.ZIndex = 1;
        }

        RectangleComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        RectangleComponent.prototype.constructor = RectangleComponent;

        RectangleComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        /**
         * @param {number} height
         * @param {number} width
         * @return {this}
         */
        RectangleComponent.prototype.SetDimensions = function (height, width) {
            global.Model.BaseComponent.prototype.SetDimensions.call(
                this,
                height,
                width
            );

            this.Points = [
                { X: 0, Y: 0 },
                { X: width, Y: 0 },
                { X: width, Y: height },
                { X: 0, Y: height }
            ];

            return this;
        };

        RectangleComponent.prototype.CreateComponentContent = function () {
            return i18next.t('changeMode.pdfDesigner.components.static.rectangle.label');
        };

        return RectangleComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef LineComponent
     */
    global.Model.LineComponent = (function () {
        /**
         * @param {string|null} title
         * @param {string|null} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function LineComponent(title, description, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.borderColor.label'),
                            Key: 'StrokeColor',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Color,
                            Default: '#000'
                        },
                    ]
                }
            ];

            this.DisableHeightSetting = true;

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Geometry,
                title,
                description,
                'line.svg',
                settings
            );

            /**
             * @type {Point[]}
             */
            this.Points = [];

            this.Layout = {
                HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                VerticalAlignment: global.Model.Enums.VerticalAlignment.Top,
                Rotation: 0,
                Fitmethod: global.Model.Enums.FitMethod.Auto
            };

            this.DefaultDimensions.Height = 0;

            /**
             * @type {string}
             */
            this.StrokeColor = '#000000';

            this.ZIndex = 2;
        }

        LineComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        LineComponent.prototype.constructor = LineComponent;

        LineComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        /**
         * @param {number} height Hat auf diese Komponente keine Auswirkungen!
         * @param {number} width
         * @return {this}
         */
        LineComponent.prototype.SetDimensions = function (height, width) {
            global.Model.BaseComponent.prototype.SetDimensions.call(
                this,
                0,
                width
            );

            this.Points = [
                { X: 0, Y: 0 },
                { X: width, Y: 0 }
            ];

            return this;
        };

        LineComponent.prototype.CreateComponentContent = function () {
            return '';
        };

        return LineComponent;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));