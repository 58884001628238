/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @typedef MarginalSettings
     */
    global.Model.MarginalSettings = (function () {
        /**
         * @param {string} baseSelector
         * @param {string} settingsContainerSelector
         * @param {Function} onPageClick
         * @param {Function} onComponentClick
         * @param {Function} onComponentEdit
         * @param {Function} onComponentRemove
         * @constructor
         */
        function MarginalSettings(baseSelector, settingsContainerSelector, onPageClick, onComponentClick, onComponentEdit, onComponentRemove) {
            /**
             * @type {string}
             * @readonly
             */
            this.BaseSelector = baseSelector + ' ';

            /**
             * @type {string}
             * @readonly
             */
            this.SettingsContainerSelector = this.BaseSelector + settingsContainerSelector;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnPageClick = onPageClick;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnComponentClick = onComponentClick;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnComponentEdit = onComponentEdit;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnComponentRemove = onComponentRemove;
        }

        return MarginalSettings;
    })();

    /**
     * @typedef Marginal
     */
    global.Model.Marginal = (function () {
        /**
         * @param {MarginalSettings} settings
         * @param {number} height
         * @param {string} renderArea
         * @param {object[]|null} content
         * @param {ComponentFactory|null} componentFactory
         * @constructor
         */
        function Marginal(settings, height, renderArea, content, componentFactory) {
            this.Settings = settings;
            this.Content = [];
            this.ComponentMap = {};
            this.Height = height;

            /**
             * @type {string}
             * @readonly
             */
            this.PageSelector = `${this.Settings.BaseSelector} .page`;

            /**
             * @type {boolean}
             * @readonly
             */
            this.IsHeader = renderArea === global.Model.Enums.SectionModificationArea.Header;

            /**
             * @type {boolean}
             * @readonly
             */
            this.IsFooter = renderArea === global.Model.Enums.SectionModificationArea.Footer;

            if (!(content || []).length) {
                return;
            }

            for (let cCnt = 0; cCnt < (content || []).length; cCnt++){
                const rawComponent = content[cCnt];
                let component = componentFactory.CreateComponentFromDefinition(rawComponent, renderArea);

                if (component == null) {
                    continue;
                }

                this.Content.push(component);
                this.ComponentMap[component.OID] = component;

                component.SetMarginal(this);
            }
        }

        /**
         * Fügt dem Randbereich eine Komponente hinzu
         * @param {BaseComponent} component
         * @return this
         */
        Marginal.prototype.AddComponent = function (component) {
            if (!component || component.IsTemplate) {
                return;
            }

            component = global.Tools.AdjustMarginalComponentDimensions(
                this,
                component,
                this.Height,
                this.IsHeader,
                this.IsFooter
            );

            this.Content.push(component);
            this.ComponentMap[component.OID] = component;

            component.SetMarginal(this);

            return this;
        };

        /**
         * Gibt eine Komponente anhand ihres Identifiers zurück
         * @param {string} identifier
         * @return {BaseComponent|null}
         */
        Marginal.prototype.GetComponent = function (identifier) {
            if (!identifier) {
                return null;
            }

            return this.ComponentMap[identifier];
        };

        /**
         * Entfernt eine Komponente anhand des Identifiers
         * @param {string} identifier
         * @return this
         */
        Marginal.prototype.RemoveComponent = function (identifier) {
            if (!identifier) {
                return null;
            }

            const component = this.GetComponent(identifier);

            if (!component) {
                return this;
            }

            component.Remove();

            const idx = Tools.indexOf(this.Content, identifier, 'OID');

            if (idx === -1) {
                return this;
            }

            this.Content.splice(idx, 1);
            delete this.ComponentMap[identifier];

            return this;
        };

        /**
         * Setzt die relative Höhe es Randbereichs
         * @param {number} relativeHeight
         * @return {Marginal}
         */
        Marginal.prototype.SetHeight = function (relativeHeight) {
            if (typeof relativeHeight !== 'number' || isNaN(relativeHeight) || relativeHeight < 0) {
                return this;
            }

            this.Height = relativeHeight;

            return this;
        };

        /**
         * Gibt die relative Höhe des Randbereichs zurück
         * @return {Number}
         */
        Marginal.prototype.GetHeight = function () {
            return this.Height;
        };

        /**
         * Versucht Komponenten, die sich außerhalb des Randbereichs befinden, neu zu positionieren.
         * @return {{FailedToPosition: number, OutOfPosition: number}}
         */
        Marginal.prototype.TryRealignComponents = function () {
            const componentsOutOfPosition = this.Content.filter(component => this.CheckComponentYPosition(component));
            const result = {
                OutOfPosition: componentsOutOfPosition.length,
                FailedToPosition: 0
            };

            if (componentsOutOfPosition.length === 0) {
                return result;
            }

            for (const component of componentsOutOfPosition) {
                const newYPosition = this.IsHeader ? this.Height - component.Height : 100 - this.Height;

                if (this.IsHeader && newYPosition >= 0 && newYPosition + component.Height <= this.Height) {
                    component.SetCoordinates(component.Position.Coordinates.X, newYPosition);
                } else if (this.IsFooter && newYPosition >= 100 - this.Height && newYPosition + component.Height <= 100) {
                    component.SetCoordinates(component.Position.Coordinates.X, newYPosition);
                } else {
                    result.FailedToPosition++;
                }
            }

            return result;
        };

        /**
         * @param {BaseComponent} component
         * @return {boolean}
         */
        Marginal.prototype.CheckComponentXPosition = function (component) {
            if (!component) {
                return false;
            }

            return component.Position.Coordinates.X < 0 || component.Position.Coordinates.X > 100 - component.Width;
        };

        /**
         * @param {BaseComponent} component
         * @return {boolean}
         */
        Marginal.prototype.CheckComponentYPosition = function (component) {
            if (!component) {
                return false;
            }

            if (this.IsHeader) {
                return component.Height + component.Position.Coordinates.Y > this.Height;
            }

            return component.Position.Coordinates.Y + component.Height > 100 ||
                component.Position.Coordinates.Y < 100 - this.Height;
        };

        /**
         * Entfernt alle Hilfsinformationen
         */
        Marginal.prototype.RemoveHelperInformation = function () {
            delete this.Settings;
            delete this.PageSelector;
            delete this.ComponentMap;
            delete this.IsHeader;
            delete this.IsFooter;

            this.Content.forEach(component => component.RemoveHelperInformation());
        };

        return Marginal;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));