/**
 * @require ./property.js
 */
(function (global) {
    var CheckboxProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.Checkbox;

        if (!(definition.OnChange instanceof Function)) {
            definition.OnChange = function () {
                changemode.CurrentPage.CurrentTab.MarkAsModified();
            };
        }

        global.Base.Properties.Base.call(this, definition);
    };

    CheckboxProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: CheckboxProperty
    });

    CheckboxProperty.prototype.RenderInput = function () {
        var html = [
            '<input type="checkbox" id="', this.ID + '-value" />'
        ];

        return html.join('');
    };

    CheckboxProperty.prototype.OnAfterRendered = function () {
        var value = CurrentEntity[this.MappedProperty];
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        $prop.prop('checked', !!value);

        this.BindEvents.call(this);
    };

    CheckboxProperty.prototype.BindEvents = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        $prop.on('input', this.OnChange);
    };

    CheckboxProperty.prototype.SetValue = function (value) {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        $prop.prop('checked', !!value);
    };

    CheckboxProperty.prototype.GetValue = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        return $prop.is(':checked');
    };

    CheckboxProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.CheckboxProperty = CheckboxProperty;
})(Modifications.Pages || (Modifications.Pages = {}));