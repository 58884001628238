(function (global) {
    var FileTableProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.FileTable;

        global.Base.Properties.Base.call(this, definition);
    };

    FileTableProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: FileTableProperty
    });

    FileTableProperty.prototype.RenderInput = function () {
        var unformattedValue = CurrentEntity[this.MappedProperty];
        var files = (unformattedValue || [])
            .map(function (identifier) { return changemode.Files[identifier]; })
            .filter(function (file) { return file != null; })
            .sort(Tools.SortByTitle);

        var html = [
            '<div class="gfx-table file-table-property">',
                '<div id="', this.ID ,'-value" class="table-container">',
                    '<div class="table-control">',
                        '<div class="flex-dummy"></div>',
                        '<div class="action-dropdown-button">',
                            '<span class="caption">+</span>',
                        '</div>',
                    '</div>',
                    Tools.Files.renderFilesTable(files),
                '</div>',
            '</div>'
        ];

        return html.join('');
    };


    FileTableProperty.prototype.AddFileToTable = function (fileIdentifier) {
        if (!fileIdentifier) {
            return;
        }

        var file = changemode.Files[fileIdentifier];

        if (!file) {
            return;
        }

        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        var newFileRow = Tools.Files.renderFilesTableBodyRow(file);

        if ($prop.find('.no-data-available').length) {
            $prop.find('.no-data-available').remove();
        }

        $prop.find('tbody').append(newFileRow);

        this.BindEvents();
    };

    FileTableProperty.prototype.OnAfterRendered = function () {
        this.BindEvents.call(this);

        var value = CurrentEntity[this.MappedProperty];

        this.SetValue(value);
    };

    FileTableProperty.prototype.UnbindEvents = function () {
        var $prop = $('#' + this.ID);

        if (!$prop.length) {
            return;
        }

        $prop.find('.action-dropdown-button').off('click');
        $prop.find('.title').off('click');
        $prop.find('.btn-remove').off('click');
    };

    FileTableProperty.prototype.BindEvents = function () {
        var $prop = $('#' + this.ID);

        if (!$prop.length) {
            return;
        }

        this.UnbindEvents();

        $prop.find('.action-dropdown-button').on('click', $.proxy(onActionDropdownButtonClick, this));
        $prop.find('.title').on('click', $.proxy(onFileClick, this));
        $prop.find('.btn-remove').on('click', $.proxy(onBtnRemoveFileClick, this));
    };

    FileTableProperty.prototype.SetValue = function (value) {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        if (value instanceof Array) {
            value = value.join('|');
        } else {
            value = null;
        }

        $prop
            .data('value', value)
            .attr('data-value', value);
    };

    FileTableProperty.prototype.GetValue = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        var value = $prop.data('value') || '';

        if (!value) {
            return null;
        }

        var identifiers = value
            .split('|')
            .filter(Tools.IsValidGuid);

        return identifiers.length ? identifiers : null;
    };

    FileTableProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    function onActionDropdownButtonClick(evt) {
        evt.stopPropagation();

        var $this = $(evt.currentTarget);

        $this.toggleClass('active');

        if ($this.hasClass('active')) {
            if (this.GetActionDropdownItems instanceof Function) {
                var items = this.GetActionDropdownItems();

                Tools.createActionDropdown(items, $this);

                var prop = this;

                if (items instanceof Array) {
                    items.forEach(function (item) {
                        if (item.FileInput && item.FileInput.InitUpload instanceof Function) {
                            var $item = $this.find('[data-id="' + item.ID + '"]');

                            item.FileInput.InitUpload.call(prop, $item);
                        }
                    });
                }
            }

            $('body').on('click.closeRoomFilesActionWindow', function () {
                $this.removeClass('active');
                $this.find('.action-dropdown').removeClass('is-visible').css('max-height', 0);
            });
        } else {
            $this.find('.action-dropdown').removeClass('is-visible').css('max-height', 0);
        }
    }

    function onFileClick(evt) {
        var $this = $(evt.currentTarget);
        var $row = $this.closest('tr');
        var oid = $row.data('oid');
        var file = changemode.Files[oid];

        if (!file) {
            return;
        }

        FileWindow.Show(file, $.proxy(updateFileInformation, this));
    }

    function updateFileInformation(file) {
        if (!file) {
            return;
        }

        var $prop = $('#' + this.ID + '-value');

        var $fileRow = $prop.find('[data-oid="' + file.OID + '"]');

        if (!$fileRow.length) {
            return;
        }

        $fileRow.find('.title').text(file.Title);
        $fileRow.find('td:nth-child(4)').text(file.IsAvailableOffline ? i18next.t('misc.no') : i18next.t('misc.yes'));
    }

    function onBtnRemoveFileClick(evt) {
        var $this = $(evt.currentTarget);
        var $tbody = $this.closest('tbody');
        var $row = $this.closest('tr');
        var value = this.GetValue();

        Tools.remove(value, $row.data('oid'));

        this.SetValue(value);

        $row.remove();

        if ($tbody.find('tr').length === 0) {
            $tbody.html(Tools.Files.renderFilesTableBody())
        }

        if (this.OnRemoveClick instanceof Function) {
            this.OnRemoveClick();
        }
    }

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.TableProperty = FileTableProperty;
})(Modifications.Pages || (Modifications.Pages = {}));