/**
 * @require ./_base.js
 */
(function (global) {
    var ProcessConditionTimeConfiguration = function (options) {
        this.options = options || {};
        this.onApply = this.options.onApply || $.noop();

        if (!this.options.ID) {
            this.options.ID = 'process-condition-time-configuration';
        }

        this.options.Title = this.options.Title;
        this.options.ShowOverlay = true;

        if (!this.options.Buttons) {
            this.options.Buttons = {
                Close: {
                    Caption: i18next.t('misc.abort')
                },
                Yes: {
                    Caption: i18next.t('misc.apply'),
                    OnClick: $.proxy(onApplyClick, this)
                }
            };
        }

        this.options.Styles = {
            '_': {
                'z-index': 14001,
                width: 768
            },
            '.content': {
                overflow: 'auto',
                padding: '10px'
            }
        };

        initStyles.call(this);
    };

    function initStyles() {
        this.options.Styles = this.options.Styles || {};
        this.options.Styles['_'] = this.options.Styles['_'] || {};
        this.options.Styles['_']['z-index'] = this.options.Styles['_']['z-index'] || 1;

        if (this.options.ShowOverlay) {
            this.options.Styles['_']['z-index']++;
        }

        if (!this.options.Styles['_'].top) {
            this.options.Styles['_'].top = '50%';
        }

        if (!this.options.Styles['_'].left) {
            this.options.Styles['_'].left = '50%';
        }
    }

    function createContentMarkup() {
        var html = [];
        var timeConfiguration = this.options.TimeConfiguration || [];

        html.push(
            '<div class="content-message-bubble">',
                '<div class="content info">',
                    '<p class="text">',
                        i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.information'),
                    '</p>',
                '</div>',
            '</div>'
        );

        for (var i = 0; i < timeConfiguration.length; i++) {
            var time = timeConfiguration[i];
            var offsetAndType = getTimeOffsetAndType(time.Offset || 0);
            var markup = getRowMarkup(time, offsetAndType, this.options.OnlyOnStateChangeIsForced);

            html.push(markup);
        }

        html.push(addTimeConfigurationButtonMarkup());

        return html.join('');
    }

    function getRowMarkup(time, offsetAndType, onlyOnStateChangeIsForced) {
        var hasDayOfWeekFilter = time && time.AllowedDaysOfWeek && time.AllowedDaysOfWeek !== 0;
        return [
            '<div class="condition-time-configuration" data-id="{0}">'.format(time.OID),
                '<div class="column">',
                    '<div class="row">',
                        '<input class="time-offset" type="number" name="time-offset" value="{0}" min="0">'.format(offsetAndType.Offset),
                        '<select class="time-offset-type" name="time-offset-type">',
                            '<option value="1"{0}>{1}</option>'
                                .format(
                                    offsetAndType.Type === 1 ?
                                        ' selected' :
                                        '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.offsetUnit.minutes')
                                ),
                            '<option value="2"{0}>{1}</option>'
                                .format(
                                    offsetAndType.Type === 2 ?
                                        ' selected' :
                                        '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.offsetUnit.hours')
                                ),
                            '<option value="3"{0}>{1}</option>'
                                .format(
                                    offsetAndType.Type === 3 ?
                                        ' selected' :
                                        '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.offsetUnit.days')
                                ),
                        '</select>',
                        '<select name="time-offset-execute-type" class="time-offset-execute-type">',
                            '<option value="1"{0}>{1}</option>'
                                .format(
                                    time.Offset < 0 ? ' selected' : '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.executionOffset.before')
                                ),
                            '<option value="2"{0}>{1}</option>'
                                .format(
                                    time.Offset >= 0 ? ' selected' : '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.executionOffset.after')
                                ),
                        '</select>',
                        '<select name="time-offset-issue-time" class="time-offset-issue-time">',
                            '<option class="{0}" value="0"{1}>{2}</option>'
                                .format(
                                    time.Offset < 0 ? ' hide' : '',
                                    time.Type === 0 ? ' selected' : '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.issueProperty.creationTimestamp')
                                ),
                            '<option class="{0}" value="1"{1}>{2}</option>'
                                .format(
                                    time.Offset < 0 ? ' hide' : '',
                                    time.Type === 1 ? ' selected' : '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.issueProperty.modificationTimestamp')
                                ),
                            '<option value="2"{0}>{1}</option>'
                                .format(
                                    time.Type === 2 ? ' selected' : '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.issueProperty.deadlineTimestamp')
                                ),
                            '<option value="3"{0}>{1}</option>'
                                .format(
                                    time.Type === 3 ? ' selected' : '',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.issueProperty.remindingTimestamp')
                                ),
                        '</select>',
                    '</div>',
                    '<div class="row">',
                        '<div class="weekday-picker">',
                            '<div class="row weekday-picker-heading">',
                                i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.executeProcessOnFollowingWeekdays'),
                            '</div>',
                            '<label>',
                                '<input type="checkbox" class="day-of-week-option" value="', 2, '"', (hasDayOfWeekFilter && !content.views.formProcess.isWeekdayAllowed(time.AllowedDaysOfWeek, Enums.Weekdays.Monday) ? '' : ' checked'), '>',
                                i18next.t('weekdays.monday_abbreviation'),
                            '</label>',
                            '<label>',
                                '<input type="checkbox" class="day-of-week-option" value="', 4, '"', (hasDayOfWeekFilter && !content.views.formProcess.isWeekdayAllowed(time.AllowedDaysOfWeek, Enums.Weekdays.Tuesday) ? '' : ' checked'), '>',
                                i18next.t('weekdays.tuesday_abbreviation'),
                            '</label>',
                            '<label>',
                                '<input type="checkbox" class="day-of-week-option" value="', 8, '"', (hasDayOfWeekFilter && !content.views.formProcess.isWeekdayAllowed(time.AllowedDaysOfWeek, Enums.Weekdays.Wednesday) ? '' : ' checked'), '>',
                                i18next.t('weekdays.wednesday_abbreviation'),
                            '</label>',
                            '<label>',
                                '<input type="checkbox" class="day-of-week-option" value="', 16, '"', (hasDayOfWeekFilter && !content.views.formProcess.isWeekdayAllowed(time.AllowedDaysOfWeek, Enums.Weekdays.Thursday) ? '' : ' checked'), '>',
                                i18next.t('weekdays.thursday_abbreviation'),
                            '</label>',
                            '<label>',
                                '<input type="checkbox" class="day-of-week-option" value="', 32, '"', (hasDayOfWeekFilter && !content.views.formProcess.isWeekdayAllowed(time.AllowedDaysOfWeek, Enums.Weekdays.Friday) ? '' : ' checked'), '>',
                                i18next.t('weekdays.friday_abbreviation'),
                            '</label>',
                            '<label>',
                                '<input type="checkbox" class="day-of-week-option" value="', 64, '"', (hasDayOfWeekFilter && !content.views.formProcess.isWeekdayAllowed(time.AllowedDaysOfWeek, Enums.Weekdays.Saturday) ? '' : ' checked'), '>',
                                i18next.t('weekdays.saturday_abbreviation'),
                            '</label>',
                            '<label>',
                                '<input type="checkbox" class="day-of-week-option" value="', 1, '"', (hasDayOfWeekFilter && !content.views.formProcess.isWeekdayAllowed(time.AllowedDaysOfWeek, Enums.Weekdays.Sunday) ? '' : ' checked'), '>',
                                i18next.t('weekdays.sunday_abbreviation'),
                            '</label>',
                            '<div class="row invalid-message">',
                                i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.atLeastOneDayOfWeekRequired'),':',
                            '</div>',
                            '<div class="row postpone-process-option">',
                                '<label>',
                                    '<input type="checkbox" class="postpone-process-to-next-possible-weekday" name="postpone-process-to-next-possible-weekday"', (time.PostponeProcessToNextPossibleWeekday ? ' checked' : ''), '>',
                                    i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.postponeProcessToNextPossibleWeekday'),
                                '</label>',
                            '</div>',
                        '</div>',
                    '</div>',
                    '<div class="row">',
                        '<label>',
                            '<input type="checkbox" class="execute-only-after-status-change" name="execute-only-after-status-change"', (onlyOnStateChangeIsForced || time.ExecuteOnlyAfterStatusChange ? ' checked' : ''), '' +
                            (onlyOnStateChangeIsForced ? ' disabled="disabled"' : ''), '>',
                            i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.executeOnlyAfterStatusChange'),
                        '</label>',
                    '</div>',
                '</div>',
                '<div class="column">',
                    '<div class="delete-time-configuration" title="', i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.delete'), '">',
                        '<img class="delete" src="./img/bin.svg" />',
                    '</div>',
                '</div>',
            '</div>'
        ].join('');
    }

    function getTimeOffsetAndType(offset) {
        var type;
        if (offset && offset % 720 === 0) {
            offset = Math.abs(offset / 1440);
            type = 3;
        } else if (offset % 30 === 0) {
            offset = Math.abs(offset / 60);
            type = 2;
        } else {
            offset = Math.abs(offset);
            type = 1;
        }

        return { Type: type, Offset: offset };
    }

    function getOffsetInMinutes(offset, type) {
        switch (type) {
            case 1:
                return offset;
            case 2:
                return offset * 60;
            case 3:
                return offset * 1440;
        }
    }

    function addTimeConfigurationButtonMarkup() {
        return '<div class="btn add-time-configuration">+ {0}</div>'
            .format(i18next.t('changeMode.panels.processSettings.condition.timeConfigurationWindow.addSendingTimestamp'));
    }

    function createMarkup() {
        var html = [
            '<div id="', this.options.ID, '" class="popup">',
                    '<h1 class="top">',
                        '<span class="title">', this.options.Title, '</span>',
                        '<div class="close"></div>',
                    '</h1>',
                '<div class="content ', (this.options.AdditionalContentClasses || [].join('')), '">',
                    createContentMarkup.call(this),
                '</div>',
                '<div class="bottom">',
                    '<div class="dummy"></div>',
                    '<div class="buttons">'
        ];

        for (var attr in this.options.Buttons) {
            var button = this.options.Buttons[attr];
            var cssClass = 'btn-' + attr[0].toLowerCase() + attr.substr(1);

            html.push(
                '<div class="btn ', cssClass, '">',
                button.Caption,
                '</div>',
            );
        }

        html.push(
                    '</div>',
                '</div>',
            '</div>'
        );

        return html.join('');
    }

    function initWindow() {
        var $clientBody = $('body');
        var markup = createMarkup.call(this);

        if (this.options.ShowOverlay) {
            var overlayMarkup = createOverlayMarkup.call(this);

            $clientBody.append(overlayMarkup);

            this.$overlay = $('#' + this.options.ID + '-overlay');
        }

        $clientBody.append(markup);

        this.$window = $('#' + this.options.ID);
        this.$top = this.$window.find('.top');
        this.$content = this.$window.find('.content');
        this.$bottom = this.$window.find('.bottom');
    }

    function createOverlayMarkup() {
        return [
            '<div id="', this.options.ID, '-overlay"',
            'class="overflow">'
        ].join('');
    }

    function show() {
        if ($('#' + this.options.ID).length > 0) {
            return;
        }

        initWindow.call(this);
        applyStyles.call(this);
        bindEvents.call(this);
        resizeWindow.call(this);

        return this;
    }

    function bindEvents() {
        unbindEvents.call(this);

        this.$top.find('.close')
            .on('click', $.proxy(close, this));

        var me = this;

        if (this.options.Buttons) {
            if (this.options.Buttons.Close) {
                this.$bottom.find('.btn-close')
                    .on('click', function () {
                        if (me.options.Buttons.Close.OnClick instanceof Function) {
                            me.options.Buttons.Close.OnClick.call(me);
                        }

                        me.Close();
                    });
            }

            if (this.options.Buttons.No) {
                this.$bottom.find('.btn-no')
                    .on('click', function () {
                        if (me.options.Buttons.No.OnClick instanceof Function) {
                            me.options.Buttons.No.OnClick.call(me);
                        }

                        me.Close();
                    });
            }

            if (this.options.Buttons.Yes) {
                this.$bottom.find('.btn-yes')
                    .on('click', function () {
                        if (!checkForAndMarkInvalidCheckboxes.call(me)) {
                            return;
                        }

                        if (me.options.Buttons.Yes.OnClick instanceof Function) {
                            me.options.Buttons.Yes.OnClick.call(me);
                        }

                        me.Close();

                    });
            }
        }

        $(window)
            .on('resize.' + this.options.ID, $.proxy(resizeWindow, this));

        this.$content.find('.time-offset-execute-type').on('change', $.proxy(onTimeExecuteTypeChange, this));
        this.$content.find('.time-offset-issue-time').on('change', $.proxy(onOffsetIssueTimeChange, this));
        this.$content.find('.delete-time-configuration').on('click', $.proxy(onDeleteTimeConfigurationClick, this));
        this.$content.find('.add-time-configuration').on('click', $.proxy(onAddTimeConfigurationClick, this));
    }

    function getTimeConfiguration() {
        var configurations = this.$content.find('div.condition-time-configuration');

        if (configurations.length === 0) {
            return null;
        }

        var timeConfiguration = [];

        for (var i = 0; i < configurations.length; i++) {
            var $configuration = $(configurations[i]);
            var oid = $configuration.data('id');
            var offset = $configuration.find('.time-offset').val();
            var offsetValue = parseFloat(offset) || 0;
            var offsetType = parseInt($configuration.find('.time-offset-type').val(), 10);
            var executeType = parseInt($configuration.find('.time-offset-execute-type').val(), 10);
            var issueTime = parseInt($configuration.find('.time-offset-issue-time').val(), 10);
            var allowedDaysOfWeek = getAllowedDaysOfWeek($configuration.find('.weekday-picker .day-of-week-option'));
            var postponeProcessToNextPossibleWeekday = $configuration.find('.postpone-process-to-next-possible-weekday').prop('checked');
            var executeOnlyAfterStatusChange = $configuration.find('.execute-only-after-status-change').prop('checked');

            offsetValue = Math.abs(getOffsetInMinutes(offset, offsetType));

            if (executeType === 1) {
                offsetValue = -Math.abs(offsetValue);
            }

            if (oid === Tools.GetEmptyGuid()) {
                oid = uuid();
            }

            var newTimeConfiguration = {
                OID: oid,
                Offset: offsetValue,
                Type: issueTime,
                ExecuteOnlyAfterStatusChange: executeOnlyAfterStatusChange
            };

            // Option soll übersprungen werden, wenn alle Wochentage ausgewählt wurden
            if (allowedDaysOfWeek < 127) {
                newTimeConfiguration.AllowedDaysOfWeek = allowedDaysOfWeek;
                newTimeConfiguration.PostponeProcessToNextPossibleWeekday = postponeProcessToNextPossibleWeekday;
            }

            timeConfiguration.push(newTimeConfiguration);
        }

        return timeConfiguration;
    }

    function getAllowedDaysOfWeek($daysOfWeek) {
        if (!($daysOfWeek || []).length)
            return null;

        var allowedDaysOfWeek = 0;
        for (let i = 0; i < $daysOfWeek.length; i++) {
            var $dayOfWeek = $($daysOfWeek[i]);

            if ($dayOfWeek.prop('checked')) {
                allowedDaysOfWeek |= parseInt($dayOfWeek.prop('value'));
            }
        }

        return allowedDaysOfWeek;
    }

    function checkForAndMarkInvalidCheckboxes(){
        var configurations = this.$content.find('div.condition-time-configuration');

        if ((configurations || []).length === 0) {
            return true;
        }

        var checkboxesValid = true;
        for (var i = 0; i < configurations.length; i++) {
            var $configuration = $(configurations[i]);
            var invalidMessage = $configuration.find('.weekday-picker .invalid-message');
            var dayOfWeekOptions = $configuration.find('.weekday-picker .day-of-week-option');
            var allowedDaysOfWeek = getAllowedDaysOfWeek(dayOfWeekOptions);

            if (allowedDaysOfWeek === 0) {
                checkboxesValid = false;
                invalidMessage.show();
            } else {
                invalidMessage.hide();
            }

            toggleInvalidClasses(dayOfWeekOptions, allowedDaysOfWeek === 0);
        }

        return checkboxesValid;
    }

    function toggleInvalidClasses(checkboxes, invalid) {
        if (!(checkboxes || []).length) {
            return;
        }

        for (var j = 0; j < checkboxes.length; j++) {
            var checkbox = checkboxes[j].parentElement;

            $(checkbox).toggleClass('invalid', invalid)
        }
    }

    function onApplyClick() {
        var timeConfiguration = getTimeConfiguration.call(this);

        this.options.onApply(timeConfiguration);
    }

    function onAddTimeConfigurationClick(evt) {
        var $this = $(evt.currentTarget);
        var time = {Offset: 0, Type: 1, OID: uuid()};
        var offsetAndType = {Type: 1, Offset: 0};

        $this.before($(getRowMarkup(time, offsetAndType, this.options.OnlyOnStateChangeIsForced)));
        var $newRow = $this.prev();

        $newRow.find('.time-offset-execute-type').on('change', $.proxy(onTimeExecuteTypeChange, this));
        $newRow.find('.time-offset-issue-time').on('change', $.proxy(onOffsetIssueTimeChange, this));
        $newRow.find('.delete-time-configuration').on('click', $.proxy(onDeleteTimeConfigurationClick, this));
        $newRow.find('.add-time-configuration').on('click', $.proxy(onAddTimeConfigurationClick, this));
    }

    function onDeleteTimeConfigurationClick(evt) {
        var $this = $(evt.currentTarget);
        var $timeConfiguration = $this.closest('div.condition-time-configuration');

        $timeConfiguration.remove();
    }

    function onTimeExecuteTypeChange(evt) {
        var $this = $(evt.currentTarget);
        var value = $this.val();
        var $issueTime = $this.next();
        var issueTimeValue = $issueTime.val();

        $issueTime.find('[value="0"]').toggleClass('hide', value === "1");
        $issueTime.find('[value="1"]').toggleClass('hide', value === "1");

        if (issueTimeValue === "0" || issueTimeValue === "1") {
            $issueTime.val(2);
        }
    }

    function onOffsetIssueTimeChange(evt) {
        var $this = $(evt.currentTarget);
        var value = $this.val();
        var $executeType = $this.prev();
        var executeTypVal = $executeType.val();

        $this.find('[value="0"]').toggleClass('hide', executeTypVal === '1');
        $this.find('[value="1"]').toggleClass('hide', executeTypVal === '1');

        if (value === '0' || value === '1') {
            $executeType.val(2);
        }
    }

    function unbindEvents() {
        $(window).off('resize.' + this.options.ID);

        this.$content.find('.time-offset-execute-type').off('change');
        this.$content.find('.delete-time-configuration').off('click');
        this.$content.find('.add-time-configuration').off('click');
    }

    function close() {
        this.$window.remove();

        if (this.$overlay instanceof $) {
            this.$overlay.remove();
        }

        unbindEvents.call(this);
    }

    function resizeWindow() {
        var screenHeight = $(window).height();
        var screenWidth = $(window).width();
        var width = this.$window.width();
        var height = this.options.ResizeToFitToContent ?
            getWindowHeightByContent.call(this) :
            this.$window.outerHeight();

        if (height > this.options.MaxHeight) {
            height = this.options.MaxHeight;
        }

        if (width > this.options.MaxWidth) {
            width = this.options.MaxWidth;
        }

        if ((screenHeight - 40) < height) {
            height = screenHeight - 40;
        }

        if ((screenWidth - 40) < width) {
            width = screenWidth - 40;
        }

        this.$window.css({
            height: height,
            width: width,
            'margin-top': -((height + 20) / 2),
            'margin-left': -((width + 20) / 2)
        });
    }

    function getWindowHeightByContent() {
        var height = parseInt(this.$window.css('border-top-width'), 10) +
            parseInt(this.$window.css('border-bottom-width'), 10) +
            this.$window.find('.top').outerHeight(true);

        this.$content.children().each(function (_idx, child) {
            var $child = $(child);

            height += $child.outerHeight(true);
        });

        height += this.$bottom.outerHeight(true);

        return height;
    }

    function applyStyles() {
        if (this.options.ShowOverlay) {
            $('#' + this.options.ID + '-overlay')
                .css('z-index', this.options.Styles['_']['z-index'] - 1);
        }

        if (!this.options.Styles) {
            return;
        }

        for (var selector in this.options.Styles) {
            if (!this.options.Styles.hasOwnProperty(selector)) {
                continue;
            }

            var $elem = selector === '_' ?
                this.$window :
                this.$window.find(selector);

            var styles = this.options.Styles[selector];

            if (Object.values(styles).length === 0) {
                continue;
            }

            $elem.css(styles);
        }
    }

    ProcessConditionTimeConfiguration.prototype.Show = show;
    ProcessConditionTimeConfiguration.prototype.Close = close;

    global.ProcessConditionTimeConfiguration = ProcessConditionTimeConfiguration;
})(Modifications.Popups || (Modifications.Popups = {}));