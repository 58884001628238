/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @typedef Point
     * @property {int} X
     * @property {int} Y
     */

    /**
     * @typedef ComponentPosition
     * @property {number} Type
     * @property {Point} Coordinates
     * @property {string|null} ReferenceElementOID
     * @property {number|null} ReferencePoint
     */

    /**
     * @abstract
     * @typedef BaseComponent
     */
    global.Model.BaseComponent = (function () {
        /**
         * @param {string} type
         * @param {string} title
         * @param {string|null} description
         * @param {string} icon
         * @param {ComponentSettings} settings
         * @constructor
         */
        function BaseComponent(type, title, description, icon, settings) {
            if (this.constructor === BaseComponent) {
                throw new Error('This class cannot be instanciated.');
            }

            /**
             * @readonly
             */
            this.CommonSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.position.label'),
                    CollapsedByDefault: true,
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.position.top'),
                            Key: 'Position.Coordinates.Y',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: 0,
                            Max: 100
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.position.left'),
                            Key: 'Position.Coordinates.X',
                            Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                            SubType: 'number',
                            Min: 0,
                            Max: 100
                        }
                    ]
                }
            ];

            const dimensionSettings = {
                Label: i18next.t('changeMode.pdfDesigner.settings.dimensions.label'),
                CollapsedByDefault: true,
                Settings: [
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.dimensions.width'),
                        Key: 'Width',
                        Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                        SubType: 'number',
                        Min: 0,
                        Max: 100
                    }
                ]
            };

            if (!this.DisableHeightSetting) {
                dimensionSettings.Settings.unshift({
                    Label: i18next.t('changeMode.pdfDesigner.settings.dimensions.height'),
                    Key: 'Height',
                    Type: global.Model.Enums.ComponentSettingRepresentation.Input,
                    SubType: 'number',
                    Min: 0,
                    Max: 100
                });
            }

            this.CommonSettings.push(dimensionSettings);

            /**
             * @property
             * @type {string}
             * @readonly
             */
            this.Type = type;

            /**
             * @type {string}
             * @readonly
             */
            this.Title = title;

            /**
             * @type {string}
             * @readonly
             */
            this.Description = description;

            /**
             * @type {string}
             * @readonly
             */
            this.Icon = icon;

            /**
             * @type {number|null}
             */
            this.ZIndex = 3;

            /**
             * @type {ComponentSettings}
             */
            this.Settings = settings;

            /**
             * @type {boolean}
             * @readonly
             */
            this.IsTemplate = this.Settings.IsTemplate;

            /**
             * @type {string}
             * @readonly
             */
            this.OID = this.IsTemplate ? uuid() : this.Settings.OID;

            /**
             * @type {string|null}
             * @readonly
             */
            this.Placeholder = this.IsTemplate ? this.Settings.Placeholder : null;

            /**
             * @readonly
             */
            this.DefaultDimensions = {
                Width: 20,
                Height: 5
            };

            /**
             * @type {ComponentPosition|null}
             */
            this.Position = null;

            /**
             * @type {boolean}
             */
            this.SettingsFocused = false;

            /**
             * @readonly
             * @type {{}}
             */
            this.SettingsMap = {};

            this.CommonSettings.forEach(function (settingsGroup) {
                (settingsGroup.Settings || []).forEach(function (setting) {
                    this.SettingsMap[setting.Key] = setting;
                }, this);
            }, this);

            this.Layout = {
                HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                VerticalAlignment: global.Model.Enums.VerticalAlignment.Center,
                Rotation: 0,
                Fitmethod: global.Model.Enums.FitMethod.Auto
            };

            (this.ComponentSettings || []).forEach(function (settingsGroup) {
                (settingsGroup.Settings || []).forEach(function (setting) {
                    this.SettingsMap[setting.Key] = setting;
                }, this);
            }, this);
        }

        BaseComponent.prototype.constructor = BaseComponent;

        BaseComponent.prototype.CreateSelectionMarkup = function () {
            const markup = [`<li class="component-prototype" id="${this.OID}" data-identifier="${this.OID}" data-type="${this.Type}">`];

            if (!!this.Icon) {
                markup.push(`<div class="icon-wrapper"><img src="./img/${this.Icon}" /></div>`);
            }

            markup.push(`<div class="text-wrapper"><strong>${this.Title}</strong>`);

            if (!!this.Description) {
                markup.push(`<p class="description">${this.Description}</p>`);
            }

            markup.push('</div></li>');

            return markup.join('');
        };

        BaseComponent.prototype.CreateDragElement = function () {
            const markup = [
                '<div id="', this.OID,'-prototype-mover" class="pdf-designer-prototype-mover">',
                    this.Title,
                '</div>'
            ].join('');

            return $(markup);
        };

        /**
         * @param {number} relativeX
         * @param {number} relativeY
         * @param {object?} additionalSettings
         * @return {Deferred}
         */
        BaseComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY, additionalSettings) {
            let settings = this.Settings || {};

            settings.OID = uuid();
            delete settings.IsTemplate;

            let component = new this.constructor(this.Title, this.Description, settings);

            if (additionalSettings) {
                component = Object.assign(component, additionalSettings);
            }

            component.Width = component.DefaultDimensions.Width;
            component.Height = component.DefaultDimensions.Height;

            component.Position = {
                Type: global.Model.Enums.PositionType.Absolute,
                Coordinates: {
                    X: relativeX,
                    Y: relativeY
                }
            };

            delete component.Title;
            delete component.Description;
            delete component.Icon;

            return $.Deferred().resolve(component);
        };

        /**
         * @param {Page?} page
         * @param {string?} renderArea
         * @return {$|string|null}
         */
        BaseComponent.prototype.RenderOnPage = function (page, renderArea, sizeSettings) {
            sizeSettings = sizeSettings || {};

            if (!renderArea) {
                if (!this.RenderArea) {
                    console.warn('Missing area information');
                    return null;
                }

                renderArea = this.RenderArea;
            } else if (!this.RenderArea) {
                this.RenderArea = renderArea;
            }

            if (this.IsTemplate) {
                console.warn('A template cannot be rendered on a page');
                return null;
            }

            if (!(page instanceof global.Model.Page)) {
                if (!(this.Page instanceof global.Model.Page)) {
                    console.error('Page information missing');
                    return null;
                }

                page = this.Page;
            }

            const $page = $(page.PageSelector);

            if (!$page.length) {
                return null;
            }

            const positionOnPage = {
                x: this.IsTableCell ? 0 : (this.Position.Coordinates.X / 100) * page.Width,
                y: this.IsTableCell ? 0 : (this.Position.Coordinates.Y / 100) * page.Height
            };

            const headerHeight = page.Section.Header ? (page.Section.Header.Height / 100) * page.Height : 0;
            const footerHeight = page.Section.Footer ? (page.Section.Footer.Height / 100) * page.Height : 0;
            const contentHeight = page.Height - headerHeight - footerHeight;

            switch (renderArea) {
                case global.Model.Enums.SectionModificationArea.Page:
                    positionOnPage.y -= headerHeight;
                    break;
                case global.Model.Enums.SectionModificationArea.Footer:
                    positionOnPage.y -= headerHeight;
                    positionOnPage.y -= contentHeight;
                    break;
            }

            const dimensions = {
                height: ((this.Height || sizeSettings.Height) / 100) * page.Height,
                width: ((this.Width || sizeSettings.Width) / 100) * page.Width
            };

            let componentBoxStyle = this.IsTableCell ? 'position:relative;' : 'position:absolute;';

            if (positionOnPage.x >= 0) {
                componentBoxStyle += `left:${positionOnPage.x}px;`;
            }

            if (positionOnPage.y >= 0) {
                componentBoxStyle += `top:${positionOnPage.y}px;`;
            }

            if (dimensions.height > 0) {
                componentBoxStyle += `height:${dimensions.height}px;`;
            }

            if (dimensions.width > 0) {
                componentBoxStyle += `width:${dimensions.width}px;`;
            }

            if (this.Textflow) {
                if (this.Textflow.FontSize) {
                    const fontSize = (this.Textflow.FontSize / 100) * page.Height;

                    componentBoxStyle += `font-size:${fontSize}px;`
                    componentBoxStyle += `line-height:${fontSize}px;`
                }

                if (!!this.Textflow.Color) {
                    componentBoxStyle += `color:${this.Textflow.Color};`;
                }

                if (this.Textflow.Bold) {
                    componentBoxStyle += 'font-weight:bold;';
                }

                if (this.Textflow.Italic) {
                    componentBoxStyle += 'font-style:italic;'
                }

                if (this.Textflow.Underline) {
                    componentBoxStyle += 'text-decoration:underline;';
                }
            }

            if (this.StrokeColor) {
                componentBoxStyle += `border-color:${this.StrokeColor};`;
            }

            if (this.Layout) {
                switch (this.Layout.HorizontalAlignment) {
                    case global.Model.Enums.HorizontalAlignment.Left:
                        componentBoxStyle += 'justify-content:flex-start;';
                        break;
                    case global.Model.Enums.HorizontalAlignment.Center:
                        componentBoxStyle += 'justify-content:center;text-align:center;';
                        break;
                    case global.Model.Enums.HorizontalAlignment.Right:
                        componentBoxStyle += 'justify-content:flex-end;text-align:right;';
                        break;
                    case global.Model.Enums.HorizontalAlignment.Justify:
                        componentBoxStyle += 'justify-content:flex-start;text-align:justify;';
                        break;
                }

                switch (this.Layout.VerticalAlignment) {
                    case global.Model.Enums.VerticalAlignment.Top:
                        componentBoxStyle += 'align-items:flex-start;';
                        break;
                    case global.Model.Enums.VerticalAlignment.Center:
                        componentBoxStyle += 'align-items:center;';
                        break;
                    case global.Model.Enums.VerticalAlignment.Bottom:
                        componentBoxStyle += 'align-items:flex-end;';
                        break;
                }
            }

            if (typeof this.ZIndex === 'number' && this.ZIndex > 0) {
                componentBoxStyle += `z-index:${this.ZIndex};`;
            }

            const $existingNode = $page.find(`.component[data-identifier="${this.OID}"]`);

            if ($existingNode.length && !this.IsTableCell) {
                $existingNode.remove();
            }

            let componentClasses = '';

            componentClasses += !!this.IsActive ? ' active' : '';
            componentClasses += !!this.IsTableCell ? ' table-cell-component' : '';

            if (this instanceof global.Model.TableComponent) {
                componentClasses += ' table-component';

                if (!!this.IsSubsampleTable || this.CellProvider.ParameterGroupOID) {
                    this.IsSubsampleTable = true;
                    componentClasses += ' subsample-table';
                }
            } else if (this instanceof global.Model.LineComponent) {
                componentClasses += ' line-component';
            }

            const markup = [
                `<div class="component${componentClasses}" style="${componentBoxStyle}" data-identifier="${this.OID}">`,
                    this.CreateComponentContent(),
                '</div>'
            ].join('');

            if (this.IsTableCell) {
                if ($existingNode && $existingNode.length) {
                    $existingNode.replaceWith($(markup));

                    const $node = $page.find(`.table-cell-component[data-identifier="${this.OID}"]`);

                    setTableNodeDimensions.call(this, $node, page);

                    return setAndReturnNode.call(this, $node);
                }

                return markup;
            }

            let $target = $page;

            switch (renderArea) {
                case global.Model.Enums.SectionModificationArea.Header:
                    $target = $target.find('.page-header');
                    break;
                case global.Model.Enums.SectionModificationArea.Footer:
                    $target = $target.find('.page-footer');
                    break;
                default:
                    $target = $target.find('.main-area');
                    break;
            }

            $target.append(markup);

            const $node = $page.find(`.component[data-identifier="${this.OID}"]`);

            return setAndReturnNode.call(this, $node);
        };

        function setTableNodeDimensions($node, page) {
            const currentHeight = $node.parent().height();

            let height = 10;

            $node.children().each(function(){
                height = height + $(this).outerHeight(true);
            });

            const relativeDimensions = {
                height: (height < currentHeight ? currentHeight : height) / page.Height * 100,
                width: $node.parent().width() / page.Width * 100
            };

            this.SetDimensions(relativeDimensions.height, relativeDimensions.width);
        }

        function setAndReturnNode($node) {
            this.SetNode($node);

            $node.on('click.selectNode', $.proxy(this.OnComponentClick, this));
            $node.trigger('click');

            return $node;
        }

        /**
         * @return {string}
         */
        BaseComponent.prototype.CreateComponentContent = function () {
            return `<span style="color:#f00;">${Tools.escapeHtml(this.Title)}</span>`;
        };

        BaseComponent.prototype.RenderSettings = function () {
            const context = getPlacementArea.call(this);
            const $settings = $(context.Settings.SettingsContainerSelector);

            if (!$settings.length) {
                console.warn('settings container unavailable');
                return;
            }

            $settings.html(renderSettings.call(this));
            bindEvents.call(this);
        };

        function getPlacementArea() {
            return this.RenderArea === global.Model.Enums.SectionModificationArea.Page ?
                this.Page :
                this.Marginal;
        }

        function renderSettings() {
            const settings = this.CommonSettings.concat(this.ComponentSettings || []);

            /**
             * @type Function
             */
            let removeAction;

            switch (this.RenderArea) {
                case global.Model.Enums.SectionModificationArea.Header:
                case global.Model.Enums.SectionModificationArea.Footer:
                    removeAction = $.proxy(this.Marginal.RemoveComponent, this.Marginal, this.OID);
                    break;
                case global.Model.Enums.SectionModificationArea.Page:
                    removeAction = $.proxy(this.Page.RemoveComponent, this.Page, this.OID);
                    break;
            }

            const actions = {
                Label: i18next.t('changeMode.pdfDesigner.settings.actions.label'),
                Settings: [{
                    Label: i18next.t('changeMode.pdfDesigner.settings.actions.deleteComponent'),
                    Type: global.Model.Enums.ComponentSettingRepresentation.Button,
                    AdditionalClasses: ['btn-delete'],
                    HideLabel: true,
                    OnClick: removeAction,
                    Key: 'delete-component'
                }]
            };

            this.SettingsMap[actions.Settings[0].Key] = actions.Settings[0];

            settings.push(actions);

            return settings.map(function (settingsGroup) {
                return renderSettingsGroup.call(this, settingsGroup);
            }, this).join('');
        }

        function renderSettingsGroup(settingsGroup) {
            if (!settingsGroup) {
                return null;
            }

            return [
                `<div class="settings-section${settingsGroup.CollapsedByDefault ? ' collapsed' : ''}">`,
                    `<h4 class="section-header">${settingsGroup.Label}</h4>`,
                    '<ul class="settings-list">',
                        (settingsGroup.Settings || []).map(function (setting) {
                            return renderSetting.call(this, setting);
                        }, this).join(''),
                    '</ul>',
                '</div>',
            ].join('');
        }

        function renderSetting(setting) {
            if (!setting) {
                return null;
            }

            const markup = [
                `<li class="setting" data-key="${setting.Key}">`,
            ];

            if (!setting.HideLabel) {
                markup.push(`<label>${setting.Label}</label>`);
            }

            switch (setting.Type) {
                case global.Model.Enums.ComponentSettingRepresentation.Boolean:
                    markup.push(renderBooleanSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.Dropdown:
                    markup.push(renderDropdownSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.FileSelection:
                    markup.push(renderFileSelectionSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.Info:
                    markup.push(renderInfoSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.Input:
                    markup.push(renderTextInputSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.MultiLineInput:
                    markup.push(renderTextAreaInputSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.MultiButtonSelection:
                    markup.push(renderMultiButtonSelectionSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.Button:
                    markup.push(renderButtonSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentSettingRepresentation.Color:
                    markup.push(renderColorSetting.call(this, setting));
                    break;
            }

            markup.push('</li>');

            return markup.join('');
        }

        function renderBooleanSetting(setting) {
            if (!setting) {
                return;
            }

            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;
            const markup = [
                '<div class="toggle-switch">',
                    `<input type="checkbox" id="${setting.Key}" ${!!value ? ' checked' : ''} />`,
                    `<label for="${setting.Key}">&nbsp;</label>`,
                '</div>'
            ];

            return markup.join('');
        }

        function renderDropdownSetting(setting) {
            if (!setting || !(setting.Options || []).length) {
                return;
            }

            const markup = [
                '<select>'
            ];

            const selectedValue = setting.GetFormattedDisplayValue instanceof Function ?
                setting.GetFormattedDisplayValue.call(setting, this) :
                Tools.GetValueByPath(setting.Key, this) || setting.Default;

            markup.push((setting.Options || []).map(function (option) {
                return `<option value="${option.Value}"${option.Value === selectedValue ? ' selected' : ''}>${option.Label}</option>`;
            }).join(''));

            markup.push('</select>');

            return markup.join('');
        }

        function renderFileSelectionSetting(setting) {
            if (!setting) {
                return;
            }

            const markup = [];
            const selectedFile = changemode.Files[this.FileOID];

            markup.push(
                `<p class="selected-file${selectedFile ? '' : ' hide'}">`,
                    (selectedFile ? Tools.escapeHtml(selectedFile.Title) : ''),
                '</p>'
            );

            markup.push(
                '<div class="btn-steal select-file">',
                    i18next.t('changeMode.pdfDesigner.settings.files.fileSelection.buttonCaption'),
                '</div>'
            );

            return markup.join('');
        }

        function renderTextInputSetting(setting) {
            if (!setting) {
                return null;
            }

            const markup = [];
            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;

            markup.push(`<input type="${setting.SubType}" value="${$.trim(Tools.escapeHtml(value))}"`);

            if (setting.SubType === 'number') {
                if (typeof (setting.Min) === 'number' && !isNaN(setting.Min)) {
                    markup.push(` min="${setting.Min}"`);
                }

                if (typeof (setting.Max) === 'number' && !isNaN(setting.Max)) {
                    markup.push(` max="${setting.Max}"`);
                }
            }

            markup.push('/>');

            return markup.join('');
        }

        function renderTextAreaInputSetting(setting) {
            if (!setting) {
                return null;
            }

            const markup = [];
            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;
            const textareaValue = $.trim(Tools.escapeHtml(value)).replace(/<br( \/)?>/gm, '\n')

            markup.push(`<textarea>${textareaValue}</textarea>`);

            return markup.join('');
        }

        function renderInfoSetting(setting) {
            if (!setting) {
                return null;
            }

            const value = Tools.GetValueByPath(setting.Key, this);

            return `<p class="info">${value}</p>`;
        }

        function renderMultiButtonSelectionSetting(setting) {
            if (!setting) {
                return;
            }

            const value = !!setting.AllowMultiSelection ? null : Tools.GetValueByPath(setting.Key, this);
            const markup = [
                '<div class="multi-button-selection">',
                    setting.Options.map(function (option) {
                        const optionIsSelected = !!setting.AllowMultiSelection ?
                            !!Tools.GetValueByPath(`${setting.Key}.${option.Key}`, this) :
                            value === option.Value;

                        const markup = [
                            `<div class="multi-button-selection-button button-steal${optionIsSelected ? ' active' : ''}" title="${option.Caption}"`
                        ];

                        if (option.Value != null) {
                            markup.push(` data-value="${option.Value}"`);
                        }

                        if (!!option.Key) {
                            markup.push(` data-key="${option.Key}"`);
                        }

                        markup.push(
                            '>',
                                `<img src="${option.Icon}"${!!option.IconStyle ? ` style="${option.IconStyle}"` : ''} />`,
                            '</div>'
                        );

                        return markup.join('');
                    }, this).join(''),
                '</div>'
            ];

            return markup.join('');
        }

        function renderButtonSetting(setting) {
            if (!setting) {
                return;
            }

            return `<div class="button btn-steal${(setting.AdditionalClasses || []).length ? ` ${setting.AdditionalClasses.join(' ')}` : ''}">${setting.Label}</div>`;
        }

        function renderColorSetting(setting) {
            if (!setting) {
                return;
            }

            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;
            const color = !!value ? new Color(value) : null;

            const markup = [
                `<div class="color-setting-wrapper" title="${i18next.t('changeMode.pdfDesigner.settings.clickToChange')}">`,
                    '<div class="color-picker-wrapper hide"></div>',
                    `<div class="set-color"${color ? ` style="background-color:${color.getRGBA()};"` : ''}></div>`,
                '</div>'
            ];

            return markup.join('');
        }

        function bindEvents() {
            const context = getPlacementArea.call(this);
            const $settings = $(context.Settings.SettingsContainerSelector);

            $settings.find('.section-header')
                .off('click')
                .on('click', $.proxy(onSettingsSectionHeaderClick, this));

            $settings
                .off('input.changeValue')
                .off('focusin')
                .off('focusout')
                .on('input.changeValue', 'input, textarea, select', $.proxy(onChangeInputValue, this))
                .on('focusin', 'input, textarea, select', $.proxy(onFocusSetting, this))
                .on('focusout', 'input, textarea, select', $.proxy(onFocusOutSetting, this));

            $settings
                .off('click.selectFile')
                .on('click.selectFile', '.select-file', $.proxy(onFileSelectionClick, this));

            $settings
                .off('click.selectMultiSelectionButton')
                .on('click.selectMultiSelectionButton', '.multi-button-selection-button', $.proxy(onMultiButtonSelectionButtonClick, this));

            $settings
                .off('click.clickButton')
                .on('click.clickButton', '.button.btn-steal', $.proxy(onSettingsButtonClick, this));

            $settings
                .off('click.setColor')
                .off('change.changeColor')
                .on('click.setColor', '.set-color', $.proxy(onColorSettingButtonClick, this))
                .on('change.changeColor', '.set-color', $.proxy(onChangeColorSetting, this));
        }

        function onFocusSetting() {
            this.SettingsFocused = true;
        }

        function onFocusOutSetting() {
            this.SettingsFocused = false;
        }

        function onSettingsSectionHeaderClick(evt) {
            const $header = $(evt.currentTarget);

            $header.parent().toggleClass('collapsed');
        }

        function onChangeInputValue(evt) {
            const $input = $(evt.currentTarget);
            const key = $input.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];
            let value = setting.Type === global.Model.Enums.ComponentSettingRepresentation.Boolean ?
                $input.is(':checked') :
                $input.val();

            if (setting.SubType === 'number') {
                value = parseFloat(value);
            }

            Tools.SetValueByPath(key, this, value, true);

            this.RenderOnPage();

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            }
        }

        function onFileSelectionClick(evt) {
            const $input = $(evt.currentTarget);
            const key = $input.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            if (!setting) {
                return;
            }

            this.SettingsFocused = true;

            ChangeMode.FilePicker.Show({
                dataSourceFolders: changemode.Folders,
                dataSourceRootFolder: changemode.RootFolder,
                dataSourceFiles: changemode.Files,
                onPick: (file) => onPickFile.call(this, file, key),
                onAbort: () => this.SettingsFocused = false,
                filterMimeTypes: setting.SubType === 'image' ? ['image'] : null
            });
        }

        function onPickFile(file, key) {
            if (!key) {
                return;
            }

            this.SettingsFocused = false;

            Tools.SetValueByPath(key, this, file.OID);

            const context = getPlacementArea.call(this);
            const $settings = $(context.Settings.SettingsContainerSelector);
            const $setting = $settings.find(`.setting[data-key="${key}"]`);

            $setting
                .find('.selected-file')
                .toggleClass('hide', !file)
                .html(Tools.escapeHtml(file.Title));

            this.RenderOnPage();

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            }
        }

        function onMultiButtonSelectionButtonClick(evt) {
            const $button = $(evt.currentTarget);
            const key = $button.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            const subKey = $button.data('key');
            let propKey = key;

            if (!!subKey) {
                propKey += `.${subKey}`;
            }

            if (setting.AllowMultiSelection) {
                $button.toggleClass('active');
            } else {
                $button
                    .addClass('active')
                    .siblings('.active')
                    .removeClass('active');
            }

            const value = setting.AllowMultiSelection ?
                $button.hasClass('active') :
                $button.data('value');

            Tools.SetValueByPath(propKey, this, value, true);

            this.RenderOnPage();

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            }
        }

        function onSettingsButtonClick(evt) {
            const $button = $(evt.currentTarget);
            const key = $button.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            if (!setting) {
                return;
            }

            if (!(setting.OnClick instanceof Function)) {
                return;
            }

            setting.OnClick();
        }

        function onColorSettingButtonClick(evt) {
            const $button = $(evt.currentTarget);
            const key = $button.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            if (!setting) {
                return;
            }

            const $wrapper = $button.siblings('.color-picker-wrapper');

            if (this.ColorPicker &&
                this.ColorPicker instanceof ColorPicker &&
                this.ColorPicker.picker.visible)
            {
                this.ColorPicker.Hide();
                this.ColorPicker = null;
                $wrapper.addClass('hide');

                return;
            }

            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;
            const color = !!value ? new Color(value) : null;

            $wrapper.removeClass('hide');

            this.ColorPicker = new ColorPicker({
                $element: $button,
                $staticContainer: $wrapper,
                InitialColor: color.getHex(),
                DefaultColors: Tools.GetDefaultColors(),
                SetBackgroundColor: true,
                AddCurrentAsDefaultColor: true
            });

            this.ColorPicker.Show();
        }

        function onChangeColorSetting(evt) {
            if (!(this.ColorPicker instanceof ColorPicker)) {
                return;
            }

            const $button = $(evt.currentTarget);
            const key = $button.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            if (!setting) {
                return;
            }

            const value = $button.css('background-color');
            const color = new Color(value);

            Tools.SetValueByPath(key, this, color.getHex(), true);

            this.RenderOnPage();

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            }
        }

        /**
         * @param {string} key
         * @param {string|number} value
         * @return {this}
         * @constructor
         */
        BaseComponent.prototype.UpdateTextInputSetting = function (key, value) {
            if (!this.IsActive) {
                return this;
            }

            if (!key) {
                return this;
            }

            const context = getPlacementArea.call(this);
            const $settings = $(context.Settings.SettingsContainerSelector);
            const $input = $settings.find(`.setting[data-key="${key}"] input`);

            if (!$input.length) {
                return this;
            }

            $input.val(value);

            return this;
        };

        /**
         * @param {Page} page
         * @return {this}
         */
        BaseComponent.prototype.SetPage = function (page) {
            if (!(page instanceof global.Model.Page)) {
                return this;
            }

            this.Page = page;

            return this;
        };

        /**
         * @param {Marginal} marginal
         * @return {this}
         */
        BaseComponent.prototype.SetMarginal = function (marginal) {
            if (!(marginal instanceof global.Model.Marginal)) {
                return this;
            }

            this.Marginal = marginal;

            return this;
        };

        /**
         * @param {$} $node
         * @return {this}
         */
        BaseComponent.prototype.SetNode = function ($node) {
            if (!($node instanceof $)) {
                return this;
            }

            this.$node = $node;

            return this;
        };

        /**
         * @param {number} relativeX
         * @param {number} relativeY
         * @return {this}
         */
        BaseComponent.prototype.SetCoordinates = function (relativeX, relativeY) {
            if (!this.Position) {
                console.warn(`Component position missing (${this.OID})`);
                return this;
            }

            const { X: oldX, Y: oldY } = this.Position.Coordinates;

            this.Position.Coordinates.X = relativeX;
            this.Position.Coordinates.Y = relativeY;

            const context = getPlacementArea.call(this);

            if (context.CheckComponentXPosition(this)) {
                this.Position.Coordinates.X = oldX;
            }

            if (context.CheckComponentYPosition(this)) {
                this.Position.Coordinates.Y = oldY;
            }

            this
                .UpdateTextInputSetting('Position.Coordinates.X', relativeX)
                .UpdateTextInputSetting('Position.Coordinates.Y', relativeY);

            return this;
        };

        /**
         * @param {number} height
         * @param {number} width
         * @return {this}
         */
        BaseComponent.prototype.SetDimensions = function (height, width) {
            if (!this.Settings) {
                console.warn(`Component settings missing (${this.OID})`);
                return this;
            }

            this.Height = height;
            this.Width = width;

            this
                .UpdateTextInputSetting('Height', height)
                .UpdateTextInputSetting('Width', width);

            return this;
        };

        /**
         * Setzt zusätzliche Event-Handler, die auf Ereignisse außerhalb der Komponente reagieren
         * @param {PdfDesigner} pdfDesignerInstance
         * @param {{ EventType: string, Handler: Function }[]} eventHandlers
         * @return {BaseComponent}
         */
        BaseComponent.prototype.SetEventHandlers = function (pdfDesignerInstance, eventHandlers) {
            if (!pdfDesignerInstance) {
                return this;
            }

            if (!(eventHandlers || []).length) {
                return this;
            }

            eventHandlers.forEach(handler => {
                if (!handler.EventType || !(handler.Handler instanceof Function)) {
                    return;
                }

                $(pdfDesignerInstance.$content).on(handler.EventType, () => handler.Handler(this));
            });
        };

        /**
         * Handelt das Click-Event auf diese Komponente
         * @param {MouseEvent} evt
         */
        BaseComponent.prototype.OnComponentClick = function (evt) {
            evt.stopPropagation();

            if (this.IsActive) {
                return;
            }

            this.IsActive = true;

            if (this.Marginal) {
                $(`${this.Marginal.PageSelector} .component[data-identifier="${this.OID}"]`)
                    .toArray()
                    .forEach(n => $(n).addClass('active'));
            } else {
                this.$node.addClass('active');
            }

            this.RenderSettings();

            const context = getPlacementArea.call(this);
            const clickAction = context.Settings.OnComponentClick;

            if (clickAction instanceof Function) {
                clickAction.call(context, this);
            }
        };

        /**
         * Verschiebt die Komponente in eine vorgegebene Richtung.
         * @param {'up'|'right'|'down'|'left'} direction
         * @param {number} distance
         * @return {BaseComponent}
         * @constructor
         */
        BaseComponent.prototype.Move = function (direction, distance) {
            if (!direction || typeof distance !== 'number' || isNaN(distance) || distance < 0) {
                return this;
            }

            const { X: oldX, Y: oldY } = this.Position.Coordinates;

            switch (direction) {
                case 'up':
                    this.Position.Coordinates.Y -= distance;
                    break;
                case 'right':
                    this.Position.Coordinates.X += distance;
                    break;
                case 'down':
                    this.Position.Coordinates.Y += distance;
                    break;
                case 'left':
                    this.Position.Coordinates.X -= distance;
                    break;
            }

            const context = getPlacementArea.call(this);

            if (context.CheckComponentXPosition(this)) {
                this.Position.Coordinates.X = oldX;
            }

            if (context.CheckComponentYPosition(this)) {
                this.Position.Coordinates.Y = oldY;
            }

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            } else {
                this.RenderOnPage();
            }
        };

        /**
         * Führt Aktionen durch, die beim Deselektieren der Komponente durchgeführt werden sollen.
         */
        BaseComponent.prototype.UnSelect = function () {
            this.IsActive = false;
            this.ColorPicker = null;

            if (this.Marginal) {
                $(`${this.Marginal.PageSelector} .component[data-identifier="${this.OID}"]`)
                    .toArray()
                    .forEach(n => $(n).removeClass('active'));
            } else {
                this.$node.removeClass('active');
            }
        };

        /**
         * Führt zusätzliche Aktionen beim Entfernen der Komponente durch.
         */
        BaseComponent.prototype.Remove = function () {
            this.IsActive = false;

            if (this.Marginal) {
                $(`${this.Marginal.PageSelector} .component[data-identifier="${this.OID}"]`)
                    .toArray()
                    .forEach(n => n.remove());
            } else {
                this.$node.remove();
            }

            const context = getPlacementArea.call(this);
            const removeAction = context.Settings.OnComponentRemove;

            if (removeAction instanceof Function) {
                removeAction.call(context, this);
            }
        };

        /**
         * Entfernt alle Hilfsinformationen von der Instanz
         */
        BaseComponent.prototype.RemoveHelperInformation = function () {
            delete this.$node;
            delete this.Checkpoint;
            delete this.CommonSettings;
            delete this.ComponentSettings;
            delete this.DefaultDimensions;
            delete this.IsActive;
            delete this.IsTemplate;
            delete this.Marginal;
            delete this.Page;
            delete this.Placeholder;
            delete this.RenderArea;
            delete this.Settings;
            delete this.SettingsMap;
            delete this.ZIndex;
            delete this.ColorPicker;
        };

        return BaseComponent;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));