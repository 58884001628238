(function (global) {
    global.PropertyTypes = {
        Group: 0,
        TextLine: 1,
        TextMemo: 2,
        Number: 3,
        ResettableButton: 4,
        RichTextField: 5,
        Buttons: 6,
        Checkbox: 7,
        FileTable: 8
    };
})(Modifications.Pages || (Modifications.Pages = {}));