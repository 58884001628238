/**
 * @require ./property.js
 */
(function (global) {
    var RichTextProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.RichTextField;

        if (!(definition.OnChange instanceof Function)) {
            definition.OnChange = function () {
                changemode.CurrentPage.CurrentTab.MarkAsModified();
            };
        }

        global.Base.Properties.Base.call(this, definition);
    };

    RichTextProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: RichTextProperty
    });

    RichTextProperty.prototype.RenderInput = function () {
        var html = [
            '<textarea id="', this.ID + '-value"',
            ' class="xlarge" placeholder="', i18next.t('misc.enterText'), '"'
        ];

        if (typeof this.Rows === 'number' && this.Rows > 0) {
            html.push(' rows="', this.Rows, '"');
        }

        html.push(
            '>',
            '</textarea>'
        );

        html.push('<label class="item-description"></label>');

        return html.join('');
    };

    RichTextProperty.prototype.OnAfterRendered = function () {
        var userLanguage = User.Language;
        var editorLanguage = SUNEDITOR_LANG[userLanguage.split('-')[0]];

        this.Editor = SUNEDITOR.create(this.ID + '-value', {
            buttonList: [
                ['font', 'fontSize', 'formatBlock'],
                '/', // Line break
                ['bold', 'underline', 'italic', 'strike'],
                ['fontColor', 'hiliteColor'],
                ['removeFormat'],
                ['align', 'list']
            ],
            lang: editorLanguage || SUNEDITOR_LANG['en']
        });

        var value = CurrentEntity[this.MappedProperty];

        if (!!value) {
            value = DOMPurify.sanitize(value);
            this.Editor.setContents(value);
        }

        var $area = $(this.Editor.core.context.element.originElement);
        Tools.ShowRteContentSize($area, value);

        this.BindEvents.call(this);
    };

    RichTextProperty.prototype.BindEvents = function () {
        var self = this;

        this.Editor.onChange = function (content, core) {
            if (self.OnChange instanceof Function) {
                self.OnChange.apply(this, arguments);
            }

            var $area = $(core.context.element.originElement);

            Tools.ShowRteContentSize($area, content);
        };
    };

    RichTextProperty.prototype.SetValue = function (value) {
        if (!this.Editor) {
            return null;
        }

        value = DOMPurify.sanitize(value);

        this.Editor.setContents(value);
    };

    RichTextProperty.prototype.GetValue = function () {
        if (!this.Editor) {
            return null;
        }

        return DOMPurify.sanitize(this.Editor.getContents());
    };

    RichTextProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.RichTextProperty = RichTextProperty;
})(Modifications.Pages || (Modifications.Pages = {}));