/**
 * @require ../../property-types.js
 */
(function (global) {
    var PropertyBase = function (definition) {
        if (this.constructor === PropertyBase) {
            throw new Error('This class cannot be instanciated.')
        }

        if (!definition) {
            throw new Error('Property definition not given');
        }

        for (var attr in definition) {
            if (!definition.hasOwnProperty(attr)) {
                continue;
            }

            this[attr] = definition[attr];
        }
    }

    PropertyBase.prototype.Render = function () {
        var html = ['<div class="item" id="', this.ID, '"'];

        html.push(
            '>',
            '<div class="title">', this.Caption, this.IsRequired ? ' *' : '', '</div>',
                '<div class="content">',
                    this.RenderInput()
        );

        if (!!this.Description) {
            html.push(
                '<p class="item-description">',
                    this.Description || '',
                '</p>'
            );
        }

        if (this.AllowMultiSelection && this.RenderMultiSelectionValue instanceof Function) {
            html.push(this.RenderMultiSelectionValue.call(this, this.GetValue()));
        }

        html.push(
                '</div>',
            '</div>'
        );

        return html.join('');
    };

    PropertyBase.prototype.RenderInput = function () {
        throw new Error('Function must be defined in the concrete implementation of this class.');
    };

    PropertyBase.prototype.OnAfterRendered = function () {
        throw new Error('Function must be defined in the concrete implementation of this class.');
    };

    PropertyBase.prototype.Validate = function () {
        var value = this.GetValue();

        return !this.IsRequired || value !== '' && value != null;
    };

    PropertyBase.prototype.SetValue = function () {
        throw new Error('Function must be defined in the concrete implementation of this class.');
    };

    PropertyBase.prototype.GetValue = function () {
        throw new Error('Function must be defined in the concrete implementation of this class.');
    };

    PropertyBase.prototype.ApplyValue = function () {
        throw new Error('Function must be defined in the concrete implementation of this class.');
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.Base = PropertyBase;
})(Modifications.Pages || (Modifications.Pages = {}));