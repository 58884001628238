/**
 * @require ./_base.js
 */
(function (global) {
    var ElementCodingsWindow = function (id, elementCodings, forms, onSelectCoding) {
        if (!id) {
            id = 'element-codings';
        }

        this.ID = id;
        this.SearchResultPrefix = [];
        this.SearchResultForms = [];
        this.ElementCodings = elementCodings;
        this.Forms = forms;
        this.OnSelectCoding = onSelectCoding;
    };

    function show(selectedCodingOID) {
        if ($('#' + this.ID).length > 0) {
            return;
        }

        initPlaceholders.call(this, selectedCodingOID);

        var windowMarkup = getWindowMarkup.call(this);
        $('body').append(windowMarkup);

        initWindow.call(this);
        bindEvents.call(this);
        resizeWindow.call(this);

        return this;
    }

    function initWindow() {
        this.$window = $('#' + this.ID);
        this.$header = this.$window.find('h1');
        this.$footer = this.$window.find('.bottom');

        this.$search = this.$window.find('.search');
        this.$prefixesList = this.$window.find('.prefixes-list');
        this.$availableElementCodingsList = this.$window.find('.available-codings');
        this.$sortingSwitch = this.$window.find('#coding-popup-forms-sorting');

        initSearch.call(this);
    }

    function initPlaceholders(selectedCodingOID) {
        var me = this;

        me.ElementCodings.sort(sortByPrefix);
        me.ElementCodingsMap = {};
        me.ElementCodings.forEach(function (coding) {
            if (!!selectedCodingOID && coding.OID === selectedCodingOID) {
                me.CurrentlySelectedPrefix = coding;
            }

            me.ElementCodingsMap[coding.OID] = coding;
        });

        if (!me.CurrentlySelectedPrefix) {
            me.CurrentlySelectedPrefix = me.ElementCodings[0];
        }
    }

    function initSearch() {
        var searchOptions = {
            placeholder: i18next.t('changeMode.elementCodings.window.search')
        };

        this.Search = this.$search
            .search(searchOptions)
            .search(true);
    }

    function getWindowMarkup() {
        return [
            '<div id="', this.ID, '" class="popup draggable-window">',
                '<h1 class="top">',
                    '<span class="title">', i18next.t('changeMode.elementCodings.window.title'), '</span>',
                    '<span class="close"></span>',
                '</h1>',

                '<div class="content">',
                    '<div class="content-message-bubble">',
                        '<div class="content info">',
                            '<p class="text">',
                                i18next.t('changeMode.elementCodings.window.infoText'),
                            '</p>',
                        '</div>',
                    '</div>',

                    '<div class="detailed-data-picker">',
                        '<div class="search"></div>',
                        '<div class="grid-row details">',
                            '<ul class="grid-col-3-12 list prefixes-list">',
                                getPrefixesListMarkup.call(this),
                            '</ul>',
                            '<div class="grid-col-9-12 description available-codings">',
                                getElementCodingsListMarkup.call(this),
                            '</div>',
                        '</div>',
                    '</div>',
                '</div>',

                '<div class="bottom">',
                    '<div class="toggle-switch">',
                        '<input type="checkbox" id="coding-popup-forms-sorting">',
                        '<label for="coding-popup-forms-sorting">Toggle</label>',

                        '<span class="caption">',
                            i18next.t('changeMode.elementCodings.window.sortAlphabetical'),
                        '</span>',
                    '</div>',
                    '<div class="flex-dummy"></div>',
                    '<div class="btn-steal btn-close">',
                        i18next.t('changeMode.elementCodings.window.close'),
                    '</div>',
                '</div>',
            '</div>'
        ].join('');
    }

    function unbindEvents() {
        this.$header.off('click');
        this.$footer.find('.btn-close').off('click');

        this.$search.off('.search');
        this.$prefixesList.off('click');

        this.$sortingSwitch.off('change');

        $(window).off('resize.resizeElementCodingsWindow');
    }

    function bindEvents() {
        unbindEvents.call(this);

        this.$header.find('.close').on('click', $.proxy(close, this));
        this.$footer.find('.btn-close').on('click', $.proxy(close, this));

        this.$prefixesList.on('click', 'li:not(.selected)', $.proxy(onClickPrefixListItem, this));

        this.$search
            .on('search.search', $.proxy(onSearchFunction, this))
            .on('select-previous-result.search select-next-result.search', $.proxy(onSelectPreviousOrNextFunction, this));

        this.$sortingSwitch.on('change', $.proxy(onSwitchSortingOrder, this));

        $(window)
            .on('resize.resizeElementCodingsWindow', $.proxy(resizeWindow, this));

        this.$window
            .draggable({
                handle: '.top',
                cancel: '.close',
                appendTo: 'body',
                scroll: false
            });

        if (this.OnSelectCoding instanceof Function) {
            this.$availableElementCodingsList.on('click', 'li[data-codingident]', $.proxy(onSelectCoding, this));
        }
    }

    function getPrefixesListMarkup() {
        return this.ElementCodings
            .sort(sortByPrefix)
            .map($.proxy(createPrefixesListItem, this))
            .join('');
    }

    function sortFormsByCurrentOrder(forms) {
        if (this.$sortingSwitch && this.$sortingSwitch.is(':checked')) {
            forms.sort(function(a, b) {
                var cmp = Tools.SortByTitle(a, b);
                return cmp == 0 ? a.OID.localeCompare(b.OID, undefined, { sensitivity: 'base' }) : cmp;
            });
        } else {
            forms.sort(function(a, b) {
                var cmp = a.CodingIdent - b.CodingIdent;
                return cmp == 0 ? a.OID.localeCompare(b.OID, undefined, { sensitivity: 'base' }) : cmp;
            });
        }
    }

    function sortByPrefix(a, b) {
        return Tools.SortStringArray(a.Prefix, b.Prefix);
    }

    function createPrefixesListItem(elementCoding) {
        var isSelected = elementCoding === this.CurrentlySelectedPrefix;
        var isSearchResult = Tools.contains(this.SearchResultPrefix, elementCoding.Prefix, 'Prefix');
        var innerHtml = isSearchResult ?
            elementCoding.Prefix.replace(this.SearchRegExp, '<span class="search-result">$1</span>') :
            elementCoding.Prefix;

        return [
            '<li ', (isSelected ? 'class="selected" ' : ''), 'data-oid="', elementCoding.OID, '">',
            innerHtml,
            '</li>'
        ].join('');
    }

    function onClickPrefixListItem(evt) {
        var $this = $(evt.currentTarget);
        var oid = $this.data('oid');

        this.CurrentlySelectedPrefix = this.ElementCodingsMap[oid];

        this.$prefixesList.children('.selected').removeClass('selected');
        $this.addClass('selected');

        if(this.Search.input) {
            onSearchFunction.call(this, null, { input: this.Search.input });
        } else {
            var listMarkup = getElementCodingsListMarkup.call(this);

            this.$availableElementCodingsList.html(listMarkup);
        }
    }

    function getElementCodingsListMarkup() {
        var html = [];
        var me = this;

        if (me.CurrentlySelectedPrefix) {
            html.push(
                '<h4>', me.CurrentlySelectedPrefix.Prefix, '</h4>',
                !!me.CurrentlySelectedPrefix.Description ?
                    '<p class="small">' + me.CurrentlySelectedPrefix.Description + '</p>' :
                    ''
            );

            var linkedForms = me.Forms.filter(function (form) {
                return form.CodingOID === me.CurrentlySelectedPrefix.OID;
            });

            sortFormsByCurrentOrder.call(this, linkedForms);

            var currentMax = linkedForms.reduce(function (currentMax, form) {
                return currentMax > form.CodingIdent ? currentMax : form.CodingIdent;
            }, 0);

            var newIdent = Tools.element.formatElementCodingIdent(currentMax + 1);
            var createNewIdentText = i18next.t('changeMode.elementCodings.window.createNewIdent', {
                newIdentNumber: newIdent
            });

            html.push(
                '<ul class="item-list list">',
                    linkedForms.map($.proxy(createExistingFormCodingItemMarkup, this)).join(''),
                    '<li data-codingident="', (currentMax + 1),'">', createNewIdentText, '</li>',
                '</ul>'
            );
        }

        return html.join('');
    }

    function createExistingFormCodingItemMarkup(form) {
        var formHierarchy = Tools.element.getElementPath(form.OID, true, [Enums.elementType.Form, Enums.elementType.FormTemplate]);
        var formCodingIdent = Tools.element.formatElementCodingIdent(form.CodingIdent);
        var isSearchResult = Tools.contains(this.SearchResultForms, form.Title, 'Title');

        var innerHtml = isSearchResult ?
            form.Title.replace(this.SearchRegExp, '<span class="search-result">$1</span>') :
            form.Title;

        var html = [
            '<li data-oid="', form.OID,'" data-codingident="', form.CodingIdent,'">',
                '<span class="form-title">', innerHtml, ' (', formCodingIdent, ')', '</span>',
                !!formHierarchy ? '<p class="small">' + formHierarchy + '</p>' : '',
            '</li>'
        ];

        return html.join('');
    }

    function selectPrefixesSearchResult(selectedResult) {
        var $children = this.$prefixesList.children();

        $children
            .filter('.selected-search-result')
            .removeClass('selected-search-result');

        var idx = (selectedResult || 1) - 1;

        if (idx >= this.SearchResultPrefix.length || idx < 0) {
            return;
        }

        var selectedSearchResult = this.SearchResultPrefix[idx];
        var $selectedSearchResult = $children.filter('[data-oid="{0}"]'.format(selectedSearchResult.OID));

        $selectedSearchResult.addClass('selected-search-result');
        $selectedSearchResult.get(0).scrollIntoView();
    }

    function selectFormSearchResult(selectedResult) {
        var $children = this.$availableElementCodingsList.find('.item-list').children();

        $children
            .filter('.selected-search-result')
            .removeClass('selected-search-result');

        var idx = (selectedResult || 1) - 1;

        if (idx >= this.SearchResultForms.length || idx < 0) {
            return;
        }

        var prefixOID = this.CurrentlySelectedPrefix.OID;
        var selectedSearchResult = this.SearchResultForms.filter(function(form) {
            return form.CodingOID == prefixOID;
        })[idx];
        var $selectedSearchResult = $children.filter('[data-oid="{0}"]'.format(selectedSearchResult.OID));

        $selectedSearchResult.addClass('selected-search-result');
        if($selectedSearchResult.length) {
            $selectedSearchResult.get(0).scrollIntoView();
        }
    }

    function onSearchFunction(_event, data) {
        var me = this;
        var searchText = (data.input || '')
            .trim()
            .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

        if (searchText) {
            me.SearchRegExp = new RegExp('(' + searchText + ')', 'ig');

            me.SearchResultPrefix = Object.values(me.ElementCodingsMap).filter(function (fn) {
                me.SearchRegExp.lastIndex = 0;

                return me.SearchRegExp.test(fn.Prefix);
            });

            var prefixOID = this.CurrentlySelectedPrefix.OID;

            me.SearchResultForms = me.Forms.filter(function (form) {
                me.SearchRegExp.lastIndex = 0;

                if(form.CodingOID !== prefixOID) {
                    return false;
                };

                return me.SearchRegExp.test(form.Title);
            });

            sortFormsByCurrentOrder.call(this, me.SearchResultForms);

            me.Search.setResultCount(me.SearchResultPrefix.length + me.SearchResultForms.length);
        } else {
            me.SearchRegExp = null;
            me.SearchResultPrefix = [];
            me.SearchResultForms = [];
            me.Search.setResultCount(0);
        }

        var listMarkup = getPrefixesListMarkup.call(me);
        var formsMarkup = getElementCodingsListMarkup.call(me);

        me.$prefixesList.html(listMarkup);
        me.$availableElementCodingsList.html(formsMarkup);

        if(me.SearchResultPrefix && me.SearchResultPrefix.length) {
            selectPrefixesSearchResult.call(me, 1);
            selectFormSearchResult.call(this, -1);
        } else {
            selectPrefixesSearchResult.call(me, -1);
            selectFormSearchResult.call(this, 1);
        }
    }

    function onSwitchSortingOrder(_event) {
        if(this.Search.input) {
            onSearchFunction.call(this, null, { input: this.Search.input });
        } else {
            var listMarkup = getElementCodingsListMarkup.call(this);

            this.$availableElementCodingsList.html(listMarkup);
        }
    }

    function onSelectPreviousOrNextFunction(_event, data) {
        if(data.selectedResult > this.SearchResultPrefix.length) {
            var overTheEdgeSelection = data.selectedResult - this.SearchResultPrefix.length;
            selectFormSearchResult.call(this, overTheEdgeSelection);
            selectPrefixesSearchResult.call(this, -1);
        } else {
            selectPrefixesSearchResult.call(this, data.selectedResult);
            selectFormSearchResult.call(this, -1);
        }
    }

    function onSelectCoding(evt) {
        var $li = $(evt.currentTarget);
        var codingIdent = $li.data('codingident');

        this.OnSelectCoding(this.CurrentlySelectedPrefix.OID, codingIdent);
    }

    function resizeWindow() {
        var screenHeight = $(window).height();
        var height = 600;

        if ((screenHeight - 40) < height) {
            height = screenHeight - 40;
        }

        this.$window.css({
            height: height,
            'margin-top': -((height + 20) / 2)
        });
    }

    function close() {
        this.$window.remove();

        unbindEvents.call(this);
    }

    ElementCodingsWindow.prototype.Show = show;
    ElementCodingsWindow.prototype.Close = close;

    if (!global.System) {
        global.System = {};
    }

    global.System.ElementCodingsWindow = ElementCodingsWindow;
})(Modifications.Popups || (Modifications.Popups = {}));