/**
 * @require ../_base.js
 */
(function (global) {
    /**
     * Erstellt eine neue Instanz des PDF-Designers
     * @param options
     * @augments {PopupBase}
     * @constructor
     */
    const PdfDesigner = function (options) {
        if (!options.ID) {
            options.ID = 'pdf-designer';
        }

        options.Title = i18next.t('changeMode.pdfDesigner.window.title', { formTitle: options.Form.Title });
        options.ShowOverlay = true;

        if (!options.Buttons) {
            options.Buttons = {
                Close: {
                    Caption: i18next.t('misc.close')
                }
            };
        }

        options.Styles = {
            '_': {
                'z-index': 14001,
                width: 1450,
                height: '90%'
            },
            '.content': {
                padding: '10px'
            },
            '.content > p:first-child': {
                margin: '0 0 1rem 0'
            },
            '.component-list-switch': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '20rem',
                height: '3rem',
                'border-right': '1px solid #ccc',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                padding: '.5rem 1rem'
            },
            '.component-list-switch .switch': {
                padding: '.5rem',
                'border': '1px solid #ddd',
                width: '5rem',
                'text-align': 'center'
            },
            '.component-list-switch .switch:not(:first-child)': {
                'border-left': 'none'
            },
            '.component-list-switch .switch:first-child': {
                'border-top-left-radius': '.5rem',
                'border-bottom-left-radius': '.5rem'
            },
            '.component-list-switch .switch:last-child': {
                'border-top-right-radius': '.5rem',
                'border-bottom-right-radius': '.5rem'
            },
            '.components': {
                position: 'absolute',
                top: '4rem',
                bottom: 0,
                left: 0,
                width: '22rem',
                'border-right': '1px solid #ccc'
            },
            '.components .search': {
                padding: '.4rem .5rem .4rem .5rem',
                'border-bottom': '1px dashed #ccc'
            },
            '.components .search input': {
                width: 'calc(100% - 2.5rem)',
                'padding-left': '1.75rem',
                'background-image': 'url(./img/magnifier_gray.svg)',
                'background-position': '5px center',
                'background-repeat': 'no-repeat',
                'background-size': '14px 14px'
            },
            '.component-tab': {
                position: 'absolute',
                top: '3.5rem',
                bottom: 0,
                left: 0,
                right: 0,
                overflow: 'auto'
            },
            '.component-list li': {
                padding: '1rem',
                display: 'flex',
                gap: '1rem'
            },
            '.component-list li .icon-wrapper': {
                width: '2.5rem',
                height: '2.5rem',
                'background-color': '#f1f1f1',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                'border-radius': '.5rem',
                flex: '0 0 2.5rem'
            },
            '.component-list li .icon-wrapper img': {
                width: '1rem',
                'max-height': '1rem'
            },
            '.component-list li .text-wrapper': {
                'max-width': '15rem',
                overflow: 'hidden',
                'text-overflow': 'ellipsis'
            },
            '.component-list li[data-type="signature"] .icon-wrapper img': {
                width: '2rem',
            },
            '.component-list li .description': {
                margin: 0,
                color: '#666'
            },
            '.section-navigation': {
                position: 'absolute',
                top: 0,
                right: 0,
                left: '22rem',
                height: '4rem',
                'background-color': '#eee',
                display: 'flex',
                'align-items': 'flex-end',
                padding: '0 1rem',
                'border-bottom': '1px solid #ccc',
                'border-left': '1px solid #ccc'
            },
            '.canvas-toolbar .button-wrapper': {
                display: 'flex',
                'align-items': 'center',
            },
            '.section-wrapper': {
                position: 'absolute',
                top: 'calc(3rem + 15px)',
                right: '20rem',
                bottom: 0,
                left: '22rem',
                overflow: 'hidden',
                'background-color': '#f1f1f1',
                'border-left': '1px solid #ccc'
            },
            '.section-wrapper .section-toolbar': {
                display: 'flex',
                'align-items': 'center',
                height: '3rem',
                'border-bottom': '1px solid #ddd',
                padding: '0 1rem',
                'background-color': '#fff'
            },
            '.section-wrapper .section-toolbar .add-page .icon': {
                display: 'flex',
                'justify-content': 'center',
                padding: 'none'
            },
            '.section-wrapper .section-toolbar .add-page img': {
                width: '1.2rem'
            },
            '.section-wrapper .canvas-wrapper': {
                position: 'absolute',
                top: '3rem',
                right: 0,
                bottom: 0,
                left: 0
            },
            '.section-wrapper .canvas-wrapper .horizontal-ruler': {
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                height: '2rem',
                padding: '0px 1.5rem 0px 3rem',
                'background-color': '#fff',
                'border-bottom': '1px solid #ccc',
                display: 'flex',
                'align-items': 'flex-end'
            },
            '.section-wrapper .canvas-wrapper .pages': {
                position: 'absolute',
                top: '3.25rem',
                right: 0,
                bottom: 0,
                left: 0,
                padding: '2rem 0'
            },
            '.component-settings': {
                position: 'absolute',
                right: 0,
                bottom: 0,
                top: 'calc(15px + 3rem)',
                width: '18rem',
                'border-left': '1px solid #ddd',
                overflow: 'auto',
                padding: '1rem'
            }
        };

        global.PopupBase.call(this, options);

        /**
         * @type {{Groups: ComponentGroup[]}}
         */
        this.PredefinedComponents = null;

        /**
         * @type {{Groups: ComponentGroup[]}}
         */
        this.CheckpointComponents = null;

        initPredefinedComponents.call(this);
        initCheckpointComponents.call(this);

        /**
         * Cache der Gesamtdaten der aktuellen Editor-Instanz
         * @type {Section[]}
         */
        this.Sections = prepareRawSections.call(this, options.Sections) || createDefaultSection.call(this);

        /**
         * Gibt an, welche Kategorie an Komponenten in der Seitenleiste aktuell angezeigt wird
         * @type {string}
         */
        this.CurrentComponentTab = 'predefined';

        /**
         * Cache des aktuell ausgewählten Bereichs
         * @type {Section}
         */
        this.CurrentSection = this.Sections[0];
        this.CurrentSection.SetIsActive(true);

        /**
         * Cache der aktuell ausgewählten Komponente
         * @type {BaseComponent|null}
         */
        this.ActiveComponent = null;

        /**
         * Stellt einen Cache für die Interact-Events des Fensters zur Verfügung
         */
        this.Interacts = {
            prototypes: {
                draggable: null,
                droppable: null
            },
            pages: {
                generic: null,
                lineComponents: null,
                tableComponents: null,
                tableCellComponents: null
            },
            header: {
                resizable: null
            },
            footer: {
                resizable: null
            }
        };

        /**
         * Gibt an, welcher Seitenbereich aktuell bearbeitet werden soll
         * @type {string}
         */
        this.SectionModificationArea = global.PdfDesigner.Model.Enums.SectionModificationArea.Page;
    };

    PdfDesigner.prototype = Object.create(global.PopupBase.prototype, {
        constructor: PdfDesigner
    });

    PdfDesigner.prototype.CreateContentMarkup = function () {
        return [
            '<div class="component-list-switch">',
                `<div class="switch active" data-tab="predefined">${i18next.t('changeMode.pdfDesigner.components.tabs.predefined')}</div>`,
                `<div class="switch" data-tab="checkpoints">${i18next.t('changeMode.pdfDesigner.components.tabs.checkpoints')}</div>`,
            '</div>',
            '<div class="components">',
                '<div class="search">',
                    `<input type="search" placeholder="${i18next.t('changeMode.pdfDesigner.components.search.placeholder')}">`,
                '</div>',
                '<div class="component-tab" data-tab="predefined">',
                    this.PredefinedComponents.Groups
                        .map(group => group.RenderInSelection())
                        .join(''),
                '</div>',
                '<div class="component-tab hide" data-tab="checkpoints">',
                    this.CheckpointComponents.Groups
                        .map(group => group.RenderInSelection())
                        .join(''),
                '</div>',
            '</div>',
            '<div class="section-navigation">',
                '<ul class="tab-view-tabs tab-view nav">',
                    createSectionSelectionMarkup.call(this),
                '</ul>',
            '</div>',
            '<div class="section-wrapper">',
                '<div class="section-toolbar">',
                    '<div class="multi-button-selection">',
                        `<span class="multi-button-selection-button button button-steal" data-modification-area="header" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.editHeader')}">`,
                            '<img src="./img/header.svg" />',
                        '</span>',
                        `<span class="multi-button-selection-button button button-steal active" data-modification-area="page" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.editPage')}">`,
                            '<img src="./img/paper.svg">',
                        '</span>',
                        `<span class="multi-button-selection-button button button-steal" data-modification-area="footer" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.editFooter')}">`,
                            '<img src="./img/footer.svg">',
                        '</span>',
                    '</div>',
                    '<div class="vertical-border"></div>',
                    `<div class="button-steal with-icon toggle-grid-lines" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.toggleGridLines')}">`,
                        '<div class="icon"><img src="./img/grid-lines.svg"></div>',
                        `<div class="caption">${i18next.t('changeMode.pdfDesigner.sectionToolbar.toggleGridLines')}</div>`,
                    '</div>',
                    '<div class="flex-dummy"></div>',
                    `<div class="button-steal with-icon add-page" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.newPage')}">`,
                        '<div class="icon"><img src="./img/paper.svg"></div>',
                        `<div class="caption">+ ${i18next.t('changeMode.pdfDesigner.sectionToolbar.newPage')}</div>`,
                    '</div>',
                '</div>',
                '<div class="canvas-wrapper page-active">',
                    `<div class="horizontal-ruler">${global.PdfDesigner.Tools.CreateRulerMarkup(21)}</div>`,
                    '<div class="pages">',
                        this.CurrentSection.Render(),
                    '</div>',
                '</div>',
            '</div>',
            '<div class="component-settings"></div>'
        ].join('');
    };

    PdfDesigner.prototype.BindAdditionalEvents = function () {
        const $navigation = this.$content.find('.section-navigation');
        const $toolbar = this.$content.find('.section-toolbar');

        this.$content.find('.switch[data-tab]').on('click', $.proxy(onSwitchTab, this));
        this.$content.find('.search input').on('input', $.proxy(onSearchInput, this));
        this.$content.find('.section-header').on('click', $.proxy(onComponentsHeaderClick, this));
        $navigation.on('click', 'li[data-idx]', $.proxy(onSelectSectionClick, this));
        $navigation.on('click', 'li[data-action="new-section"]', $.proxy(onAddSectionClick, this));
        $toolbar.on('click', '.button[data-modification-area]', $.proxy(onSelectSectionModificationArea, this));
        $toolbar.on('click', '.toggle-grid-lines', $.proxy(onToggleGridLines, this));
        $toolbar.on('click', '.add-page', $.proxy(onAddPageClick, this));
        this.$content.find('.section-wrapper').on('dblclick', '.page-header, .main-area, .page-footer', $.proxy(onPageDoubleClick, this));
        $(document.documentElement).on('keydown.pdfDesignerKeydown', $.proxy(onDocumentKeyDown, this));

        initPrototypeInteractEvents.call(this);
        initPageInteractEvents.call(this);
        initMarginalInteractEvents.call(this);
    };

    PdfDesigner.prototype.OnAfterRendered = function () {
        renderPages.call(this);

        initScrollbar(this.$content.find('.component-tab'));
        initScrollbar(getPagesContainer.call(this));

        for (const [_, component] of Object.entries(this.PredefinedComponentsMap)) {
            component.SetEventHandlers(this, [
                {
                    EventType: 'modificationArea.changed',
                    Handler: $.proxy(onHandleModificationAreaChange, this)
                }
            ]);
        }

        this.$content.trigger('modificationArea.changed');

        let pdfDesignerContext = this;

        this.$content.ContextMenu({
            selector: '.page, table th, table td, table .table-cell-component',
            items: [{
                type: 1,
                title: i18next.t('changeMode.pdfDesigner.pages.removePage.contextMenu'),
                disabled: () => {
                    return this.CurrentSection.Segments.length === 1;
                },
                click: () => {
                    const $page = $(contextmenu.element);
                    const options = {
                        title: i18next.t('changeMode.pdfDesigner.pages.removePage.messageBox.title'),
                        text: i18next.t('changeMode.pdfDesigner.pages.removePage.messageBox.text'),
                        yes: true,
                        onYes: () => removePage.call(this, $page.data('page-no')),
                        no: true
                    };

                    Tools.Message.Show(options);
                }
            }, {
                type: 1,
                title: i18next.t('changeMode.pdfDesigner.sections.removeSection.contextMenu'),
                disabled: () => {
                    return this.Sections.length === 1;
                },
                click: () => {
                    const options = {
                        title: i18next.t('changeMode.pdfDesigner.sections.removeSection.messageBox.title'),
                        text: i18next.t('changeMode.pdfDesigner.sections.removeSection.messageBox.text'),
                        yes: true,
                        onYes: () => removeSection.call(this),
                        no: true
                    };

                    Tools.Message.Show(options);
                }
            },
            {
                type: 1,
                title: i18next.t('changeMode.pdfDesigner.settings.table.addRow'),
                key: 'add-row',
                visible: isTableActionVisible,
                disabled: ($target) => {
                    return isTableActionDisabled($target) || isTableActionDisabledWithSubsampleCheck($target);
                },
                click: onTableActionsContextMenuClick
            }, {
                type: 1,
                title: i18next.t('changeMode.pdfDesigner.settings.table.removeRow'),
                key: 'delete-row',
                visible: isTableActionVisible,
                disabled: isTableActionDisabledWithAllConditions,
                click: onTableActionsContextMenuClick
            }, {
                type: 1,
                title: i18next.t('changeMode.pdfDesigner.settings.table.addColumnLeft'),
                key: 'add-column-left',
                visible: isTableActionVisible,
                disabled: isTableActionDisabled,
                click: onTableActionsContextMenuClick
            }, {
                type: 1,
                title: i18next.t('changeMode.pdfDesigner.settings.table.addColumnRight'),
                key: 'add-column-right',
                visible: isTableActionVisible,
                disabled: isTableActionDisabled,
                click: onTableActionsContextMenuClick
            }, {
                type: 1,
                title: i18next.t('changeMode.pdfDesigner.settings.table.removeColumn'),
                key: 'delete-column',
                visible: isTableActionVisible,
                disabled: isTableActionDisabled,
                click: onTableActionsContextMenuClick
            }]
        });

        function isTableActionDisabled($target) {
            return !$target.hasClass('table-cell-component') && ($target[0].tagName !== 'TH' && $target[0].tagName !== 'TD');
        }

        function isTableActionDisabledWithHeaderCheck($target) {
            return $target.parent()[0].tagName === 'TH' ||
                $target[0].tagName === 'TH';
        }

        function isTableActionDisabledWithSubsampleCheck($target) {
            return $target.parents('.subsample-table').length > 0;
        }

        function isTableActionDisabledWithAllConditions($target) {
            return isTableActionDisabled($target) ||
                isTableActionDisabledWithHeaderCheck($target) ||
                isTableActionDisabledWithSubsampleCheck($target);
        }

        function isTableActionVisible($target) {
            return !isTableActionDisabled($target);
        }

        function onTableActionsContextMenuClick(evt) {
            const key = $(evt.currentTarget).data('key');

            let $target = $(this.currentTarget);

            if ($target[0].tagName === 'TH' || $target[0].tagName === 'TD') {
                $target = $target.children('.table-cell-component').eq(0);
            }

            const $tableComponent = $target.closest('.table-component');
            const $tableCell = $target.parent();
            const tableCellElement = $tableCell[0];
            const $tableRow = $tableCell.closest('tr');
            const isHeader = tableCellElement.tagName === 'TH';

            const tableIdentifier = $tableComponent.data('identifier');

            const $page = $tableComponent.parents('.canvas.page');
            const pageNumber = $page.data('page-no') || 1;

            const page = pdfDesignerContext.CurrentSection.GetPage(pageNumber - 1);

            const tableComponent = page.GetComponent(tableIdentifier);

            const row = isHeader ? 0 : $tableRow.index();
            const column = $tableCell.index();

            const currentTableCells = tableComponent.IsSubsampleTable ? tableComponent.CellProvider.TemplateCells : tableComponent.CellProvider.Cells;
            const columnsCount = tableComponent.IsSubsampleTable ? currentTableCells.length : currentTableCells[0].length;

            switch (key) {
                case global.PdfDesigner.Model.Enums.TableActions.AddRow:
                    if (tableComponent.IsSubsampleTable) {
                        return;
                    }

                    const newItems = [];
                    const newRow = isHeader ? 0 : row + 1;

                    for (let i = 0; i < columnsCount; i++) {
                        newItems.push(tableComponent.CreateTableCell({
                            Row: newRow,
                            Column: i,
                            IsHeader: false,
                            Width: tableComponent.ColumnSizes[i] || 4,
                            Height: 2,
                        }));
                    }

                    tableComponent.CellProvider.Cells.splice(newRow, 0, newItems);

                    break;
                case global.PdfDesigner.Model.Enums.TableActions.DeleteRow:
                    if (tableComponent.IsSubsampleTable || isHeader || tableComponent.CellProvider.Cells.length === 1) {
                        return;
                    }

                    tableComponent.CellProvider.Cells.splice(row, 1);

                    break;
                case global.PdfDesigner.Model.Enums.TableActions.AddColumnRight:
                case global.PdfDesigner.Model.Enums.TableActions.AddColumnLeft:
                    const addColumn = key === global.PdfDesigner.Model.Enums.TableActions.AddColumnRight ? 1 : 0;

                    for (let i = 0; i < tableComponent.HeaderCellProvider.Cells.length; i++) {
                        const cellRow = tableComponent.HeaderCellProvider.Cells[i];

                        cellRow.splice(column + addColumn, 0, tableComponent.CreateTableCell({
                            Column: column + addColumn,
                            Row: i,
                            IsHeader: true,
                            BackgroundColor: '#cccccc'
                        }));
                    }

                    if (tableComponent.IsSubsampleTable) {
                        tableComponent.CellProvider.TemplateCells.splice(column + addColumn, 0, tableComponent.CreateTableCell({
                            Column: column + addColumn,
                            IsTemplate: true
                        }));
                        break;
                    }

                    for (let i = 0; i < tableComponent.CellProvider.Cells.length; i++) {
                        const cellRow = tableComponent.CellProvider.Cells[i];

                        cellRow.splice(column + addColumn, 0, tableComponent.CreateTableCell({
                            Column: column + addColumn,
                            Row: i
                        }));
                    }

                    break;
                case global.PdfDesigner.Model.Enums.TableActions.DeleteColumn:
                    if (tableComponent.HeaderCellProvider.Cells[0].length <= 1) {
                        // Spalte nicht entfernen, wenn nur eine existiert
                        return;
                    }

                    for (let i = 0; i < tableComponent.HeaderCellProvider.Cells.length; i++) {
                        const cellRow = tableComponent.HeaderCellProvider.Cells[i];

                        cellRow.splice(column, 1);
                    }

                    if (tableComponent.IsSubsampleTable) {
                        tableComponent.CellProvider.TemplateCells.splice(column, 1);
                        break;
                    }

                    for (let i = 0; i < tableComponent.CellProvider.Cells.length; i++) {
                        const cellRow = tableComponent.CellProvider.Cells[i];

                        cellRow.splice(column, 1);
                    }

                    break;
            }

            tableComponent.RenderOnPage();
            tableComponent.OnAfterTableResized(page);
        }
    };

    PdfDesigner.prototype.OnResize = function () {
        this.Pages.forEach(page => page.DetermineDimensions());
    };

    PdfDesigner.prototype.OnAfterClosed = function () {
        $(document.documentElement).off('keydown.pdfDesignerKeydown');
        unsetInteractEvents.call(this);
    };

    /**
     * Bereitet rohe Designer-Daten für die Verwendung innerhalb des Editors vor
     * @param {*[]} sections
     * @return {Section[]|null}
     */
    function prepareRawSections(sections) {
        if (!(sections instanceof Array) || !sections.length) {
            return null;
        }

        const pageSettings = getDefaultPageSettings.call(this);
        const marginalSettings = getDefaultMarginalSettings.call(this);
        const sectionSettings = getDefaultSectionSettings();
        const componentFactory = new global.PdfDesigner.Model.ComponentFactory(
            this.CheckpointMap,
            this.options.Fonts
        );

        /**
         * @type {Section[]}
         */
        const preparedSections = [];

        for (let sCnt = 0; sCnt < sections.length; sCnt++) {
            const section = sections[sCnt];
            const header = new global.PdfDesigner.Model.Marginal(
                marginalSettings,
                section.Header ? section.Header.Height : 10,
                global.PdfDesigner.Model.Enums.SectionModificationArea.Header,
                section.Header ? section.Header.Content : null,
                componentFactory
            );

            const footer = new global.PdfDesigner.Model.Marginal(
                marginalSettings,
                section.Footer ? section.Footer.Height : 10,
                global.PdfDesigner.Model.Enums.SectionModificationArea.Footer,
                section.Footer ? section.Footer.Content : null,
                componentFactory
            );

            preparedSections.push(
                new global.PdfDesigner.Model.Section(
                    sectionSettings,
                    pageSettings,
                    header,
                    footer,
                    section.Segments,
                    componentFactory
                )
            );
        }

        return preparedSections;
    }

    /**
     * Stellt Standardeinstellungen für Bereiche zur Verfügung
     * @return {SectionSettings}
     */
    function getDefaultSectionSettings() {
        return new global.PdfDesigner.Model.SectionSettings(
            global.PdfDesigner.Model.PageFormat.A4,
            global.PdfDesigner.Model.PageOrientation.Portrait
        );
    }

    /**
     * Stell Standardeinstellungen für Kopf-/Fußzeilen zur Verfügung
     * @return {MarginalSettings}
     */
    function getDefaultMarginalSettings() {
        return new global.PdfDesigner.Model.MarginalSettings(
            '#' + this.options.ID,
            '.component-settings',
            $.proxy(onPageClick, this),
            $.proxy(onPageComponentClick, this),
            $.proxy(onMarginalComponentEdit, this),
            $.proxy(onPageComponentRemove, this)
        );
    }

    /**
     * Stellt Standardeinstellungen für Seiten zur Verfügung
     * @return {PageSettings}
     */
    function getDefaultPageSettings() {
        return new global.PdfDesigner.Model.PageSettings(
            '#' + this.options.ID,
            '.component-settings',
            $.proxy(onPageClick, this),
            $.proxy(onPageComponentClick, this),
            $.proxy(onPageComponentRemove, this)
        );
    }

    /**
     * Erstellt einen Standardbereich
     * @return {Section}
     */
    function createDefaultSection() {
        const sectionSettings = getDefaultSectionSettings();
        const pageSettings = getDefaultPageSettings.call(this);
        const marginalSettings = getDefaultMarginalSettings.call(this);
        const page = new global.PdfDesigner.Model.Page(pageSettings, 1);

        return [
            new global.PdfDesigner.Model.Section(
                sectionSettings,
                pageSettings,
                new global.PdfDesigner.Model.Marginal(
                    marginalSettings,
                    10,
                    global.PdfDesigner.Model.Enums.SectionModificationArea.Header
                ),
                new global.PdfDesigner.Model.Marginal(
                    marginalSettings,
                    10,
                    global.PdfDesigner.Model.Enums.SectionModificationArea.Footer
                ),
                [page]
            )
        ];
    }

    /**
     * Initialisiert die vom System vorgegebenen Komponenten
     */
    function initPredefinedComponents() {
        const defaultSettings = {
            IsTemplate: true,
            Fonts: this.options.Fonts
        };

        /**
         * @type {BaseComponent[]}
         */
        const staticComponents = [
            new global.PdfDesigner.Model.TextComponent(
                i18next.t('changeMode.pdfDesigner.components.static.textField.label'),
                i18next.t('changeMode.pdfDesigner.components.static.textField.description'),
                new global.PdfDesigner.Model.ComponentSettings(defaultSettings)
            ),
            new global.PdfDesigner.Model.CataloguePictureComponent(
                i18next.t('changeMode.pdfDesigner.components.static.cataloguePicture.label'),
                i18next.t('changeMode.pdfDesigner.components.static.cataloguePicture.description'),
                new global.PdfDesigner.Model.ComponentSettings(defaultSettings)
            ),
            new global.PdfDesigner.Model.TableComponent(
                i18next.t('changeMode.pdfDesigner.components.static.table.label'),
                i18next.t('changeMode.pdfDesigner.components.static.table.description'),
                new global.PdfDesigner.Model.ComponentSettings({
                    ...defaultSettings,
                    DisabledInModificationArea: [
                        global.PdfDesigner.Model.Enums.SectionModificationArea.Header,
                        global.PdfDesigner.Model.Enums.SectionModificationArea.Footer
                    ]
                })
            ),
            new global.PdfDesigner.Model.RectangleComponent(
                i18next.t('changeMode.pdfDesigner.components.static.rectangle.label'),
                i18next.t('changeMode.pdfDesigner.components.static.rectangle.description'),
                new global.PdfDesigner.Model.ComponentSettings(defaultSettings)
            ),
            new global.PdfDesigner.Model.LineComponent(
                i18next.t('changeMode.pdfDesigner.components.static.line.label'),
                i18next.t('changeMode.pdfDesigner.components.static.line.description'),
                new global.PdfDesigner.Model.ComponentSettings(defaultSettings)
            )
        ];

        /**
         * @type {BaseComponent[]}
         */
        const documentInformation = [
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageNumber.label'),
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageNumber.description'),
                new global.PdfDesigner.Model.ComponentSettings({
                    ...defaultSettings,
                    Placeholder: '{{Page}}',
                    DisabledInModificationArea: [global.PdfDesigner.Model.Enums.SectionModificationArea.Page]
                }),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageCount.label'),
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageCount.description'),
                new global.PdfDesigner.Model.ComponentSettings({
                    ...defaultSettings,
                    Placeholder: '{{MaxPage}}',
                    DisabledInModificationArea: [global.PdfDesigner.Model.Enums.SectionModificationArea.Page]
                }),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.creationDate'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{CreationDate}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.creationTime'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{CreationTime}}'}),
                'clock-regular.svg'
            )
        ];

        /**
         * @type {BaseComponent[]}
         */
        const issueInformationComponents = [
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.id'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueId}}'}),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.revision'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueRevision}}'}),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.type'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueType}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.title'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.description'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueDescription}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.creatorName'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueCreatorName}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.creationTimestamp'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueCreationTimestamp}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.editorName'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueEditorName}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.modificationTimestamp'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueModificationTimestamp}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.priority'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssuePriorityTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.state'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueStateTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.deadlineTimestamp'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueDeadlineTimestamp}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.ouTitle'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueLocationTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.formTitle'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({...defaultSettings, Placeholder: '{{IssueFormTitle}}'}),
                'font-solid.svg'
            )
        ];

        this.PredefinedComponentsMap = {};

        staticComponents.forEach(c => this.PredefinedComponentsMap[c.OID] = c);
        issueInformationComponents.forEach(c => this.PredefinedComponentsMap[c.OID] = c);
        documentInformation.forEach(c => this.PredefinedComponentsMap[c.OID] = c);

        /**
         * @type {{Groups: ComponentGroup[]}}
         */
        this.PredefinedComponents = {
            Groups: [
                new global.PdfDesigner.Model.ComponentGroup(
                    'static',
                    i18next.t('changeMode.pdfDesigner.components.static.label'),
                    staticComponents
                ),
                new global.PdfDesigner.Model.ComponentGroup(
                    'document-information',
                    i18next.t('changeMode.pdfDesigner.components.documentInformation.label'),
                    documentInformation
                ),
                new global.PdfDesigner.Model.ComponentGroup(
                    'issue-information',
                    i18next.t('changeMode.pdfDesigner.components.issueInformation.label'),
                    issueInformationComponents
                )
            ]
        };
    }

    /**
     * Initialisiert die Prüfpunkt-Komponenten abhängig vom gewählten Formular
     */
    function initCheckpointComponents() {
        this.CheckpointComponents = { Groups: [] };
        this.CheckpointComponentsMap = {};
        this.CheckpointMap = {};

        if (!this.options.Form || !(this.options.Form.Parametergroups || []).length) {
            return;
        }

        this.options.Form.Parametergroups
            .forEach(function (group) {
                if (!group.Enabled || !(group.Parameters || []).length) {
                    return;
                }

                const components = [];

                group.Parameters.forEach(function (checkpoint) {
                    if (!checkpoint.Enabled) {
                        return;
                    }

                    if (checkpoint.Type === Enums.elementType.EMailAddresses && !Tools.IsEMailCpEnabled()) {
                        return;
                    }

                    if (checkpoint.Type === Enums.elementType.Print) {
                        return;
                    }

                    const component =
                        createComponentFromCheckpoint.call(this, checkpoint, group.Type === Enums.elementType.SubsampleFormRow);

                    if (!component) {
                        return;
                    }

                    components.push(component);

                    this.CheckpointMap[checkpoint.OID] = checkpoint;
                    this.CheckpointComponentsMap[component.OID] = component;
                }, this);

                if (components.length) {
                    const componentGroup = new global.PdfDesigner.Model.ComponentGroup(
                        group.OID,
                        group.Title,
                        components
                    );

                    this.CheckpointComponents.Groups.push(componentGroup);
                }
            }, this);
    }

    /**
     * @param {object} checkpoint
     * @param {boolean} isSubsampleCheckpoint
     * @return {CheckpointBaseComponent|null}
     */
    function createComponentFromCheckpoint(checkpoint, isSubsampleCheckpoint) {
        if (!checkpoint) {
            return null;
        }

        const componentSettings = new global.PdfDesigner.Model.ComponentSettings({
            IsTemplate: true,
            OID: uuid(),
            Fonts: this.options.Fonts,
            IsSubsampleCheckpoint: isSubsampleCheckpoint
        });

        switch (checkpoint.Type) {
            case Enums.elementType.Checkbox:
                return new global.PdfDesigner.Model.CheckboxCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Number:
                return new global.PdfDesigner.Model.NumberCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Line:
                return new global.PdfDesigner.Model.LineCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Memo:
                return new global.PdfDesigner.Model.MemoCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Date:
                return new global.PdfDesigner.Model.DateCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Time:
                return new global.PdfDesigner.Model.TimeCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Photo:
                return new global.PdfDesigner.Model.PhotoCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Scancode:
                return new global.PdfDesigner.Model.ScanCodeCpComponent(checkpoint, componentSettings);
            case Enums.elementType.LocationCode:
                return new global.PdfDesigner.Model.LocationCodeCpComponent(checkpoint, componentSettings);
            case Enums.elementType.ListBox:
                return new global.PdfDesigner.Model.ListBoxCpComponent(checkpoint, componentSettings);
            case Enums.elementType.MultiListBox:
                return new global.PdfDesigner.Model.MultiListBoxCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Info:
                return new global.PdfDesigner.Model.InfoCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Signature:
                return new global.PdfDesigner.Model.SignatureCpComponent(checkpoint, componentSettings);
            case Enums.elementType.UsersAndTeams:
                return new global.PdfDesigner.Model.UserAndTeamsCpComponent(checkpoint, componentSettings);
            case Enums.elementType.IndividualData:
                return new global.PdfDesigner.Model.IndividualDataCpComponent(checkpoint, componentSettings);
            case Enums.elementType.PhoneNumber:
                return new global.PdfDesigner.Model.PhoneNumberCpComponent(checkpoint, componentSettings);
            case Enums.elementType.EMailAddresses:
                return new global.PdfDesigner.Model.EMailAddressesCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Files:
                return new global.PdfDesigner.Model.FilesCpComponent(checkpoint, componentSettings);
        }
    }

    /**
     * Handelt das PageClick-Event außerhalb von Komponenten
     */
    function onPageClick() {
        unselectActiveComponent.call(this);
    }

    /**
     * Verarbeitet das Doppelklick-Event auf die Seiten
     * @param {MouseEvent} evt
     */
    function onPageDoubleClick(evt) {
        const $target = $(evt.currentTarget);

        /**
         * @type {string}
         */
        let newModificationArea;

        if ($target.hasClass('page-header')) {
            newModificationArea = global.PdfDesigner.Model.Enums.SectionModificationArea.Header;
        } else if ($target.hasClass('main-area')) {
            newModificationArea = global.PdfDesigner.Model.Enums.SectionModificationArea.Page;
        } else if ($target.hasClass('page-footer')) {
            newModificationArea = global.PdfDesigner.Model.Enums.SectionModificationArea.Footer;
        }

        changeModificationArea.call(this, newModificationArea);
    }

    /**
     * Handelt das Click-Event für Komponenten auf Seiten und wird ausgeführt, nachdem Komponenten-interne Anweisungen abgearbeitet wurden
     * @param {BaseComponent} component
     */
    function onPageComponentClick(component) {
        if (this.ActiveComponent) {
            this.ActiveComponent.UnSelect();
        }

        this.ActiveComponent = component;

        initScrollbar(this.$content.find('.component-settings'));
    }

    /**
     * Handelt das Bearbeiten von Komponenten in Kopf-/Fußzeilen
     * @param {BaseComponent} component
     */
    function onMarginalComponentEdit(component) {
        if (!component) {
            return;
        }

        refreshMarginalComponent.call(this, component);
    }

    /**
     * Handelt das Löschen von Page-Komponenten
     */
    function onPageComponentRemove() {
        unselectActiveComponent.call(this);
    }

    /**
     * Handelt das Ändern des Bearbeitungsbereichs für Komponenten
     * @param {BaseComponent} prototypeComponent
     */
    function onHandleModificationAreaChange(prototypeComponent) {
        if (!prototypeComponent) {
            return;
        }

        const $prototypeNode = $(`#${prototypeComponent.OID}`);

        if (!$prototypeNode.length) {
            return;
        }

        if ((prototypeComponent.Settings.DisabledInModificationArea || []).indexOf(this.SectionModificationArea) === -1) {
            $prototypeNode.removeClass('disabled');
            return;
        }

        $prototypeNode.addClass('disabled');
    }

    /**
     * @param {KeyboardEvent} evt
     */
    function onDocumentKeyDown(evt) {
        if (!this.ActiveComponent || this.ActiveComponent.SettingsFocused) {
            return;
        }

        if (evt.key === 'Delete') {
            handleDeleteComponentEvent.call(this, evt);
            return;
        }

        if (evt.key.indexOf('Arrow') > -1) {
            handleMoveComponentEvent.call(this, evt);
        }
    }

    /**
     * Behandelt das Event zum Löschen von Komponenten
     * @param {KeyboardEvent} evt
     */
    function handleDeleteComponentEvent(evt) {
        evt.preventDefault();

        if (!!this.ActiveComponent.IsTableCell) {
            return;
        }

        const options = {
            title: i18next.t('changeMode.pdfDesigner.components.deleteComponent.title'),
            text: i18next.t('changeMode.pdfDesigner.components.deleteComponent.text'),
            onYes: () => {
                switch (this.ActiveComponent.RenderArea) {
                    case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                    case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                        this.ActiveComponent.Marginal.RemoveComponent(this.ActiveComponent.OID);
                        break;
                    case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                        this.ActiveComponent.Page.RemoveComponent(this.ActiveComponent.OID);
                        break;
                }
            },
            no: true
        };

        Tools.Message.Show(options);
    }

    /**
     * Behandelt das Event zum Verschieben von Komponenten
     * @param {KeyboardEvent} evt
     */
    function handleMoveComponentEvent(evt) {
        evt.preventDefault();

        /**
         * @type {'up'|'right'|'down'|'left'}
         */
        const direction = evt.key.substring(5).toLowerCase();
        const pxPerMm = global.PdfDesigner.Tools.CalculatePixelsPerMillimeter();
        const pageNo = this.ActiveComponent.$node.closest('.page').data('page-no');
        const page = this.CurrentSection.GetPage(pageNo - 1);

        let moveBy;

        switch (direction) {
            case 'up':
            case 'down':
                moveBy = pxPerMm / page.Height * 20;
                break;
            case 'left':
            case 'right':
                moveBy = pxPerMm / page.Width * 20;
                break;
        }

        const distance = evt.shiftKey ? (moveBy * 5) : moveBy;

        this.ActiveComponent.Move(direction, distance);
    }

    /**
     * Deaktiviert die Selektion der aktuell aktiven Komponente
     */
    function unselectActiveComponent() {
        if (this.ActiveComponent) {
            this.ActiveComponent.UnSelect();
        }

        this.ActiveComponent = null;
        this.$content.find('.component-settings').empty();
        this.$content.find('.table-component').removeClass('active');
    }

    /**
     * Erstellt das Markup für die Bereichs-Navigation
     * @return {string}
     */
    function createSectionSelectionMarkup() {
        const markup = this.Sections
            .map((_, idx) => {
                return `<li data-idx="${idx}"${idx === 0 ? ' class="active"' : ''}>${idx + 1}</li>`;
            });

        markup.push(`<li data-action="new-section">+ ${i18next.t('changeMode.pdfDesigner.sections.newSection')}</li>`);

        return markup.join('');
    }

    /**
     * Rendert alle Seiten des aktuell ausgewählten Bereichs
     */
    function renderPages() {
        // Seiten positionieren
        this.$content.find('.canvas').toArray().forEach(function (page) {
            const $page = $(page);
            const pageNo = $page.data('page-no');
            let top = (pageNo > 1 ? 25 : 0) + (1122 * (pageNo - 1));

            if (pageNo > 1) {
                top += (pageNo - 1) * 25;
            }

            $page.css('top', top + 'px');
        });

        // Content-Bereiche erstellen und Main-Komponenten rendern
        this.CurrentSection.Segments.forEach(page => {
            page.DetermineDimensions()
                .RenderHeader()
                .RenderMainArea()
                .RenderFooter()
                .PlaceComponents()
                .BindEvents();
        });

        // Kopfzeilen rendern
        (this.CurrentSection.Header?.Content || []).forEach(component => {
            this.CurrentSection.Segments.forEach(page =>
                component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Header)
            );
        }, this);

        // Fußzeilen rendern
        (this.CurrentSection.Footer?.Content || []).forEach(component => {
            this.CurrentSection.Segments.forEach(page =>
                component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Footer)
            );
        }, this);

        this.CurrentSection.SetShowGridLines(this.CurrentSection.GetShowGridLines(), this.SectionModificationArea);
    }

    /**
     * Initialisiert eine OverlayScrollbars-Instanz für den angegebenen Container
     * @param {$|HTMLElement} container
     * @param {number?} yScrollPosition
     * @param {Function?} onScroll
     */
    function initScrollbar(container, yScrollPosition, onScroll) {
        if ((container instanceof $)) {
            container = container[0];
        }

        let instance = OverlayScrollbarsGlobal.OverlayScrollbars(container);

        if (instance) {
            instance.destroy();
        }

        const scrollbarOptions = {
            scrollbars: {
                theme: 'os-theme-dark',
                autoHide: 'never'
            }
        };

        if (onScroll instanceof Function) {
            scrollbarOptions.callbacks = {
                onScroll: onScroll
            };
        }

        instance = OverlayScrollbarsGlobal.OverlayScrollbars(container, scrollbarOptions);

        if (typeof yScrollPosition === 'number' && !isNaN(yScrollPosition)) {
            instance.elements().viewport.scroll({ top: yScrollPosition });
        }
    }

    /**
     * Gibt, sofern vorhanden, eine OverlayScrollbars-Instanz für den angegebenen Container zurück
     * @param {$|HTMLElement} container
     * @return {*}
     */
    function getScrollbar(container) {
        if ((container instanceof $)) {
            container = container[0];
        }

        return OverlayScrollbarsGlobal.OverlayScrollbars(container);
    }

    /**
     * Erstellt, sofern Gitternetzlinien aktiv sind, einen Snap-To-Grid Modifier, welcher beim Bewegen von Komponenten genutzt wird
     * @return {*|null}
     */
    function getInteractGridSnapModifier() {
        if (!this.CurrentSection.ShowGridLines) {
            return null;
        }

        const pxPerMm = global.PdfDesigner.Tools.CalculatePixelsPerMillimeter();
        const target = interact.snappers.grid({
            x: pxPerMm,
            y: pxPerMm
        });

        return interact.modifiers.snap({
            targets: [target],
            relativePoints: [
                { x: 0, y: 0 }
            ],
            offset: 'parent'
        });
    }

    /**
     * Entfernt bestehende Interact-Events und initialisiert diese neu
     */
    function initInteractEvents() {
        unsetInteractEvents.call(this);
        initPrototypeInteractEvents.call(this);
        initPageInteractEvents.call(this);
        initMarginalInteractEvents.call(this);
    }

    /**
     * Resetted alle Interact-Events
     */
    function unsetInteractEvents() {
        this.Interacts.prototypes.draggable.unset();
        this.Interacts.prototypes.droppable.unset();
        this.Interacts.pages.generic.unset();
        this.Interacts.pages.lineComponents.unset();
        this.Interacts.pages.tableComponents.unset();
        this.Interacts.pages.tableCellComponents.unset();
        this.Interacts.header.resizable.unset();
        this.Interacts.footer.resizable.unset();
    }

    /**
     * Erstellt die Interact-Events für Prototyp-Komponenten aus der linken Seitenleiste
     */
    function initPrototypeInteractEvents() {
        let currentComponentPrototype;
        let subsampleComponentPrototype;
        let $prototypeElement;
        let $prototypeMoveElement;
        let $dropZoneElement;

        /**
         * @return {{top: number, left: number, pageNo: *}}
         */
        const getComponentCoordinatesOnPage = () => {
            const pageNo = $dropZoneElement.hasClass('page') ?
                $dropZoneElement.data('page-no') :
                $dropZoneElement.closest('.page').data('page-no');
            const position = $prototypeMoveElement.position();
            const pagePosition = $dropZoneElement.hasClass('page') ?
                $dropZoneElement.position() :
                $dropZoneElement.closest('.page').position();
            const $sectionWrapper = $dropZoneElement.closest('.section-wrapper');
            const sectionWrapperPositionTop = parseFloat($sectionWrapper.css('top'));
            const $pagesContainer = $dropZoneElement.closest('.pages');
            const pagesContainerPositionTop = parseFloat($pagesContainer.css('top'));

            return {
                pageNo: pageNo,
                left: position.left - pagePosition.left + 93,
                top: position.top - pagePosition.top - sectionWrapperPositionTop - pagesContainerPositionTop - parseFloat($pagesContainer.css('padding-top')) - 13
            };
        };

        this.Interacts.prototypes.draggable = interact(`#${this.options.ID} .component-list li`)
            .draggable({
                inertia: false,
                autoScroll: false,
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: '.content',
                        endOnly: true
                    })
                ],
                listeners: {
                    start: (evt) => {
                        $prototypeElement = $(evt.currentTarget);
                        $dropZoneElement = null;

                        if ($prototypeElement.hasClass('disabled')) {
                            $prototypeElement = null;
                            return;
                        }

                        const identifier = $prototypeElement.attr('id');

                        currentComponentPrototype = this.CurrentComponentTab === 'predefined' ?
                            this.PredefinedComponentsMap[identifier] :
                            this.CheckpointComponentsMap[identifier];

                        if (!currentComponentPrototype) {
                            return;
                        }

                        $prototypeElement.addClass('move-active');

                        const moveElementId = this.options.ID + '-prototype-clone';

                        $prototypeMoveElement = currentComponentPrototype.CreateDragElement();
                        $prototypeMoveElement.attr('id', moveElementId);

                        this.$content.append($prototypeMoveElement);

                        const componentsPosition = $prototypeElement.closest('.components').position();

                        $prototypeMoveElement
                            .css({
                                left: evt.clientX - evt.rect.left - 2,
                                top: evt.clientY - componentsPosition.top - parseFloat(this.$content.css('padding-top')) - 25
                            })
                            .data({
                                x: evt.clientX - evt.rect.left - 2,
                                y: evt.clientY - componentsPosition.top - parseFloat(this.$content.css('padding-top')) - 25
                            });
                    },
                    move: (evt) => {
                        if (!($prototypeMoveElement instanceof $)) {
                            return;
                        }

                        const x = ($prototypeMoveElement.data('x') || 0) + evt.dx;
                        const y = ($prototypeMoveElement.data('y') || 0) + evt.dy;

                        $prototypeMoveElement
                            .css({ top: y, left: x })
                            .data({ 'x': x, 'y': y });

                        if (!$dropZoneElement) {
                            return;
                        }

                        const coordinates = getComponentCoordinatesOnPage();

                        setCurrentCursorPositionOnRuler.call(this, false, 'horizontal', null, coordinates.left);
                        setCurrentCursorPositionOnRuler.call(this, false, 'vertical', coordinates.pageNo, coordinates.top);
                    },
                    end: () => {
                        if ($dropZoneElement) {
                            const pageNo = $dropZoneElement.closest('.page').data('page-no');

                            setCurrentCursorPositionOnRuler.call(this, true, 'horizontal');
                            setCurrentCursorPositionOnRuler.call(this, true, 'vertical', pageNo);
                        }

                        if (!($prototypeElement instanceof $)) {
                            return;
                        }

                        $prototypeElement.removeClass('move-active');

                        if (!($prototypeMoveElement instanceof $)) {
                            return;
                        }

                        $prototypeMoveElement.remove();

                        $prototypeElement = null;
                        $prototypeMoveElement = null;
                    }
                }
            });

        let dropTarget = `#${this.options.ID} `;

        function afterPrototypeDroppedActions() {
            if ($dropZoneElement.hasClass('table-cell-component')) {
                $dropZoneElement.removeClass('moving-active');
            }

            currentComponentPrototype = null;
            subsampleComponentPrototype = null;
        }

        switch (this.SectionModificationArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                dropTarget += '.page-header';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                dropTarget += '.main-area, table th, table td, table .table-cell-component';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                dropTarget += '.page-footer';
                break;
        }

        this.Interacts.prototypes.droppable = interact(dropTarget)
            .dropzone({
                accept: '.component-prototype',
                overlap: 'pointer',
                ondragenter: (evt) => {
                    $dropZoneElement = $(evt.target);

                    if ($dropZoneElement.hasClass('table-cell-component')) {
                        if ($prototypeElement.data('type') === global.PdfDesigner.Model.Enums.ComponentType.Table ||
                            $prototypeElement.data('type') === global.PdfDesigner.Model.Enums.ComponentType.Geometry) {
                            return;
                        }

                        $dropZoneElement.addClass('moving-active');
                    }
                },
                ondragleave: () => {
                    const pageNo = $dropZoneElement.closest('.page').data('page-no');

                    setCurrentCursorPositionOnRuler.call(this, true, 'horizontal');
                    setCurrentCursorPositionOnRuler.call(this, true, 'vertical', pageNo);

                    if ($dropZoneElement.hasClass('table-cell-component')) {
                        $dropZoneElement.removeClass('moving-active');
                    }

                    $dropZoneElement = null;
                },
                ondrop: () => {
                    if (!($prototypeMoveElement instanceof $)) {
                        afterPrototypeDroppedActions();
                        return;
                    }

                    const coordinates = getComponentCoordinatesOnPage();
                    const page = this.CurrentSection.GetPage(coordinates.pageNo - 1);

                    const relativeCoordinates = {
                        left: coordinates.left / page.Width * 100,
                        top: coordinates.top / page.Height * 100
                    };

                    const draggedPrototypeType = $prototypeElement.data('type');

                    if ($dropZoneElement.hasClass('table-cell-component')) {
                        if (draggedPrototypeType === global.PdfDesigner.Model.Enums.ComponentType.Table) {
                            afterPrototypeDroppedActions();
                            return;
                        }

                        if (draggedPrototypeType !== global.PdfDesigner.Model.Enums.ComponentType.Geometry) {
                            relativeCoordinates.left = 0;
                            relativeCoordinates.top = 0;
                        }

                        currentComponentPrototype.Settings.IsTableCell = true;
                    }

                    if (currentComponentPrototype.IsSubsampleCheckpoint) {
                        if ($dropZoneElement.hasClass('main-area')) {
                            // Wenn ein Teilproben-Prüfpunkt in den Content gedroppt wird, muss die Tabelle erstellt werden.
                            // Die gedroppte Komponente wird zwischengespeichert
                            subsampleComponentPrototype = currentComponentPrototype;
                            // Definition für eine Tabelle auswählen und anschließend erstellen
                            currentComponentPrototype = Tools.getFirst(Object.values(this.PredefinedComponentsMap),
                                (c) => c.Type === global.PdfDesigner.Model.Enums.ComponentType.Table);

                            if (!currentComponentPrototype) {
                                afterPrototypeDroppedActions();
                                return;
                            }

                            currentComponentPrototype.IsSubsampleTable = true;
                            currentComponentPrototype.Settings.IsTableCell = false;
                        } else {
                            currentComponentPrototype.Settings.IsTableCell = true;
                        }
                    }

                    currentComponentPrototype.CreateDerivedInstance(
                        relativeCoordinates.left,
                        relativeCoordinates.top
                    )
                    .then((newPageComponent) => {
                        // Erstellen einer Teilproben-Tabelle, falls ein Teilproben-Prüfpunkt in den Content-Bereich gedroppt wurde
                        if (subsampleComponentPrototype && subsampleComponentPrototype.IsSubsampleCheckpoint &&
                            $dropZoneElement.hasClass('main-area')) {
                            subsampleComponentPrototype.Settings.IsTableCell = true;
                            subsampleComponentPrototype.CreateDerivedInstance(0, 0)
                                .then((newSubsampleComponent) => {
                                    newSubsampleComponent.IsTableCell = true;
                                    newPageComponent.SetNewSubsampleTableContent(subsampleComponentPrototype.GroupOID, newSubsampleComponent);
                                    page.AddComponent(newPageComponent);

                                    newPageComponent.RenderOnPage();
                                    afterPrototypeDroppedActions();
                                });

                            return;
                        }

                        // Platzieren einer Komponente
                        if (!$dropZoneElement.hasClass('table-cell-component')
                            || draggedPrototypeType === global.PdfDesigner.Model.Enums.ComponentType.Geometry) {
                            switch (this.SectionModificationArea) {
                                case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                                    this.CurrentSection.Header.AddComponent(newPageComponent);
                                    this.CurrentSection.Segments.forEach(page =>
                                        newPageComponent.RenderOnPage(
                                            page,
                                            global.PdfDesigner.Model.Enums.SectionModificationArea.Header
                                        )
                                    );
                                    break;
                                case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                                    page.AddComponent(newPageComponent);
                                    newPageComponent.RenderOnPage(page, this.SectionModificationArea);
                                    break;
                                case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                                    this.CurrentSection.Footer.AddComponent(newPageComponent);
                                    this.CurrentSection.Segments.forEach(page =>
                                        newPageComponent.RenderOnPage(
                                            page,
                                            global.PdfDesigner.Model.Enums.SectionModificationArea.Footer
                                        )
                                    );
                                    break;
                            }

                            afterPrototypeDroppedActions();
                            return;
                        }

                        if (draggedPrototypeType === global.PdfDesigner.Model.Enums.ComponentType.Table) {
                            afterPrototypeDroppedActions();
                            return;
                        }

                        // Komponente in Tabellenzelle einfügen
                        const tableComponent = getParentTableComponent($dropZoneElement, page);

                        if (!tableComponent) {
                            afterPrototypeDroppedActions();
                            return;
                        }

                        const tableCellComponent = tableComponent.GetSelectedComponent($dropZoneElement.data('identifier'));

                        if (!tableCellComponent) {
                            afterPrototypeDroppedActions();
                            return;
                        }

                        const $tableCell = $dropZoneElement.parent();
                        const column = $tableCell.data('column') || 0;
                        const row = $tableCell.data('row') || 0;

                        if (subsampleComponentPrototype) {
                            // Teilproben-Prüfpunkt in Tabelle einfügen
                            const tableContentCell = tableComponent.CellProvider.TemplateCells[column];
                            tableContentCell.Content[0] = newPageComponent;
                        } else if ($tableCell[0].tagName === 'TH') {
                            // Header der Tabelle anpassen
                            if (newPageComponent.IsSubsampleCheckpoint) {
                                // Teilproben-Prüfpunkte dürfen nicht in die Header eingefügt werden
                                afterPrototypeDroppedActions();
                                return;
                            }

                            const tableHeaderCell = tableComponent.HeaderCellProvider.Cells[row || 0][column] || [];
                            tableHeaderCell.Content[0] = newPageComponent;
                        } else if (tableComponent.IsSubsampleTable) {
                            // Tabelle ist eine Teilproben-Tabelle
                            if (tableComponent.CellProvider.Type !== global.PdfDesigner.Model.Enums.TableCellProviderType.Subsample ||
                                (newPageComponent.IsSubsampleCheckpoint && !!currentComponentPrototype.GroupOID &&
                                    tableComponent.CellProvider.ParameterGroupOID !== currentComponentPrototype.GroupOID)) {
                                // Ein Teilproben-Prüfpunkt aus einer anderen Prüfgruppe darf nicht in diese Tabelle eingefügt werden
                                afterPrototypeDroppedActions();
                                return;
                            }

                            const tableContentCell = tableComponent.CellProvider.TemplateCells[column];
                            tableContentCell.Content[0] = newPageComponent;
                        } else if (newPageComponent.IsSubsampleCheckpoint) {
                            // Umwandeln der Tabelle in eine Teilproben-Tabelle
                            const options = {
                                title: i18next.t('changeMode.pdfDesigner.components.convertToSubsampleTableInformation.title'),
                                text: i18next.t('changeMode.pdfDesigner.components.convertToSubsampleTableInformation.text'),
                                yes: true,
                                onYes: () => {
                                    tableComponent.SetNewSubsampleTableContent(newPageComponent.GroupOID, newPageComponent);
                                    tableComponent.RenderOnPage();
                                },
                                no: true,
                                onNo: function() {
                                    afterPrototypeDroppedActions();
                                }
                            };

                            Tools.Message.Show(options);
                            return;
                        } else {
                            // Zelle durch die neue Komponente ersetzten
                            const tableContentCell = tableComponent.CellProvider.Cells[row][column] || [];
                            tableContentCell.Content[0] = newPageComponent;
                        }

                        tableComponent.RenderOnPage();
                        afterPrototypeDroppedActions();
                    });
                }
            });
    }

    /**
     * Erstellt die Interact-Events für auf den Seiten platzierte Komponenten
     */
    function initPageInteractEvents() {
        /**
         * @type {Page}
         */
        let page;

        /**
         * @type {BaseComponent}
         */
        let component;

        let targetBaseSelector = `#${this.options.ID} `;

        switch (this.SectionModificationArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                targetBaseSelector += '.page-header';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                targetBaseSelector += '.main-area';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                targetBaseSelector += '.page-footer';
                break;
        }

        targetBaseSelector += ' .component:not(.table-cell-component):not(table)';

        const draggableModifiers = [
            interact.modifiers.restrict({
                restriction: 'parent',
                elementRect: { top: 0, right: 1, bottom: 1, left: 0 },
            })
        ];

        const gridSnapModifier = getInteractGridSnapModifier.call(this);

        if (gridSnapModifier) {
            draggableModifiers.push(gridSnapModifier);
        }

        const defaultDraggableSettings = {
            inertia: false,
            autoScroll: false,
            modifiers: draggableModifiers,
            listeners: {
                start: (evt) => {
                    page = initPageComponentInteraction.call(this, evt);

                    const $component = $(evt.currentTarget);

                    const identifier = $component.data('identifier');

                    switch (this.SectionModificationArea) {
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                            component = this.CurrentSection.Header.GetComponent(identifier);
                            break;
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                            component = page.GetComponent(identifier);
                            break;
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                            component = this.CurrentSection.Footer.GetComponent(identifier);
                            break;
                    }
                },
                move: (evt) => {
                    if (!component) {
                        page = null;
                        return;
                    }

                    const $component = $(evt.currentTarget);

                    if ($component.hasClass('table-cell-component')) {
                        return;
                    }

                    const x = ($component.data('x') || 0) + evt.dx;
                    let y = ($component.data('y') || 0) + evt.dy;

                    $component
                        .css({ top: y, left: x })
                        .data({ 'x': x, 'y': y });

                    const $page = $component.closest('.page');
                    const $header = $page.find('.page-header');
                    const $content = $page.find('.main-area');

                    switch (this.SectionModificationArea) {
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                            y += $header.outerHeight(true);

                            break;
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                            y += $header.outerHeight(true);
                            y += $content.outerHeight(true);
                            break;
                    }

                    const pageNo = $page.data('page-no');

                    setCurrentCursorPositionOnRuler.call(this, false, 'horizontal', null, x);
                    setCurrentCursorPositionOnRuler.call(this, false, 'vertical', pageNo, y);
                },
                end: (evt) => {
                    const $dropZoneElement = $(evt.currentTarget);
                    const pageNo = $dropZoneElement.hasClass('page') ?
                        $dropZoneElement.data('page-no') :
                        $dropZoneElement.closest('.page').data('page-no');

                    setCurrentCursorPositionOnRuler.call(this, true, 'horizontal');
                    setCurrentCursorPositionOnRuler.call(this, true, 'vertical', pageNo);

                    if (!component || !page) {
                        component = null;
                        page = null;

                        return;
                    }

                    endPageComponentInteraction.call(this, evt, component, page);

                    page = null;
                    component = null;
                }
            }
        };

        const defaultResizableSettings = {
            edges: { top: true, left: true, bottom: true, right: true },
            modifiers: [
                interact.modifiers.restrict({
                    restriction: 'parent',
                }),
                interact.modifiers.restrictSize({
                    min: { width: 50 },
                }),
            ],
            ignoreFrom: '.table-settings-button',
            inertia: false,
            listeners: [{
                start: (evt) => {
                    page = initPageComponentInteraction.call(this, evt);

                    const $component = $(evt.currentTarget);
                    const identifier = $component.data('identifier');

                    switch (this.SectionModificationArea) {
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                            component = this.CurrentSection.Header.GetComponent(identifier);
                            break;
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                            component = page.GetComponent(identifier);
                            break;
                        case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                            component = this.CurrentSection.Footer.GetComponent(identifier);
                            break;
                    }

                    if (component instanceof global.PdfDesigner.Model.TableComponent) {
                        const edgeTableCells = getEdgeTableCells(evt.edges, $component);
                        edgeTableCells.$tableComponents.addClass('moving-active');
                    }
                },
                move: (evt) => {
                    const $component = $(evt.currentTarget);

                    let componentHeight = evt.rect.height;
                    let deltaTop = evt.deltaRect.top;

                    if (component instanceof global.PdfDesigner.Model.LineComponent) {
                        componentHeight = 0;
                        deltaTop = 0;
                    }

                    if (component instanceof global.PdfDesigner.Model.TableComponent) {
                        const edgeTableCells = getEdgeTableCells(evt.edges, $component);
                        const $tableCells = edgeTableCells.$tableCells;
                        const $tableComponents = edgeTableCells.$tableComponents;

                        if (evt.edges.top) {
                            $tableCells.height($tableCells.height() - evt.deltaRect.top);
                            $tableComponents.height($tableComponents.height() - evt.deltaRect.top);
                        } else if (evt.edges.bottom) {
                            $tableCells.height($tableCells.height() + evt.deltaRect.bottom);
                            $tableComponents.height($tableComponents.height() + evt.deltaRect.bottom);
                        } else if (evt.edges.left) {
                            $tableCells.width($tableCells.width() - evt.deltaRect.left);
                            $tableComponents.width($tableComponents.width() - evt.deltaRect.left);
                        } else if (evt.edges.right) {
                            $tableCells.width($tableCells.width() + evt.deltaRect.right);
                            $tableComponents.width($tableComponents.width() + evt.deltaRect.right);
                        }
                    }

                    $component.css({
                        width: evt.rect.width,
                        height: componentHeight
                    });

                    const x = ($component.data('x') || 0) + evt.deltaRect.left;
                    const y = ($component.data('y') || 0) + deltaTop;

                    $component
                        .css({ top: y, left: x })
                        .data({ 'x': x, 'y': y });
                },
                end: (evt) => {
                    if (!component || !page) {
                        component = null;
                        page = null;

                        return;
                    }

                    endPageComponentInteraction.call(this, evt, component, page);

                    if (component.OnAfterTableResized instanceof Function) {
                        component.OnAfterTableResized(page);

                        component.$node.find('.moving-active').removeClass('moving-active');
                    }

                    page = null;
                    component = null;
                }
            }]
        };

        this.Interacts.pages.generic = interact(`${targetBaseSelector}:not(.line-component):not(.table-settings-button)`)
            .draggable(defaultDraggableSettings)
            .resizable(defaultResizableSettings);

        this.Interacts.pages.lineComponents = interact(`${targetBaseSelector}.line-component`)
            .draggable(defaultDraggableSettings)
            .resizable({ ...defaultResizableSettings, edges: { top: false, right: true, bottom: false, left: true } });

        initTableInteractEvents.call(this);
        initTableCellInteractEvents.call(this);
    }

    function getEdgeTableCells(edges, $component) {
        let $tableCells;
        let $tableComponents;

        if (edges.top) {
            $tableCells = $component.find('th');
            $tableComponents = $tableCells.find('.table-cell-component');
        } else if (edges.bottom) {
            $tableCells = $component.find('tbody tr:last-child td');
            $tableComponents = $tableCells.find('.table-cell-component');
        } else if (edges.left) {
            $tableCells = $component.find('th:first-child, td:first-child');
            $tableComponents = $tableCells.find('.table-cell-component');
        } else if (edges.right) {
            $tableCells = $component.find('th:last-child, td:last-child');
            $tableComponents = $tableCells.find('.table-cell-component');
        }

        return {
            $tableCells: $tableCells,
            $tableComponents: $tableComponents
        };
    }

    /**
     * @param {boolean} hide
     * @param {('horizontal'|'vertical')} orientation
     * @param {number?} pageNo
     * @param {number} position
     */
    function setCurrentCursorPositionOnRuler(hide = false, orientation = 'horizontal', pageNo, position = 0) {
        if (position < 0) {
            return;
        }

        const isHorizontal = orientation === 'horizontal';
        const $ruler = isHorizontal ?
            this.$content.find('.horizontal-ruler') :
            this.CurrentSection.GetPage(pageNo - 1).GetRuler();

        $ruler.find('.current-position')
            .css(isHorizontal ? 'left' : 'top', isHorizontal ? `calc(3rem + ${position}px)` : position)
            .toggleClass('hide', hide);
    }

    function initTableInteractEvents() {
        const tableCellTarget = `#${this.options.ID} table td:not(:last-child) > .table-cell-component, th > .table-cell-component`;

        let tableCellComponent;
        let page;
        let $page;

        this.Interacts.pages.tableComponents = interact(tableCellTarget)
            .resizable({
                edges: { top: false, left: false, bottom: true, right: false },
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: '.table-component',
                    })
                ],
                inertia: false,
                listeners: [{
                    start: (evt) => {
                        page = initPageComponentInteraction.call(this, evt);

                        const $component = $(evt.currentTarget);
                        const identifier = $component.data('identifier');

                        tableCellComponent = getSelectedTableComponent($component, identifier, page);
                        $page = $component.closest('.page');
                    },
                    move: (evt) => {
                        const $component = $(evt.currentTarget);

                        $component.css({
                            height: evt.rect.height
                        });

                        const $tableCells = $component.parent().siblings();
                        const $tableCellComponents = $tableCells.find('.table-cell-component');

                        $component.parent().css({
                            height: evt.rect.height
                        });

                        $tableCells.css({
                            height: evt.rect.height
                        });

                        $tableCellComponents.css({
                            height: evt.rect.height
                        });

                        const $tableComponent = $component.parents('.table-component');
                        const $table = $tableComponent.find('table');

                        // Größe der Komponente mit ändern
                        $tableComponent.height($table.outerHeight(true));
                        $tableComponent.width($table.outerWidth(true));

                        setCurrentCursorPositionOnRuler.call(this, false, 'vertical', page.PageNo,
                            $component.offset().top - $page.offset().top + evt.rect.height);
                    },
                    end: (evt) => {
                        if (!tableCellComponent || !page) {
                            tableCellComponent = null;
                            page = null;

                            return;
                        }

                        setCurrentCursorPositionOnRuler.call(this, true, 'vertical', page.PageNo);

                        const $component = $(evt.currentTarget);
                        const parentComponent = getParentTableComponent($component, page);

                        if (!parentComponent) {
                            page = null;
                            tableCellComponent = null;

                            return;
                        }

                        parentComponent.OnAfterTableResized(page);

                        $component.removeClass('moving-active');

                        page = null;
                        tableCellComponent = null;
                    }
                }]
            });
    }

    function initTableCellInteractEvents() {
        const tableCellTarget = `#${this.options.ID} .table-component th`;

        let tableComponent;
        let page;
        let contentHeight = 10;
        let contentWidth = 10;

        let column;
        let $tableComponent;
        let pageNo;
        let $page;
        let $col;
        let colStartWidth;
        let $cols;
        let $resizer;

        this.Interacts.pages.tableCellComponents = interact(tableCellTarget)
            .resizable({
                edges: {
                    top: false,
                    left: true,
                    bottom: false,
                    right: true
                },
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: 'table',
                    })
                ],
                cursorChecker (action, interactable, element, interacting) {
                    if (action.edges.right) { return 'col-resize'}
                    if (action.edges.left) { return 'col-resize'}
                },
                allowFrom: '.col-resizer',
                inertia: false,
                listeners: [{
                    start: (evt) => {
                        $col = $(evt.currentTarget);
                        $resizer = $col.find('.col-resizer');
                        colStartWidth = $col.width();
                        column = $resizer.data('column') || 0;

                        $tableComponent = $col.closest('.table-component');
                        $page = $tableComponent.closest('.page');
                        pageNo = $page.data('page-no');
                        page = this.CurrentSection.GetPage(pageNo - 1);

                        const $table = $tableComponent.find('table');
                        $cols = $table.find('th');

                        tableComponent = page.GetComponent($tableComponent.data('identifier'));
                    },
                    move: (evt) => {
                        // Größenänderung auf +-25px begrenzen
                        const deltaX = Math.max(Math.min(evt.delta.x, 25), -25);
                        let newWidth = $col.width() + deltaX;

                        if (newWidth < 10) {
                            return;
                        }

                        const $siblingColumn = $col.next();
                        const siblingWidth = $siblingColumn.width();

                        let newWidthForSiblingColumn = siblingWidth - deltaX;

                        // Größe der nächsten Spalte anpassen
                        $siblingColumn.css({
                            width: newWidthForSiblingColumn
                        });

                        // Größe der aktuellen Spalte anpassen
                        $col.css({
                            width: newWidth
                        });

                        // Resizer Position anpassen
                        if ($col.index() > 0) {
                            $col.prevAll().each((index, element) => {
                                newWidth += $(element).width() + ($resizer.width() / 2);
                            });
                        }

                        $resizer.css({
                            left: newWidth + ($resizer.width() / 2)
                        });

                        setCurrentCursorPositionOnRuler.call(this, false, 'horizontal', page.PageNo,
                            ($col[0].getBoundingClientRect().left + $col.outerWidth(true)) - $page[0].getBoundingClientRect().left);
                    },
                    end: () => {
                        contentHeight = 0;
                        contentWidth = 0;

                        if (!tableComponent || !page) {
                            tableComponent = null;
                            page = null;

                            return;
                        }

                        setCurrentCursorPositionOnRuler.call(this, true, 'horizontal', page.PageNo);

                        tableComponent.OnAfterTableResized(page);

                        page = null;
                        tableComponent = null;
                    }
                }]
            });
    }

    function getParentTableComponent($component, page) {
        const $tableComponent = $component.closest('table').parent();

        if (!$tableComponent.length) {
            return null;
        }

        const tableIdentifier = $tableComponent.data('identifier');
        return page.GetComponent(tableIdentifier);
    }

    function getSelectedTableComponent($component, identifier, page) {
        const tableComponent = getParentTableComponent($component, page);

        if (!tableComponent) {
            return null;
        }

        return tableComponent.GetSelectedComponent(identifier);
    }

    /**
     * Wird beim Start einer Komponenten-Interaktion innerhalb einer Seite ausgeführt
     * @param evt
     * @return {Page|null}
     */
    function initPageComponentInteraction(evt) {
        const $component = $(evt.currentTarget);
        const pageNo = $component.closest('.page').data('page-no');

        $component.addClass('moving-active');

        if ($component.hasClass('table-cell-component')) {
            return this.CurrentSection.GetPage(pageNo - 1);
        }

        const componentsPosition = $component.position();

        $component
            .css({
                left: componentsPosition.left,
                top: componentsPosition.top
            })
            .data({
                x: componentsPosition.left,
                y: componentsPosition.top
            });

        return this.CurrentSection.GetPage(pageNo - 1);
    }

    /**
     * Wird beim Beenden einer Komponenten-Interaktion innerhalb einer Seite ausgeführt
     * @param evt
     * @param {BaseComponent} component
     * @param {Page} page
     */
    function endPageComponentInteraction(evt, component, page) {
        const $component = $(evt.currentTarget);
        const coordinates = $component.position();

        const $page = $component.closest('.page');
        const $header = $page.find('.page-header');
        const $content = $page.find('.main-area');

        switch (this.SectionModificationArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                coordinates.top += $header.outerHeight(true);

                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                coordinates.top += $header.outerHeight(true);
                coordinates.top += $content.outerHeight(true);
                break;
        }

        const relativeCoordinates = {
            left: coordinates.left / page.Width * 100,
            top: coordinates.top / page.Height * 100
        };

        const relativeDimensions = {
            height: $component.outerHeight() / page.Height * 100,
            width: $component.outerWidth() / page.Width * 100
        };

        component
            .SetCoordinates(relativeCoordinates.left, relativeCoordinates.top)
            .SetDimensions(relativeDimensions.height, relativeDimensions.width);

        $component.removeClass('moving-active');

        refreshMarginalComponent.call(this, component);
    }

    /**
     * Erstellt die Interact-Events für Höhenanpassungen von Kopf- und Fußzeilen
     */
    function initMarginalInteractEvents() {
        const defaultResizableSettings = {
            modifiers: [
                interact.modifiers.restrictSize({
                    min: { height: 20 },
                    max: { height: 300 }
                }),
            ],
            inertia: false,
            listeners: [{
                move: function (evt) {
                    const $marginal = $(evt.currentTarget);

                    $marginal.css({
                        height: evt.rect.height
                    });

                    const $page = $marginal.siblings('.main-area');
                    const $siblingMarginal = $marginal.hasClass('page-header') ?
                        $page.siblings('.page-footer') :
                        $page.siblings('.page-header');

                    $page.css('height', `calc(100% - ${$marginal.outerHeight()}px - ${$siblingMarginal.outerHeight()}px)`);
                },
                end: (evt) => {
                    const $marginal = $(evt.currentTarget);
                    const pageNo = $marginal.closest('.page').data('page-no');
                    const page = this.CurrentSection.GetPage(pageNo - 1);
                    const relativeHeight = $marginal.outerHeight() / page.Height * 100;
                    const marginal = $marginal.hasClass('page-header') ?
                        this.CurrentSection.Header :
                        this.CurrentSection.Footer;

                    // Höhe an Kopf- bzw. Fußzeile setzen
                    marginal.SetHeight(relativeHeight);

                    // Komponenten neu anordnen
                    const realignmentResult = this.CurrentSection.TryRealignComponents();

                    if (realignmentResult.FailedToPosition > 0) {
                        const options = {
                            title: i18next.t('changeMode.pdfDesigner.components.realignmentFailed.title'),
                            text: i18next.t('changeMode.pdfDesigner.components.realignmentFailed.text', {
                                count: realignmentResult.FailedToPosition
                            }),
                            ok: true
                        };

                        Tools.Message.Show(options);
                    }

                    // Bereich neu rendern, wenn mindestens eine Komponente neu positioniert wurde
                    if (realignmentResult.OutOfPosition > 0 &&
                        realignmentResult.FailedToPosition < realignmentResult.OutOfPosition)
                    {
                        const $canvasScrollContainer = getPagesContainer.call(this);
                        // Aktuelle Scrollposition merken
                        const currentScrollPosition = getCurrentCanvasContainerScrollPosition.call(this);

                        renderCurrentSection.call(this);
                        initScrollbar($canvasScrollContainer, currentScrollPosition);
                    }
                }
            }]
        };

        this.Interacts.header.resizable = interact('.page-header')
            .resizable({ ...defaultResizableSettings, edges: { top: false, left: false, bottom: true, right: false } });

        this.Interacts.footer.resizable = interact('.page-footer')
            .resizable({ ...defaultResizableSettings, edges: { top: true, left: false, bottom: false, right: false } });
    }

    /**
     * Gibt den Container zurück der die Pages beinhaltet
     * @return {$}
     */
    function getPagesContainer() {
        return this.$content.find('.pages');
    }

    /**
     * Rendert eine Komponente auf den Kopf-/Fußzeilen aller Seiten neu
     * @param {BaseComponent} component
     */
    function refreshMarginalComponent(component) {
        if (!component) {
            return;
        }

        switch (component.RenderArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                this.CurrentSection.Segments.forEach(page =>
                    component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Header)
                );
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                this.CurrentSection.Segments.forEach(page =>
                    component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Footer)
                );
                break;
        }
    }

    /**
     * Handelt das Click-Event für das Wechseln eines Tabs innerhalb der Komponenten-Auswahl
     * @param {MouseEvent} evt
     */
    function onSwitchTab(evt) {
        const $tabControlItem = $(evt.currentTarget);
        const tabId = $tabControlItem.data('tab');

        if (!tabId) {
            return;
        }

        $tabControlItem
            .addClass('active')
            .siblings()
            .removeClass('active');

        const $tab = this.$content.find(`.component-tab[data-tab="${tabId}"]`);

        $tab
            .removeClass('hide')
            .siblings('.component-tab')
            .addClass('hide');

        initScrollbar($tab);

        this.CurrentComponentTab = tabId;
    }

    /**
     * Handelt das Input-Event für das Durchführen einer Suche innerhalb der Komponenten-Auswahl
     * @param {KeyboardEvent} evt
     */
    function onSearchInput(evt) {
        const $input = $(evt.currentTarget);

        function fn() {
            if (evt.isTrigger) {
                return;
            }

            const searchText = $.trim($input.val());
            const componentGroups = this.CurrentComponentTab === 'predefined' ?
                this.PredefinedComponents.Groups :
                this.CheckpointComponents.Groups;
            const $container = this.$content.find(`.component-tab[data-tab="${this.CurrentComponentTab}"]`);

            setComponentVisibility(searchText, componentGroups, $container);
        }

        window.clearTimeout(this.SearchTimeout);

        if (evt.which === Enums.KeyCodes.Enter || evt.isTrigger) {
            fn.call(this);
        } else {
            this.SearchTimeout = window.setTimeout(() => fn.call(this), 350);
        }
    }

    /**
     * @param {string|null} searchText
     * @param {ComponentGroup[]} componentGroups
     * @param {$} $container
     */
    function setComponentVisibility(searchText, componentGroups, $container) {
        if (!(componentGroups instanceof Array) || !componentGroups.length) {
            return;
        }

        if (!($container instanceof $)) {
            return;
        }

        const regEx = !!searchText ?
            new RegExp(Tools.escRegExp(searchText), 'ig') :
            null;

        componentGroups.forEach(group => {
            let groupIsVisible = false;

            group.Components.forEach(component => {
                component.IsVisible = !regEx || regEx.test(component.Title);
                groupIsVisible = groupIsVisible || component.IsVisible;

                if (regEx) {
                    regEx.lastIndex = -1;
                }

                $container.find(`.component-prototype[data-identifier="${component.OID}"]`)
                    .toggleClass('hide', !component.IsVisible);
            });

            group.IsVisible = !!groupIsVisible;

            $container.find(`section[data-identifier="${group.Identifier}"]`)
                .toggleClass('hide', !group.IsVisible);
        });
    }

    /**
     * Handelt das Click-Event für den Kopfbereich von Komponentengruppen
     * @param {MouseEvent} evt
     */
    function onComponentsHeaderClick(evt) {
        const $header = $(evt.currentTarget);

        $header.toggleClass('collapsed');
        $header.siblings('.component-list').toggleClass('hide');
    }

    /**
     * Handelt das Click-Event für die Bereichsauswahl
     * @param {MouseEvent} evt
     */
    function onSelectSectionClick(evt) {
        const $section = $(evt.currentTarget);
        const sectionIdx = $section.data('idx');

        if (sectionIdx == null || isNaN(sectionIdx)) {
            return;
        }

        selectAndRenderSection.call(this, sectionIdx);
    }

    /**
     * Handelt das Click-Event zum Hinzufügen eines neuen Bereichs
     * @param {MouseEvent} evt
     */
    function onAddSectionClick(evt) {
        createNewSection.call(this);

        const newIdx = this.Sections.length - 1;

        $(evt.currentTarget).before(`<li data-idx="${newIdx}">${newIdx + 1}</li>`);

        selectAndRenderSection.call(this, newIdx);
    }

    /**
     * Selektiert einen Bereich anhand des angegebenen Indizes, rendert diesen und erstellt die nötigen Events
     * @param {number} sectionIdx
     */
    function selectAndRenderSection(sectionIdx) {
        if (this.Sections.length < sectionIdx) {
            return;
        }

        unselectActiveComponent.call(this);

        this.Sections.forEach(section => section.SetIsActive(false));

        this.CurrentSection = this.Sections[sectionIdx];
        this.CurrentSection.SetIsActive(true);

        this.$content
            .find(`.section-navigation li[data-idx="${sectionIdx}"]`)
            .addClass('active')
            .siblings()
            .removeClass('active');

        renderCurrentSection.call(this);
    }

    /**
     * Rendert den aktuell ausgewählten Bereich neu und erstellt die nötigen Events
     */
    function renderCurrentSection() {
        this.$content.find('.pages').html(this.CurrentSection.Render());

        renderPages.call(this);
        initSectionEvents.call(this);
    }

    /**
     * Erstellt einen neuen Bereich
     */
    function createNewSection() {
        const page = createNewPage.call(this, 1);
        const sectionSettings = getDefaultSectionSettings();
        const marginalSettings = getDefaultMarginalSettings.call(this);

        const section = new global.PdfDesigner.Model.Section(
            sectionSettings,
            null,
            new global.PdfDesigner.Model.Marginal(
                marginalSettings,
                10,
                global.PdfDesigner.Model.Enums.SectionModificationArea.Header
            ),
            new global.PdfDesigner.Model.Marginal(
                marginalSettings,
                10,
                global.PdfDesigner.Model.Enums.SectionModificationArea.Footer
            ),
            [page]
        );

        section.SetShowGridLines(this.CurrentSection.GetShowGridLines(), this.SectionModificationArea);

        this.Sections.push(section);
    }

    /**
     * Entfernt die ausgewählte Seite aus dem aktuellen Bereich und erstellt die Anzeige für diesen Bereich neu
     * @param {number} pageNo
     */
    function removePage(pageNo) {
        if (typeof pageNo !== 'number' || isNaN(pageNo)) {
            return;
        }

        this.CurrentSection.RemovePage(pageNo - 1);
        renderCurrentSection.call(this);
    }

    /**
     * Entfernt einen Bereich inklusive aller Seiten aus der Vorlage und setzt den Fokus auf den ersten verfügbaren Bereich
     */
    function removeSection() {
        const sectionIdx = this.$content.find('.section-navigation .active').data('idx');

        this.Sections.splice(sectionIdx, 1);
        this.$content.find('.section-navigation ul').html(createSectionSelectionMarkup.call(this));

        selectAndRenderSection.call(this, 0);
    }

    /**
     * Erstellt eine neue Seite
     * @param {number} pageNo
     * @return {Page}
     */
    function createNewPage(pageNo) {
        const pageSettings = getDefaultPageSettings.call(this);

        return new global.PdfDesigner.Model.Page(pageSettings, pageNo);
    }

    /**
     * Handelt das Click-Event zur Auswahl des zu bearbeitenden Seitenbereichs
     * @param {MouseEvent} evt
     */
    function onSelectSectionModificationArea(evt) {
        const $btn = $(evt.currentTarget);
        const newModificationArea = $btn.data('modification-area');

        changeModificationArea.call(this, newModificationArea);
    }

    /**
     * Steuert das Ein-/Ausblenden der Gitternetzlinien
     * @param {MouseEvent} evt
     */
    function onToggleGridLines(evt) {
        const $btn = $(evt.currentTarget);
        const isActive = $btn.hasClass('is-active');

        this.Sections.forEach(section => section.SetShowGridLines(!isActive, this.SectionModificationArea));

        $btn.toggleClass('is-active', !isActive);

        initInteractEvents.call(this);
    }

    /**
     * Ändert den aktiven Seitenbereich
     * @param {string} newModificationArea
     */
    function changeModificationArea(newModificationArea) {
        if (!newModificationArea) {
            return;
        }

        this.SectionModificationArea = newModificationArea;

        this.$content.find(`.section-toolbar .button[data-modification-area="${newModificationArea}"]`)
            .addClass('active')
            .siblings()
            .removeClass('active');

        const $canvasWrapper = this.$content.find('.canvas-wrapper');

        $canvasWrapper.removeClass('header-active page-active footer-active');

        switch (this.SectionModificationArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                $canvasWrapper.addClass('header-active');
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                $canvasWrapper.addClass('page-active');
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                $canvasWrapper.addClass('footer-active');
                break;
        }

        initSectionEvents.call(this, true);
        this.Sections.forEach(section => section.SetShowGridLines(section.GetShowGridLines(), this.SectionModificationArea));

        this.$content.trigger('modificationArea.changed');
    }

    /**
     * Handelt das Click-Event zur Erstellung einer neuen Seite innerhalb des aktuellen Bereichs
     */
    function onAddPageClick() {
        const sectionPageCount = this.CurrentSection.Segments.length;
        const page = createNewPage.call(this, sectionPageCount + 1);

        this.CurrentSection.AddPage(page);

        const $pagesContainer = this.$content.find('.pages');

        $pagesContainer.html(this.CurrentSection.Render());

        const $canvasScrollContainer = getPagesContainer.call(this);

        renderPages.call(this);
        initSectionEvents.call(this);
        initScrollbar($canvasScrollContainer);

        const $page = this.$content.find(`.page[data-page-no="${sectionPageCount + 1}"]`);
        const scrollbar = getScrollbar($canvasScrollContainer);
        const pageYPosition = parseInt($page.css('top')) - 5;

        scrollbar.elements().viewport.scroll({ top: pageYPosition });
    }

    /**
     * Initialisiert die Events für die aktuelle Bereichsauswahl
     * @param {boolean} rememberScrollPosition
     */
    function initSectionEvents(rememberScrollPosition = false) {
        const $canvasScrollContainer = getPagesContainer.call(this);
        const currentScrollPosition = getCurrentCanvasContainerScrollPosition.call(this);

        initScrollbar($canvasScrollContainer, rememberScrollPosition ? currentScrollPosition : null);
        initInteractEvents.call(this);
    }

    /**
     * Bestimmt die aktuelle Scroll-Position des Canvas-Containers
     * @return {number|null}
     */
    function getCurrentCanvasContainerScrollPosition() {
        const $canvasScrollContainer = getPagesContainer.call(this);
        const scrollbar = getScrollbar($canvasScrollContainer);

        return scrollbar ? scrollbar.elements().viewport.scrollTop : null;
    }

    if (!global.PdfDesigner) {
        global.PdfDesigner = {};
    }

    global.PdfDesigner.Window = PdfDesigner;
})(Modifications.Popups || (Modifications.Popups = {}));