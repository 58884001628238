/**
 * @require ./property.js
 */
(function (global) {
    var PropertyGroup = function (definition) {
        if (!definition) {
            throw new Error('PropertyGroup definition not given');
        }

        if (!(definition.Properties instanceof Array) ||
            definition.Properties.length === 0) {
            throw new Error('No properties given');
        }

        for (var attr in definition) {
            if (!definition.hasOwnProperty(attr)) {
                continue;
            }

            if (attr === 'Type') {
                this.Type = global.PropertyTypes.Group;
            } else {
                this[attr] = definition[attr];
            }
        }
    }

    PropertyGroup.prototype.Render = function () {
        var menuConfig = Client.OfficeConfiguration[Mode];
        var tabConfig = menuConfig && menuConfig.HiddenProperties ? menuConfig.HiddenProperties[content.Mode] : null;
        var hiddenProperties = tabConfig || [];

        return [
            '<div class="content-panel" id="', this.ID, '">',
                (this.Properties || []).filter(function (property) {
                    return hiddenProperties.indexOf(property.ID) === -1;
                }).map(function (property) {
                    return property.Render();
                }).join(''),
            '</div>'
        ].join('');
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.PropertyGroup = PropertyGroup;
})(Modifications.Pages || (Modifications.Pages = {}));