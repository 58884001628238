/**
 * @require ./property.js
 */
(function (global) {
    var ResettableButtonProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.ResettableButton;

        global.Base.Properties.Base.call(this, definition);
    };

    ResettableButtonProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: ResettableButtonProperty
    });

    ResettableButtonProperty.prototype.RenderInput = function () {
        var unformattedValue = CurrentEntity[this.MappedProperty];
        var formattedValue = this.ValueFormatter instanceof Function ?
            this.ValueFormatter(unformattedValue) :
            unformattedValue;

        if (this.AllowMultiSelection && unformattedValue instanceof Array) {
            unformattedValue = unformattedValue.join('|');
        }

        var html = [
            '<div class="clearable-button-selection" id="', this.ID, '-value"',
                !!unformattedValue ? ' data-value="' + unformattedValue + '"' : '',
                '>',
                '<div class="button-steal select">', !!formattedValue ? formattedValue : i18next.t('misc.noSelection'), '</div>',
                '<div class="button-steal clear">×</div>',
            '</div>'
        ];

        return html.join('');
    };

    ResettableButtonProperty.prototype.OnAfterRendered = function () {
        this.BindEvents.call(this);
    };

    ResettableButtonProperty.prototype.BindEvents = function () {
        var $prop = $('#' + this.ID);

        if (!$prop.length) {
            return;
        }

        var me = this;

        $prop.find('.select').on('click', this.OnSelectClick);
        $prop.find('.clear').on(
            'click',
            this.OnResetClick instanceof Function ?
                $.proxy(this.OnResetClick, me) :
                $.proxy(onResettableButtonClearClick, me)
        );
    };

    ResettableButtonProperty.prototype.SetValue = function (value) {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        var formattedValue = this.ValueFormatter instanceof Function ?
            this.ValueFormatter(value) :
            value;

        $prop.find('.select')
            .html(formattedValue || i18next.t('misc.noSelection'));

        if (value instanceof Array) {
            value = value.join('|');
        }

        $prop
            .data('value', value)
            .attr('data-value', value);
    };

    ResettableButtonProperty.prototype.GetValue = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        var value = $prop.data('value');

        if (this.AllowMultiSelection && typeof value === 'string') {
            return value.split('|');
        }

        return value;
    };

    ResettableButtonProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    function onResettableButtonClearClick(evt) {
        var $btn = $(evt.currentTarget);
        var $prop = $btn.parents('.clearable-button-selection');

        if ($prop.length) {
            this.SetValue('');
            changemode.CurrentPage.CurrentTab.MarkAsModified();
        }

        if (this.OnAfterResetClick instanceof Function) {
            this.OnAfterResetClick.call(this, evt.currentTarget);
        }
    }

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.ResettableButtonProperty = ResettableButtonProperty;
})(Modifications.Pages || (Modifications.Pages = {}));