/**
 * @require ./property.js
 */
(function (global) {
    var TextLineProperty = function (definition) {
        if (!definition) {
            throw new Error('Property definition not given');
        }

        definition.Type = global.PropertyTypes.TextLine;

        if (!(definition.OnChange instanceof Function)) {
            definition.OnChange = function () {
                changemode.CurrentPage.CurrentTab.MarkAsModified();
            };
        }

        global.Base.Properties.Base.call(this, definition);
    };

    TextLineProperty.prototype = Object.create(global.Base.Properties.Base.prototype, {
        constructor: TextLineProperty
    });

    TextLineProperty.prototype.RenderInput = function () {
        var html = [];

        if (this.Button) {
            html.push('<div class="multi-item-row">');
        }

        html.push(
            '<input type="text" id="', this.ID + '-value"',
            ' class="xlarge',
            this.Button ? ' with-button' : '',
            '" placeholder="', i18next.t('misc.enterText'), '"'
        );

        if (typeof this.MaxLength === 'number' && this.MaxLength > 0) {
            html.push(' maxlength="', this.MaxLength, '"');
        }

        html.push('>');

        if (this.Button) {
            html.push(
                '<div class="button-steal"',
                    this.Button.Title ? ' title="' + this.Button.Title + '">' : '>',
                    this.Button.Caption,
                '</div>',
                '</div>'
            );
        }

        return html.join('');
    };

    TextLineProperty.prototype.OnAfterRendered = function () {
        var value = CurrentEntity[this.MappedProperty];
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        $prop.val(Tools.unescapeHtml(value) || '');

        this.BindEvents.call(this);
    };

    TextLineProperty.prototype.BindEvents = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return;
        }

        $prop.on('input', this.OnChange);

        if (this.OnBlur instanceof Function) {
            $prop.on('blur', this.OnBlur);
        }

        if (this.OnBlur instanceof Function) {
            $prop.on('blur', this.OnBlur);
        }

        if (this.Button && this.Button.OnClick instanceof Function) {
            $prop
                .siblings('.button-steal')
                .on('click', this.Button.OnClick);
        }
    };

    TextLineProperty.prototype.SetValue = function (value) {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        $prop.val(Tools.unescapeHtml(value));
    };

    TextLineProperty.prototype.GetValue = function () {
        var $prop = $('#' + this.ID + '-value');

        if (!$prop.length) {
            return null;
        }

        return Tools.escapeHtml($prop.val()) || null;
    };

    TextLineProperty.prototype.ApplyValue = function () {
        CurrentEntity[this.MappedProperty] = this.GetValue();
    };

    if (!global.Base) {
        global.Base = {};
    }

    if (!global.Base.Properties) {
        global.Base.Properties = {};
    }

    global.Base.Properties.TextLineProperty = TextLineProperty;
})(Modifications.Pages || (Modifications.Pages = {}));