/**
 * @require ../_base/page.js
 * @require ../_base/page-tab.js
 */
(function (global) {
    var ElementCodingsView = function () {
        var availableTabs = getAvailableTabs.call(this);
        var options = getPageOptions.call(this);

        global.Base.Page.call(this, availableTabs, options);
    };

    ElementCodingsView.prototype = Object.create(global.Base.Page.prototype, {
        constructor: ElementCodingsView
    });

    ElementCodingsView.prototype.SaveEntity = function () {
        return ElementCodings.Save(CurrentEntity);
    };

    function getAvailableTabs() {
        return [
            new global.Base.PageTab(
                'properties', getAvailableProperties.call(this)
            )
        ];
    }

    function getAvailableProperties() {
        return [
            new global.Base.Properties.PropertyGroup({
                ID: 'element-codings-misc',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.TextLineProperty({
                        Caption: i18next.t('changeMode.panels.properties.prefix'),
                        ID: 'element-coding-prefix',
                        MaxLength: 10,
                        MappedProperty: 'Prefix',
                        IsRequired: true
                    }),
                    new global.Base.Properties.TextMemoProperty({
                        Caption: i18next.t('changeMode.panels.properties.description'),
                        ID: 'element-coding-description',
                        Rows: 3,
                        MaxLength: 200,
                        MappedProperty: 'Description'
                    })
                ]
            })
        ];
    }

    function getPageOptions() {
        return {
            TreeTextProperty: 'Prefix'
        }
    }

    if (!global.System) {
        global.System = {};
    }

    global.System.ElementCodings = ElementCodingsView;
})(Modifications.Pages || (Modifications.Pages = {}));