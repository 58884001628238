/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @readonly
     */
    global.Model.Enums = {
        /**
         * @enum {number}
         */
        PositionType: {
            Relative: 0,
            Absolute: 1
        },
        /**
         * @enum {number}
         */
        ComponentType: {
            Text: 0,
            Table: 1,
            Image: 2,
            Geometry: 3,
            Checkpoint: 4
        },
        /**
         * @enum {string}
         */
        ComponentSettingRepresentation: {
            Dropdown: 'dropdown',
            FileSelection: 'file-selection',
            Info: "info",
            Input: 'input',
            MultiLineInput: 'multi-line-input',
            MultiButtonSelection: 'multi-button-selection',
            Button: 'button',
            Boolean: 'boolean',
            Color: 'color'
        },
        /**
         * @enum {number}
         */
        HorizontalAlignment: {
            Left: 0,
            Right: 1,
            Center: 2,
            Justify: 3
        },
        /**
         * @enum {number}
         */
        VerticalAlignment: {
            Top: 0,
            Center: 1,
            Bottom: 2,
            Justify: 3
        },
        /**
         * @enum {number}
         */
        FitMethod: {
            Auto: 0,
            Clip: 1,
            Meet: 2,
            NoFit: 3
        },
        /**
         * @enum {string}
         */
        SectionModificationArea: {
            Footer: 'footer',
            Header: 'header',
            Page: 'page'
        },
        /**
         * @enum {number}
         */
        CheckpointContentType: {
            Value: 0,
            Title: 1,
            Description: 2,
            CreatorName: 3,
            EditorName: 4,
            CreationTimestamp: 5,
            ModificationTimestamp: 6
        },
        /**
         * @enum {string}
         */
        CheckpointTextPrefixes: {
            CreationTimestamp: 'CP_CreationTimestamp_',
            CreatorName: 'CP_CreatorName_',
            Description: 'CP_Description_',
            EditorName: 'CP_EditorName_',
            ModificationTimestamp: 'CP_ModificationTimestamp_',
            Title: 'CP_Title_',
            Value: 'CP_Value_'
        },
        /**
         * @enum {number}
         */
        TableCellProviderType: {
            Absolute: 0,
            Subsample: 1
        },
        /**
         * @enum {string}
         */
        TableActions: {
            AddRow: 'add-row',
            DeleteRow: 'delete-row',
            AddColumnLeft: 'add-column-left',
            AddColumnRight: 'add-column-right',
            DeleteColumn: 'delete-column'
        }
    };
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));