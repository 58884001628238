/**
 * @require ./_base.js
 */
(function (global) {
    var MailTemplatePlaceholdersWindow = function (id, dropZoneSelector, form) {
        if (!id) {
            id = 'mail-template-placeholders';
        }

        this.ID = id;

        if (!!dropZoneSelector) {
            this.DropZoneSelector = dropZoneSelector;
            this.$dropZoneElements = $(dropZoneSelector);
        }

        this.Form = form;
        this.SearchResult = [];
    };

    function show() {
        if ($('#' + this.ID).length > 0) {
            return;
        }

        initPlaceholders.call(this);

        var windowMarkup = getWindowMarkup.call(this);
        $('body').append(windowMarkup);

        initWindow.call(this);
        bindEvents.call(this);
        resizeWindow.call(this);

        return this;
    }

    function initWindow() {
        this.$window = $('#' + this.ID);
        this.$header = this.$window.find('h1');
        this.$footer = this.$window.find('.bottom');

        this.$search = this.$window.find('.search');
        this.$placeHoldersList = this.$window.find('.list');
        this.$placeHoldersDescription = this.$window.find('.description');

        initSearch.call(this);
    }

    function initPlaceholders() {
        var me = this;

        me.PlaceHoldersList = getFormParameterPlaceholders.call(me);

        var maxPosition = me.PlaceHoldersList.length > 0
            ? me.PlaceHoldersList.reduce(function (max, group) {
                return max > group.position ? max : group.position;
            }, -1)
            : -1;

        me.PlaceHoldersList.push(
            {
                title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issuePlaceholders.title'),
                position: ++maxPosition,
                children: [
                    {
                        name: 'IssueCreatorName',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueCreatorName.title'),
                        description: getIssuePlaceholderDescription('issueCreatorName')
                    },
                    {
                        name: 'IssueCreationTimestamp',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueCreationTimestamp.title'),
                        description: getIssuePlaceholderDescription('issueCreationTimestamp')
                    },
                    {
                        name: 'IssueEditorName',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueEditorName.title'),
                        description: getIssuePlaceholderDescription('issueEditorName')
                    },
                    {
                        name: 'IssueModificationTimestamp',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueModificationTimestamp.title'),
                        description: getIssuePlaceholderDescription('issueModificationTimestamp')
                    },
                    {
                        name: 'IssueTitle',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueTitle.title'),
                        description: getIssuePlaceholderDescription('issueTitle')
                    },
                    {
                        name: 'IssueDescription',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueDescription.title'),
                        description: getIssuePlaceholderDescription('issueDescription')
                    },
                    {
                        name: 'IssueLocationTitle',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueLocationTitle.title'),
                        description: getIssuePlaceholderDescription('issueLocationTitle')
                    },
                    {
                        name: 'IssueType',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueType.title'),
                        description: getIssuePlaceholderDescription('issueType')
                    },
                    {
                        name: 'IssueFormTitle',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueFormTitle.title'),
                        description: getIssuePlaceholderDescription('issueFormTitle')
                    },
                    {
                        name: 'IssueDeadlineTimestamp',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueDeadlineTimestamp.title'),
                        description: getIssuePlaceholderDescription('issueDeadlineTimestamp')
                    },
                    {
                        name: 'IssueStateTitle',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueStateTitle.title'),
                        description: getIssuePlaceholderDescription('issueStateTitle')
                    },
                    {
                        name: 'IssuePriorityTitle',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issuePriorityTitle.title'),
                        description: getIssuePlaceholderDescription('issuePriorityTitle')
                    },
                    {
                        name: 'IssueId',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueId.title'),
                        description: getIssuePlaceholderDescription('issueId')
                    },
                    {
                        name: 'IssueRevision',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.issueRevision.title'),
                        description: getIssuePlaceholderDescription('issueRevision')
                    },
                    {
                        name: 'OpenRecordingLink',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.openRecording.title'),
                        description: getIssuePlaceholderDescription('openRecording')
                    }
                ]
            });

        if (me.Form && me.Form.IsInvestigation) {
            me.PlaceHoldersList.push({
                title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.investigationPlaceholders.title'),
                position: ++maxPosition,
                children: [
                    {
                        name: 'OpenInvestigationLink',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.openInvestigationLink.title'),
                        description: getPlaceholderDescription('investigationPlaceholders', 'openInvestigationLink')
                    }
                ]
            });
        }

        if (me.Form && Tools.IsContactModuleAvailable()) {
            me.PlaceHoldersList.push({
                title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.sharedCorrectiveActionsPlaceholders.title'),
                position: ++maxPosition,
                children: [
                    {
                        name: 'OpenSharedCorrectiveActionsLink',
                        title: i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.openSharedCorrectiveActionsLink.title'),
                        description: getPlaceholderDescription('sharedCorrectiveActionsPlaceholders', 'openSharedCorrectiveActionsLink')
                    }
                ]
            });
        }

        me.PlaceHoldersList.sort(sortByPositionAndTitle);

        me.PlaceHoldersMap = {};
        me.PlaceHoldersList
            .filter(function (group) {
                return (group.children || []).length;
            })
            .forEach(function (group) {
                group.children
                    .filter(function (fn) {
                        return !!fn.name;
                    })
                    .forEach(function (fn) {
                        me.PlaceHoldersMap[fn.name.toLowerCase()] = fn;
                    });
            });

        this.CurrentlySelectedPlaceholder = this.PlaceHoldersList[0].children[0];
    }

    function initSearch() {
        var searchOptions = {
            placeholder: i18next.t('changeMode.mailTemplates.placeholdersWindow.search')
        };

        this.Search = this.$search
            .search(searchOptions)
            .search(true);
    }

    function getIssuePlaceholderDescription(key) {
        return getPlaceholderDescription('issuePlaceholders', key);
    }

    function getPlaceholderDescription(groupKey, placeholderKey) {
        if (!groupKey || !placeholderKey) {
            return null;
        }

        groupKey[0] = groupKey[0].toLowerCase();
        placeholderKey[0] = placeholderKey[0].toLowerCase();

        var description = '<span class="item-group">' +
            i18next.t('changeMode.mailTemplates.placeholdersWindow.placeholders.' + groupKey + '.title') +
            '</span>';

        description += '<br><br>' +
            i18next.t(
                'changeMode.mailTemplates.placeholdersWindow.placeholders.' +
                placeholderKey +
                '.description'
            );

        return description;
    }

    function getFormParameterPlaceholders() {
        if (!this.Form || !(this.Form.Parametergroups || []).length) {
            return [];
        }

        var me = this;

        me.Checkpoints = {};

        return this.Form.Parametergroups
            .filter(function (group) {
                return group.Enabled &&
                    group.ModificationType !== Enums.ModificationType.Deleted &&
                    (group.Parameters || []).length > 0;
            })
            .map(function (group) {
                return {
                    title: group.Title,
                    position: group.Position,
                    children: group.Parameters
                        .filter(function (param) {
                            return param.Enabled &&
                                param.ModificationType !== Enums.ModificationType.Deleted &&
                                param.Type !== Enums.elementType.Info;
                        })
                        .map(function (param) {
                            me.Checkpoints[param.OID] = param;

                            var description = '<span class="item-group">' +
                                i18next.t('changeMode.mailTemplates.placeholdersWindow.checkpointGroup') +
                                ': ' +
                                group.Title +
                                '</span>';

                            if (!!param.Description) {
                                description += '<br><br>' + param.Description;
                            }

                            return {
                                name: 'CP_' + param.OID,
                                title: $.trim(param.Title),
                                description: description,
                                position: param.Position
                            };
                        })
                };
            });
    }

    function getWindowMarkup() {
        return [
            '<div id="', this.ID, '" class="popup draggable-window">',
                '<h1 class="top">',
                    '<span class="title">', i18next.t('changeMode.mailTemplates.placeholdersWindow.title'), '</span>',
                    '<span class="close"></span>',
                '</h1>',

                '<div class="content">',
                    '<div class="content-message-bubble">',
                        '<div class="content info">',
                            '<p class="text">',
                                i18next.t('changeMode.mailTemplates.placeholdersWindow.infoText'),
                            '</p>',
                        '</div>',
                    '</div>',

                    '<div class="detailed-data-picker">',
                        '<div class="search"></div>',
                        '<div class="grid-row details">',
                            '<ul class="grid-col-6-12 list">',
                                getPlaceholdersListMarkup.call(this),
                            '</ul>',
                            '<div class="grid-col-6-12 description">',
                                getPlaceholderDescriptionMarkup.call(this),
                            '</div>',
                        '</div>',
                    '</div>',
                '</div>',

                '<div class="bottom">',
                    '<div class="flex-dummy"></div>',
                    '<div class="btn-steal btn-close">',
                        i18next.t('changeMode.mailTemplates.placeholdersWindow.close'),
                    '</div>',
                '</div>',
            '</div>'
        ].join('');
    }

    function unbindEvents() {
        this.$header.off('click');
        this.$footer.find('.btn-close').off('click');

        this.$search.off('.search');
        this.$placeHoldersList.off('click');

        $(window).off('resize.resizeMailTemplatePlaceholdersWindow');

        if (this.$dropZoneElements instanceof $) {
            this.$placeHoldersList.find('li')
                .off('dragstart')
                .off('dragend');

            unbindDropZoneEvents.call(this);
        }
    }

    function unbindDropZoneEvents() {
        if (this.$dropZoneElements instanceof $) {
            this.$dropZoneElements
                .off('dragover')
                .off('drop');
        }
    }

    function bindEvents() {
        unbindEvents.call(this);

        this.$header.find('.close').on('click', $.proxy(close, this));
        this.$footer.find('.btn-close').on('click', $.proxy(close, this));

        this.$placeHoldersList.on('click', 'li:not(.item-header):not(.selected)', $.proxy(onClickPlaceholdersListItem, this));

        this.$search
            .on('search.search', $.proxy(onSearchFunction, this))
            .on('select-previous-result.search select-next-result.search', $.proxy(onSelectPreviousOrNextFunction, this));

        $(window)
            .on('resize.resizeMailTemplatePlaceholdersWindow', $.proxy(resizeWindow, this));

        this.$window
            .draggable({
                handle: '.top',
                cancel: '.close',
                appendTo: 'body',
                scroll: false
            });

        if (this.$dropZoneElements instanceof $) {
            this.$placeHoldersList.find('li')
                .on('dragstart', $.proxy(onDragStart, this))
                .on('dragend', $.proxy(onDragEnd, this));
        }
    }

    function bindDropZoneEvents() {
        if (!this.DropZoneSelector) {
            return;
        }

        unbindDropZoneEvents.call(this);

        this.$dropZoneElements = $(this.DropZoneSelector);

        if (this.$dropZoneElements instanceof $) {
            this.$dropZoneElements
                .on('dragover', $.proxy(onDragOver, this))
                .on('drop', $.proxy(onDrop, this));
        }
    }

    function onDragStart(evt) {
        var me = this;

        bindDropZoneEvents.call(me);

        me.DraggedListItem = evt.currentTarget;
        me.DraggedListItem.style.fontWeight = 'bold';

        evt.originalEvent.dataTransfer.effectAllowed = 'move';
        evt.originalEvent.dataTransfer.setData('text/html', me.outerHTML);

        me.$dragOverlay = $('<div class="drag-overlay"></div>');
        $('body').append(me.$dragOverlay);

        me.$dropZoneElements.addClass('drop-visible');

        if (changemode.CurrentPage && changemode.CurrentPage.CurrentTab) {
            Object.values(changemode.CurrentPage.CurrentTab.PropertyMap)
                .filter(function (pageProperty) {
                    return pageProperty.Type === Modifications.Pages.PropertyTypes.RichTextField &&
                        pageProperty.Editor;
                })
                .forEach(function (pageProperty) {
                    pageProperty.Editor.onDrop = function () {
                        onDropToRichTextField.call(me, pageProperty);
                    };
                });
        }
    }

    function onDragOver(evt) {
        if (evt.preventDefault) {
            evt.preventDefault();
        }

        evt.originalEvent.dataTransfer.dropEffect = 'move';

        return false;
    }

    function onDragEnd() {
        this.DraggedListItem.style.fontWeight = 'normal';

        if (this.$dragOverlay) {
            this.$dragOverlay.remove();
            this.$dragOverlay = null;
        }

        this.$dropZoneElements.removeClass('drop-visible');
    }

    function onDrop(evt) {
        if (this.DraggedListItem == null) {
            return;
        }

        var $targetControl = $(evt.currentTarget);
        var $targetItem = $targetControl.parents('.item');
        var pageProperty = changemode.CurrentPage && changemode.CurrentPage.CurrentTab ?
            changemode.CurrentPage.CurrentTab.PropertyMap[$targetItem.attr('id')] :
            null;

        if (!pageProperty) {
            return;
        }

        switch (pageProperty.Type) {
            case Modifications.Pages.PropertyTypes.TextLine:
            case Modifications.Pages.PropertyTypes.TextMemo:
                onDropToTextField.call(this, $targetControl);
                break;
        }

        this.DraggedListItem.style.fontWeight = 'normal';

        if (this.$dragOverlay) {
            this.$dragOverlay.remove();
            this.$dragOverlay = null;
        }

        this.$dropZoneElements.removeClass('drop-visible');

        changemode.CurrentPage.CurrentTab.MarkAsModified();
    }

    function createPlaceholderText() {
        if (!this.DraggedListItem) {
            return null;
        }

        var name = this.DraggedListItem.dataset.name;

        if (!name) {
            return null;
        }

        return '{{' + name + '}}';
    }

    function onDropToTextField($targetControl) {
        if (!($targetControl instanceof $)) {
            return null;
        }

        var name = this.DraggedListItem ? (this.DraggedListItem.dataset.name || '') : '';

        if (name.indexOf('CP_') === 0) {
            var oid = name.substr(3);
            var checkpoint = this.Checkpoints[oid]

            if (!checkpoint ||
                checkpoint.Type === Enums.elementType.Photo ||
                checkpoint.Type === Enums.elementType.Signature) {
                return null;
            }
        }

        var placeholderText = createPlaceholderText.call(this);

        if (!placeholderText) {
            return null;
        }

        var cursorPos = $targetControl.prop('selectionStart');
        var currentValue = $targetControl.val();
        var textBefore = currentValue.substring(0,  cursorPos);
        var textAfter = currentValue.substring(cursorPos, currentValue.length);

        $targetControl.val(textBefore + placeholderText + textAfter);
    }

    function onDropToRichTextField(pageProperty) {
        if (!pageProperty ||
            !pageProperty.Type === Modifications.Pages.PropertyTypes.RichTextField ||
            !pageProperty.Editor) {
            return null;
        }

        var placeholderText = createPlaceholderText.call(this);

        if (!placeholderText) {
            return null;
        }

        pageProperty.Editor.insertHTML(placeholderText, true, false);

        Object.values(changemode.CurrentPage.CurrentTab.PropertyMap)
            .filter(function (pageProperty) {
                return pageProperty.Type === Modifications.Pages.PropertyTypes.RichTextField &&
                    pageProperty.Editor;
            })
            .forEach(function (pageProperty) {
                pageProperty.Editor.onDrop = null;
            });

        this.DraggedListItem.style.fontWeight = 'normal';

        changemode.CurrentPage.CurrentTab.MarkAsModified();

        return null;
    }

    function getPlaceholdersListMarkup() {
        return this.PlaceHoldersList
            .sort(sortByPositionAndTitle)
            .map($.proxy(createPlaceholdersListGroup, this))
            .join('');
    }

    function createPlaceholdersListGroup(group) {
        return [
            '<li class="item-header">', group.title, '</li>',
            (group.children || [])
                .sort(sortByPositionAndTitle)
                .map($.proxy(createPlaceholdersListItem, this))
                .join('')
        ].join('');
    }

    function sortByPositionAndTitle(a, b) {
        if (a.position !== b.position) {
            return a.position - b.position;
        }

        return Tools.SortStringArray(a.title, b.title);
    }

    function createPlaceholdersListItem(placeholder) {
        var isSelected = placeholder === this.CurrentlySelectedPlaceholder;
        var isSearchResult = Tools.contains(this.SearchResult, placeholder.name, 'name');
        var nameIsSearchResult = isSearchResult && this.SearchRegExp.test(placeholder.name);
        var innerHtml = isSearchResult && !nameIsSearchResult ?
            placeholder.title.replace(this.SearchRegExp, '<span class="search-result">$1</span>') :
            placeholder.title;

        if (this.SearchRegExp) {
            this.SearchRegExp.lastIndex = 0;
        }

        if (nameIsSearchResult) {
            innerHtml = '<span class="search-result">' + placeholder.title + '</span>';
        }

        return isSelected ?
            '<li class="selected" data-name="{0}" draggable="true">{1}</li>'.format(placeholder.name, innerHtml) :
            '<li data-name="{0}" draggable="true">{1}</li>'.format(placeholder.name, innerHtml);
    }

    function onClickPlaceholdersListItem(evt) {
        var $this = $(evt.currentTarget);
        var name = $this.data('name').toLowerCase();

        this.CurrentlySelectedPlaceholder = this.PlaceHoldersMap[name];

        this.$placeHoldersList.children('.selected').removeClass('selected');
        $this.addClass('selected');

        var placeholderDescription = getPlaceholderDescriptionMarkup.call(this);

        this.$placeHoldersDescription.html(placeholderDescription);
    }

    function getPlaceholderDescriptionMarkup() {
        var html = [];

        if (this.CurrentlySelectedPlaceholder) {
            html.push(
                '<h4>', this.CurrentlySelectedPlaceholder.title, '</h4>',
                this.CurrentlySelectedPlaceholder.description || ''
            );
        }

        return html.join('');
    }

    function selectPlaceholdersSearchResult(selectedResult) {
        var idx = (selectedResult || 1) - 1;

        if (idx >= this.SearchResult.length) {
            return;
        }

        var selectedSearchResult = this.SearchResult[idx];
        var $children = this.$placeHoldersList.children();
        var $selectedSearchResult = $children.filter('[data-name="{0}"]'.format(selectedSearchResult.name));

        $children
            .filter('.selected-search-result')
            .removeClass('selected-search-result');

        $selectedSearchResult.addClass('selected-search-result');
        $selectedSearchResult.get(0).scrollIntoView();
    }

    function onSearchFunction(_event, data) {
        var me = this;
        var searchText = (data.input || '')
            .trim()
            .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

        if (searchText) {
            me.SearchRegExp = new RegExp('(' + searchText + ')', 'ig');

            me.SearchResult = Object.values(me.PlaceHoldersMap)
                .filter(function (fn) {
                    me.SearchRegExp.lastIndex = 0;

                    return me.SearchRegExp.test(fn.title) || me.SearchRegExp.test(fn.name);
                })
                .sort(sortByPositionAndTitle);

            me.Search.setResultCount(me.SearchResult.length);
        } else {
            me.SearchRegExp = null;
            me.SearchResult = [];
            me.Search.setResultCount(0);
        }

        var listMarkup = getPlaceholdersListMarkup.call(me);

        me.$placeHoldersList.html(listMarkup);

        selectPlaceholdersSearchResult.call(me);
        bindEvents.call(me);
    }

    function onSelectPreviousOrNextFunction(_event, data) {
        selectPlaceholdersSearchResult.call(this, data.selectedResult);
    }

    function resizeWindow() {
        var screenHeight = $(window).height();
        var height = 600;

        if ((screenHeight - 40) < height) {
            height = screenHeight - 40;
        }

        this.$window.css({
            height: height,
            'margin-top': -((height + 20) / 2)
        });
    }

    function close() {
        this.$window.remove();

        unbindEvents.call(this);
    }

    MailTemplatePlaceholdersWindow.prototype.Show = show;
    MailTemplatePlaceholdersWindow.prototype.Close = close;

    if (!global.System) {
        global.System = {};
    }

    global.System.MailTemplatePlaceholdersWindow = MailTemplatePlaceholdersWindow;
})(Modifications.Popups || (Modifications.Popups = {}));