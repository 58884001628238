(function (global) {
    var PopupBase = function (options) {
        if (this.constructor === PopupBase) {
            throw new Error('This class cannot be instanciated.')
        }

        if (!options.ID) {
            throw new Error('No Popup ID given!');
        }

        if (!options.Buttons) {
            options.Buttons = {
                Close: {
                    Caption: i18next.t('misc.close'),
                    OnClick: this.Close
                }
            };
        }

        if (options.Buttons.No && !(options.Buttons.No.OnClick instanceof Function)) {
            options.Buttons.No.OnClick = this.Close;
        }

        this.options = options;

        initStyles.call(this);
    };

    function initStyles() {
        this.options.Styles = this.options.Styles || {};
        this.options.Styles['_'] = this.options.Styles['_'] || {};
        this.options.Styles['_']['z-index'] = this.options.Styles['_']['z-index'] || 1;

        if (this.options.ShowOverlay) {
            this.options.Styles['_']['z-index']++;
        }

        if (!this.options.Styles['_'].top) {
            this.options.Styles['_'].top = '50%';
        }

        if (!this.options.Styles['_'].left) {
            this.options.Styles['_'].left = '50%';
        }
    }

    PopupBase.prototype.Show = function () {
        init.call(this);
        bindEvents.call(this);
        applyStyles.call(this);
        resizeWindow.call(this);
        this.BindAdditionalEvents();
        this.OnAfterRendered();
    };

    PopupBase.prototype.Close = close;

    function init() {
        var $clientBody = $('body');
        var markup = createMarkup.call(this);

        if (this.options.ShowOverlay) {
            var overlayMarkup = createOverlayMarkup.call(this);

            $clientBody.append(overlayMarkup);

            this.$overlay = $('#' + this.options.ID + '-overlay');
        }

        $clientBody.append(markup);

        this.$window = $('#' + this.options.ID);
        this.$top = this.$window.find('.top');
        this.$content = this.$window.find('.content');
        this.$bottom = this.$window.find('.bottom');
    }

    function createOverlayMarkup() {
        return [
            '<div id="', this.options.ID, '-overlay"',
            'class="overflow">'
        ].join('');
    }

    function createMarkup() {
        var html = [
            '<div id="', this.options.ID, '" class="popup', this.options.EnableDragging ? 'ui-draggable-handle' : '', '">',
                '<h1 class="top">',
                    '<span class="title">', this.options.Title, '</span>',
                    '<div class="close"></div>',
                '</h1>',
                '<div class="content ', (this.options.AdditionalContentClasses || [].join('')), '">',
                    this.CreateContentMarkup(),
                '</div>',
                '<div class="bottom">',
                    '<div class="buttons">'
        ];

        for (var attr in this.options.Buttons) {
            var button = this.options.Buttons[attr];
            var cssClass = 'btn-' + attr[0].toLowerCase() + attr.substring(1);

            if ((button.AdditionalClasses || []).length) {
                cssClass += ' ' + (button.AdditionalClasses || []).join(' ');
            }

            html.push(
                '<div class="btn ', cssClass, '" data-identifier="' + attr + '">',
                    button.Caption,
                '</div>',
            );

            if (button.FlexDummyAfter) {
                html.push('<div class="flex-dummy"></div>');
            }
        }

        html.push(
                    '</div>',
                '</div>',
            '</div>'
        );

        return html.join('');
    }

    function unbindEvents() {
        $(window).off('resize.' + this.options.ID);
    }

    function bindEvents() {
        unbindEvents.call(this);

        this.$top.find('.close')
            .on('click', $.proxy(close, this));

        var me = this;

        if (this.options.Buttons) {
            for (var attr in this.options.Buttons) {
                var button = this.options.Buttons[attr];

                if (!(button.OnClick instanceof Function)) {
                    continue;
                }

                var $btn = this.$bottom.find('.btn-' + attr[0].toLowerCase() + attr.substring(1));

                $btn.on('click', function (evt) {
                    var $btn = $(evt.currentTarget);

                    if ($btn.attr('disabled') === 'disabled') {
                        return;
                    }

                    var identifier = $btn.data('identifier');

                    if (me.options.Buttons[identifier].OnClick instanceof Function) {
                        me.options.Buttons[identifier].OnClick.call(me);
                    }

                    if (!me.options.Buttons[identifier].KeepOpen) {
                        me.Close();
                    }
                });
            }
        }

        $(window)
            .on('resize.' + this.options.ID, $.proxy(resizeWindow, this));

        if (this.options.EnableDragging) {
            this.$window
                .draggable({
                    handle: '.top',
                    cancel: '.close',
                    appendTo: 'body',
                    scroll: false
                });
        }
    }

    function applyStyles() {
        if (this.options.ShowOverlay) {
            $('#' + this.options.ID + '-overlay')
                .css('z-index', this.options.Styles['_']['z-index'] - 1);
        }

        if (!this.options.Styles) {
            return;
        }

        for (var selector in this.options.Styles) {
            if (!this.options.Styles.hasOwnProperty(selector)) {
                continue;
            }

            var $elem = selector === '_' ?
                this.$window :
                this.$window.find(selector);

            var styles = this.options.Styles[selector];

            if (Object.values(styles).length === 0) {
                continue;
            }

            $elem.css(styles);
        }
    }

    function close() {
        this.$window.remove();

        if (this.$overlay instanceof $) {
            this.$overlay.remove();
        }

        unbindEvents.call(this);

        this.OnAfterClosed();
    }

    function resizeWindow() {
        var screenHeight = $(window).height();
        var screenWidth = $(window).width();
        var width = this.$window.width();
        var height = !!this.options.ResizeToFitToContent ?
            getWindowHeightByContent.call(this) :
            this.$window.outerHeight();

        if (height < this.options.MinHeight) {
            height = this.options.MinHeight;
        }

        if (width < this.options.MinWidth) {
            width = this.options.MinWidth;
        }

        if (height > this.options.MaxHeight) {
            height = this.options.MaxHeight;
        }

        if (width > this.options.MaxWidth) {
            width = this.options.MaxWidth;
        }

        if ((screenHeight - 40) < height) {
            height = screenHeight - 40;
        }

        if ((screenWidth - 40) < width) {
            width = screenWidth - 40;
        }

        this.$window.css({
            height: height,
            width: width,
            'margin-top': -((height + 20) / 2),
            'margin-left': -((width + 20) / 2)
        });
    }

    function getWindowHeightByContent() {
        var height = parseInt(this.$window.css('border-top-width'), 10) +
            parseInt(this.$window.css('border-bottom-width'), 10) +
            this.$window.find('.top').outerHeight(true);

        this.$content.children().each(function (_idx, child) {
            var $child = $(child);

            height += $child.outerHeight(true);
        });

        height += this.$bottom.outerHeight(true);

        return height;
    }

    PopupBase.prototype.CreateContentMarkup = function () {
        throw new Error('Function must be defined in the concrete implementation of this class.');
    };

    PopupBase.prototype.BindAdditionalEvents = $.noop;

    PopupBase.prototype.OnAfterRendered = $.noop;

    PopupBase.prototype.OnAfterClosed = $.noop;

    global.PopupBase = PopupBase;
})(Modifications.Popups || (Modifications.Popups = {}));