/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    global.Model.ComponentFactory = (function () {
        /**
         * @param {*[]} checkpointMap
         * @param {string[]} fonts
         */
        function ComponentFactory(checkpointMap, fonts) {
            this.CheckpointMap = checkpointMap;
            this.Fonts = fonts;
        }

        /**
         * @param {object} rawComponent
         * @param {string} renderArea
         * @param {boolean | null} isTableCell
         * @return {BaseComponent|null}
         */
        ComponentFactory.prototype.CreateComponentFromDefinition = function (rawComponent, renderArea, isTableCell) {
            if (rawComponent == null) {
                return null;
            }

            if (!renderArea) {
                return null;
            }

            /**
             * @type {BaseComponent}
             */
            let component;

            switch (rawComponent.Type) {
                case global.Model.Enums.ComponentType.Checkpoint:
                    const checkpoint = this.CheckpointMap[rawComponent.ParameterOID];

                    if (!checkpoint) {
                        return null;
                    }

                    component = this.CreateCheckpointComponent(checkpoint, isTableCell);
                    component.Textflow = rawComponent.Textflow;
                    component.ContentType = rawComponent.ContentType;

                    if (!component.Textflow.FontSize) {
                        component.Textflow.FontSize = 0.95;
                    }

                    if (rawComponent.Underline != null) {
                        component.Underline = !!rawComponent.Underline;
                    }

                    if (rawComponent.SuppressUnit != null) {
                        component.SuppressUnit = !!rawComponent.SuppressUnit;
                    }

                    if (rawComponent.ShowAllListboxOptions != null) {
                        component.ShowAllListboxOptions = !!rawComponent.ShowAllListboxOptions;
                    }
                    break;
                case global.Model.Enums.ComponentType.Image:
                    component = new global.Model.CataloguePictureComponent(null, null, { IsTableCell: isTableCell });
                    component.FileOID = rawComponent.FileOID;
                    break;
                case global.Model.Enums.ComponentType.Geometry:
                    switch (rawComponent.Points.length) {
                        case 2:
                            component = new global.Model.LineComponent(null, null, {});

                            rawComponent.Width = rawComponent.Points[1].X;
                            rawComponent.Height = 0;
                            break;
                        case 4:
                            component = new global.Model.RectangleComponent(null, null, {});

                            rawComponent.Width = rawComponent.Points[1].X;
                            rawComponent.Height = rawComponent.Points[2].Y;
                            break;
                    }

                    if (!component) {
                        return null;
                    }

                    component.Points = rawComponent.Points;
                    component.StrokeColor = rawComponent.StrokeColor;
                    break;
                case global.Model.Enums.ComponentType.Text:
                    component = new global.Model.TextComponent(null, null, { Fonts: this.Fonts, IsTableCell: isTableCell });
                    component.Textflow = rawComponent.Textflow;

                    if (!component.Textflow.FontSize) {
                        component.Textflow.FontSize = 0.95;
                    }

                    if (rawComponent.Underline != null) {
                        component.Underline = !!rawComponent.Underline;
                    }
                    break;
                case global.Model.Enums.ComponentType.Table:
                    component = new global.Model.TableComponent(null, null, {
                        CellProvider: rawComponent.CellProvider,
                        HeaderCellProvider: rawComponent.HeaderCellProvider,
                        RowBorders: rawComponent.RowBorders,
                        ColumnBorders: rawComponent.ColumnBorders
                    });
                    break;
                default:
                    component = new global.Model.GenericInformationComponent(null, null, { Fonts: this.Fonts, IsTableCell: isTableCell });
                    component.Textflow = rawComponent.Textflow;
                    break;
            }

            if (!component) {
                return null;
            }

            component.IsTableCell = isTableCell;

            component.Layout = rawComponent.Layout;
            component.Position = rawComponent.Position;
            component.Height = rawComponent.Height;
            component.Width = rawComponent.Width;
            component.OID = rawComponent.OID;
            component.RenderArea = renderArea;

            delete component.Title;
            delete component.Description;
            delete component.Icon;
            delete component.IsTemplate;

            return component;
        }

        /**
         * @param {object} checkpoint
         * @param {boolean|null} isTableCell
         * @return {CheckpointBaseComponent|null}
         */
        ComponentFactory.prototype.CreateCheckpointComponent = function (checkpoint, isTableCell) {
            if (!checkpoint) {
                return null;
            }

            const defaultComponentSettings = new global.Model.ComponentSettings({
                OID: uuid(),
                Fonts: this.Fonts,
                IsTableCell: !!isTableCell
            });

            switch (checkpoint.Type) {
                case Enums.elementType.Checkbox:
                    return new global.Model.CheckboxCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Number:
                    return new global.Model.NumberCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Line:
                    return new global.Model.LineCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Memo:
                    return new global.Model.MemoCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Date:
                    return new global.Model.DateCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Time:
                    return new global.Model.TimeCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Photo:
                    return new global.Model.PhotoCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Scancode:
                    return new global.Model.ScanCodeCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.LocationCode:
                    return new global.Model.LocationCodeCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.ListBox:
                    return new global.Model.ListBoxCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.MultiListBox:
                    return new global.Model.MultiListBoxCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Info:
                    return new global.Model.InfoCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Signature:
                    return new global.Model.SignatureCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.UsersAndTeams:
                    return new global.Model.UserAndTeamsCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.IndividualData:
                    return new global.Model.IndividualDataCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.PhoneNumber:
                    return new global.Model.PhoneNumberCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.EMailAddresses:
                    return new global.Model.EMailAddressesCpComponent(checkpoint, defaultComponentSettings);
                case Enums.elementType.Files:
                    return new global.Model.FilesCpComponent(checkpoint, defaultComponentSettings);
            }

            return null;
        }

        return ComponentFactory;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));