/**
 * @require ../pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @typedef PageFormat
     * @type {{A4: string}}
     */
    global.Model.PageFormat = {
        A4: 'a4'
    };

    /**
     * @typedef PageOrientation
     * @type {{Landscape: string, Portrait: string}}
     */
    global.Model.PageOrientation = {
        /**
         * Querformat
         */
        Landscape: 'landscape',
        /**
         * Hochformat
         */
        Portrait: 'portrait'
    };

    /**
     * @typedef SectionSettings
     */
    global.Model.SectionSettings = (function () {
        /**
         * @param {string} format
         * @param {string} orientation
         * @constructor
         */
        function SectionSettings(format, orientation) {
            /**
             * @type {string}
             */
            this.Format = format;

            /**
             * @type {string}
             */
            this.Orientation = orientation;
        }

        return SectionSettings;
    })();

    /**
     * @typedef Section
     */
    global.Model.Section = (function () {
        /**
         * @param {SectionSettings} sectionSettings
         * @param {PageSettings} pageSettings
         * @param {Marginal|null} header
         * @param {Marginal|null} footer
         * @param {Page[]|object[]|null} pages
         * @param {ComponentFactory} componentFactory
         * @constructor
         */
        function Section(sectionSettings, pageSettings, header, footer, pages, componentFactory) {
            if (sectionSettings == null) {
                throw new Error('No settings for this section provided');
            }

            /**
             * @type {SectionSettings}
             * @readonly
             */
            this.Settings = sectionSettings;

            /**
             * @type {Page[]}
             * @readonly
             */
            this.Segments = [];

            const renderArea = global.Model.Enums.SectionModificationArea.Page;

            for (let pgNo = 0; pgNo < (pages || []).length; pgNo++){
                let page = pages[pgNo];

                if (!(page instanceof global.Model.Page)) {
                    if (!componentFactory) {
                        continue;
                    }

                    const components = [];

                    for (let cCnt = 0; cCnt < (page.Content || []).length; cCnt++){
                        let component = componentFactory.CreateComponentFromDefinition(page.Content[cCnt], renderArea, false);

                        if (component == null) {
                            continue;
                        }

                        if (component.Type === global.Model.Enums.ComponentType.Table) {
                            SetTableComponents.call(this, components, component, componentFactory, renderArea);
                        }

                        components.push(component);
                    }

                    page = new global.Model.Page(pageSettings, pgNo + 1, components);
                }

                this.Segments.push(page);
            }

            /**
             * @type {Marginal|null}
             */
            this.Header = header;

            /**
             * @type {Marginal|null}
             */
            this.Footer = footer;

            /**
             * @type {boolean}
             */
            this.IsActive = false;

            (this.Segments || []).forEach(p => p.SetSection(this));
        }

        function SetTableComponents(components, component, componentFactory, renderArea) {
            for (let i = 0; i < (component.HeaderCellProvider.Cells || []).length; i++) {
                const row = component.HeaderCellProvider.Cells[i];

                for (let j = 0; j < row.length; j++) {
                    let cell = row[j];

                    SetTableComponent.call(this, cell, components, component, componentFactory, renderArea);
                }
            }

            for (let i = 0; i < (component.CellProvider.Cells || []).length; i++) {
                const row = component.CellProvider.Cells[i];

                for (let j = 0; j < row.length; j++) {
                    let cell = row[j];

                    SetTableComponent.call(this, cell, components, component, componentFactory, renderArea);
                }
            }
            for (let i = 0; i < (component.CellProvider.TemplateCells || []).length; i++) {
                const cell = component.CellProvider.TemplateCells[i];
                SetTableComponent.call(this, cell, components, component, componentFactory, renderArea);
            }
        }

        function SetTableComponent(cell, components, component, componentFactory, renderArea) {
            for (let k = 0; k < (cell.Content || []).length; k++) {
                cell.Content[k] = componentFactory.CreateComponentFromDefinition(cell.Content[k], renderArea, true);
                cell.Content[k].IsTableCell = true;
                cell.Content[k].Position = { Type: global.Model.Enums.PositionType.Relative, Coordinates: { X: 0, Y: 0 } };
                cell.Content[k].TableIdentifier = component.OID;
                cell.Content[k].CellOID = cell.OID;
                cell.Content[k].SetPage(component.Page);
                component.TableComponentsMap[cell.Content[k].OID] = cell.Content[k];
            }

            cell.Height = cell.Content.reduce((acc, value) => acc + value.Height, 0);
        }

        /**
         * Rendert den Bereich inklusive aller Komponenten
         * @return {string}
         * @constructor
         */
        Section.prototype.Render = function () {
            return this.Segments
                .map(p => p.Render())
                .join('');
        };

        /**
         * Versucht alle Komponenten innerhalb der Sektion neu zu positionieren, um zu verhindern, dass sie außerhalb ihrer Grenzen liegen
         * @return {{FailedToPosition: number, OutOfPosition: number}}
         */
        Section.prototype.TryRealignComponents = function () {
            const result = {
                OutOfPosition: 0,
                FailedToPosition: 0
            };

            if (this.HasHeader()) {
                const headerResult = this.Header.TryRealignComponents();

                result.OutOfPosition = headerResult.OutOfPosition;
                result.FailedToPosition = headerResult.FailedToPosition;
            }

            if (this.HasFooter()) {
                const footerResult = this.Footer.TryRealignComponents();

                result.OutOfPosition += footerResult.OutOfPosition;
                result.FailedToPosition += footerResult.FailedToPosition;
            }

            if (this.HasContent()) {
                this.Segments.forEach(p => {
                    const pageResult = p.TryRealignComponents();

                    result.OutOfPosition += pageResult.OutOfPosition;
                    result.FailedToPosition += pageResult.FailedToPosition;
                })
            }

            return result;
        };

        /**
         * Fügt dem Bereich eine neue Seite hinzu
         * @param {Page} page
         */
        Section.prototype.AddPage = function (page) {
            if (!page) {
                return;
            }

            page.SetSection(this);
            this.Segments.push(page);

            return this;
        };

        /**
         * Gibt eine Seite des Bereichs anhand der Seitennummer zurück
         * @param {number} idx
         * @return {Page|null}
         */
        Section.prototype.GetPage = function (idx) {
            if (typeof idx !== 'number' || isNaN(idx)) {
                return null;
            }

            return this.Segments[idx];
        };

        /**
         * Entfernt eine Seite aus dem Bereich
         * @param {number} idx
         */
        Section.prototype.RemovePage = function (idx) {
            if (typeof idx !== 'number' || isNaN(idx)) {
                return;
            }

            this.Segments.splice(idx, 1);
            this.Segments.forEach((page, no) => page.SetPageNumber(no + 1));

            return this;
        };

        /**
         * Blendet Hilfslinien auf allen Seiten ein bzw. aus
         * @param {boolean} showGridLines
         * @param {string} modificationArea
         */
        Section.prototype.SetShowGridLines = function (showGridLines, modificationArea) {
            showGridLines = !!showGridLines;

            this.ShowGridLines = showGridLines;

            if (this.IsActive) {
                this.Segments.forEach(page => page.SetShowGridLines(showGridLines, modificationArea));
            }

            return this;
        };

        /**
         * Gibt zurück, ob im aktuellen Bereich Gitternetzlinien angezeigt werden sollen
         * @return {boolean}
         */
        Section.prototype.GetShowGridLines = function () {
            return this.ShowGridLines;
        };

        /**
         * Setzt ein Flag, ob dieser Bereich aktuell aktiv ist.
         * @param {boolean} isActive
         */
        Section.prototype.SetIsActive = function (isActive) {
            this.IsActive = isActive;

            return this;
        };

        /**
         * Gibt zurück, ob der Bereich eine Kopfzeile besitzt
         * @return {boolean}
         */
        Section.prototype.HasHeader = function () {
            return this.Header && (this.Header.Content || []).length > 0;
        };

        /**
         * Gibt zurück, ob der Bereich Komponenten im Inhaltsbereich besitzt
         * @return {boolean}
         */
        Section.prototype.HasContent = function () {
            return (this.Segments || []).some(p => p.HasContent());
        };

        /**
         * Gibt zurück, ob der Bereich eine Fußzeile besitzt
         * @return {boolean}
         */
        Section.prototype.HasFooter = function () {
            return this.Footer && (this.Footer.Content || []).length > 0;
        };

        return Section;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));